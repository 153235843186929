import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import {
  chartExample1,
} from "variables/charts.js"; //For some reason deleting this import will cause problems on Dashboard Charts.

const interactionChart = {
  data: {
    labels: ["Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    datasets: [
      {
        borderColor: "#6bd098",
        backgroundColor: "#6bd098",
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [10, 2, 3, 15, 5, 1],
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

class InteractionTrend extends React.Component {
  render() {
    return (
      <Card className="card-chungus">
        <CardHeader>
          <CardHeader>
            <h5>Interaction Trend</h5>
          </CardHeader>
        </CardHeader>
        <CardBody>
          <Line
            data={interactionChart.data}
            options={interactionChart.options}
            width={400}
            height={100}
          />
        </CardBody>
      </Card>
    );
  }
}
// Specifies the default values for props:
InteractionTrend.defaultProps = {
  maxTickets: 5,
};
export default InteractionTrend;
