import React, { Component } from "react";

export default class LiveImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveImage: "",
    };
    this.loadImage = () => {
      //Check if the source is local or hosted
      var src = "";
      if (this.props.image.includes("https://")) {
        src = `${this.props.image}?${new Date().getTime()}`;
      } else {
        src = `${this.props.image}`;
      }
      this.setState({ liveImage: src });
      //console.log("going to :"+src)
    };
  }

  componentDidMount() {
    this.loadImage();
    this.interval = setInterval(this.loadImage, this.props.interval);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <img alt="profile" src={this.state.liveImage} {...this.props} />;
  }
}
