import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
  CardHeader,
  CardSubtitle,
} from "reactstrap";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
//Amplify
import { Auth } from "aws-amplify";
import { host } from "../../variables/apis";
import { connect } from "react-redux";
// Chart configurations
import { config, months, days } from "./CallBehaviourChartConf";
// Custom components
import FetchingData from "components/FetchingData";
//Functions
import {
  arrayOfMonthDays,
  arrayOfMonthData,
  reduceByMonth,
  getTrueABAN,
} from "./CallBehaviourFuncs";
import verifyArray from "AuxFunctions/verifyArray";
//Variables
import { blue, red, purple, green, yellow } from "variables/colors";

var last2month_index = new Date().getMonth() - 2;


class CallBehaviourTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "0",
      isFetching: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  async fetchData() {
    this.setState({ isFetching: true });
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    try {
      fetch(`${host}json-stats/historical?report=historical_metrics`, init)
        .then((response) => response.json())
        .then((data) => {
          //console.log("Historical data:",data);
          this.props.onChangeState("historical_data", data.DATA);
          this.setState({ isFetching: false });
        });
    } catch {
      console.log("Error while loading historical metrics");
      this.setState({ isFetching: false });
    }
  }

  async fetchLastYearData() {
    //this.setState({ isFetching: true });
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    fetch(`${host}json-stats/historical?report=historical_last_year`, init)
      .then((response) => response.json())
      .then((data) => {
        //console.log("Historical data:",data);
        this.props.onChangeState("historical_last_year", data.DATA);
        //this.setState({ isFetching: false });
      });
  }

  formatChartData(array, type) {
    // Receives an array of raw Connect Data and returns array of objects for react chart line
    //console.log("array", array);
    if (array === undefined || !verifyArray(array)) {
      return null;
    }

    let labels = [
      "Offered",
      "Answered",
      "SLA Answered",
      "Abandoned",
      "True Abandoned",
    ];
    let colors = [purple, blue, green, yellow, red];
    let data = {
      labels: [],
      datasets: [],
    };
    // Setting Chart Labels
    switch (type) {
      case "week":
        data.labels = days;
        break;

      case "last_month":
      case "any_month":
        data.labels = arrayOfMonthDays(array.length);
        break;

      case "month":
        data.labels = arrayOfMonthDays();
        break;

      case "year":
        data.labels = months;
        break;

      default:
        data.labels = days;
        break;
    }

    labels.forEach((dataset, index) => {
      data.datasets.push({
        label: dataset,
        borderColor: colors[index],
        pointBackgroundColor: colors[index],
        pointRadius: 1.5,
        pointHitRadius: 7,
        pointHoverRadius: 6,
        //lineTension:0,
        fill: false,
        backgroundColor: colors[index] + "77", //rgba(81, 188, 218,.15)",
        borderWidth: 2,
        data: [],
      });
    });

    array.forEach((item, index) => {
      if (item.DATA.INBOUND.TOTAL.COUNT !== 0 || index === 0) {
        data.datasets[0].data.push(item.DATA.INBOUND.TOTAL.COUNT);
      }
      if (item.DATA.INBOUND.TOTAL.ANS !== 0 || index === 0) {
        data.datasets[1].data.push(item.DATA.INBOUND.TOTAL.ANS);
      }
      if (item.DATA.INBOUND.TOTAL.SLA_ANS !== 0 || index === 0) {
        data.datasets[2].data.push(item.DATA.INBOUND.TOTAL.SLA_ANS);
      }
      if (item.DATA.INBOUND.TOTAL.ABAN !== 0 || index === 0) {
        data.datasets[3].data.push(item.DATA.INBOUND.TOTAL.ABAN);
      }
      if (item.DATA.INBOUND.TOTAL.ABAN !== 0 || index === 0) {
        data.datasets[4].data.push(
          getTrueABAN(
            item.DATA.INBOUND.TOTAL.ABAN,
            item.DATA.INBOUND.TOTAL.SLA_ABAN
          )
        );
      }
    });

    return data;
  }

  componentDidMount() {
    try {
      if (!this.props.historical_data.HISTORICAL[0]) {
        this.fetchData();
      }
    } 
    catch {
      console.log("an error ocurred while fetching historical data");
    }
    
    try {
      let current_month = new Date().getMonth();
      if (current_month < 3 && !this.props.historical_last_year.HISTORICAL[0]) {
        this.fetchLastYearData();
      }
    } 
    catch {
      console.log("an error ocurred while fetchin last year's historical data");
    }
  }

  render() {
    //console.log("CallBehaviourTabs props:", this.props);
    let n_month = new Date().getMonth();
    let week, last_month, month;
    try {
      week = this.props.historical_data.TIMELINE_MAP.WEEK;
      last_month = this.props.historical_data.TIMELINE_MAP.LAST_MONTH;
      month = this.props.historical_data.TIMELINE_MAP.MONTH;
    } catch {
      console.log("An error ocurred while getting TIMELINE MAPS");
    }
    
    /* Setting last month and 2nd last month data depending on if it's going to be 
    used from current year or last year data... */
    let november_index = 11;
    let december_index = 12;
    let last_month_data, last2_month_data;

    try {
      switch (n_month){
        case 1:
          last2_month_data = last2_month_data = this.formatChartData(
            arrayOfMonthData(
              this.props.historical_last_year.HISTORICAL,
              november_index
            ),
            "any_month"
          );
          last_month_data = last2_month_data = this.formatChartData(
            arrayOfMonthData(
              this.props.historical_last_year.HISTORICAL,
              december_index
            ),
            "any_month"
          );
          break;

        case 2:
          last2_month_data = this.formatChartData(
            arrayOfMonthData(
              this.props.historical_last_year.HISTORICAL,
              december_index
            ),
            "any_month"
          );

          last_month_data = this.formatChartData(
            this.props.historical_data.HISTORICAL.slice(
              last_month.START,
              last_month.END + 1
            ),
            "last_month"
          );
          break;

        default:
          last2_month_data = this.formatChartData(
            arrayOfMonthData(
              this.props.historical_data.HISTORICAL,
              last2month_index
              ),
              "any_month"
              );

          last_month_data = this.formatChartData(
            this.props.historical_data.HISTORICAL.slice(
              last_month.START,
              last_month.END + 1
            ),
            "last_month"
          );
          break;
      }
    }
    catch {
      console.log("An error ocurred while assigning last months metrics data.")
    }

    return (
      <Card className="large-chart-card">
        <CardHeader>
          <CardTitle tag="h5">
            Call Behaviour &nbsp;
            <i className="fas fa-chart-line text-primary"></i>
            
            <span className="refresh-link" onClick={this.fetchData}>
                Refresh data &nbsp;
                <i
                className="fas fa-sync text-info icon-spin"
                style={{ fontSize: "1rem" }}
                ></i>
            </span>  
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper" style={{ marginLeft: "20px" }}>
              <Nav id="tabs" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "0" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "0" })}
                  >
                    Week
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "1" })}
                  >
                    {this.props.last2_month}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "2" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "2" })}
                  >
                    {this.props.last_month}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "3" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "3" })}
                  >
                    {this.props.month}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "4" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "4" })}
                  >
                    Year
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          {/* -------- Tab contents begin here -------- */}
          <TabContent id="my-tab-content" activeTab={this.state.activeTab}>
            <TabPane tabId="0" role="tabpanel">
              {this.state.isFetching ? (
                <FetchingData />
              ) : (
                <Line
                  data={
                    this.props.historical_data
                      ? this.formatChartData(
                          this.props.historical_data.HISTORICAL.slice(
                            week.START,
                            week.END + 1
                          ),
                          "week"
                        )
                      : []
                  }
                  options={config.options}
                />
              )}
            </TabPane>

            <TabPane tabId="1" role="tabpanel">
              {this.state.isFetching ? (
                <FetchingData />
              ) : (
                <Line
                  data={
                    last2_month_data !== undefined
                      ? last2_month_data
                      : []
                  }
                  options={config.options}
                />
              )}
            </TabPane>

            <TabPane tabId="2" role="tabpanel">
              {this.state.isFetching ? (
                <FetchingData />
              ) : (
                <Line
                  data={
                    last_month_data !== undefined
                      ? last_month_data
                      : []
                  }
                  options={config.options}
                />
              )}
            </TabPane>

            <TabPane tabId="3" role="tabpanel">
              {this.state.isFetching ? (
                <FetchingData />
              ) : (
                <Line
                  data={
                    this.props.historical_data
                      ? this.formatChartData(
                          this.props.historical_data.HISTORICAL.slice(
                            month.START,
                            month.END + 1
                          ),
                          "month"
                        )
                      : []
                  }
                  options={config.options}
                />
              )}
            </TabPane>

            <TabPane tabId="4" role="tabpanel">
              {this.state.isFetching ? (
                <FetchingData />
              ) : (
                <Line
                  data={
                    this.props.historical_data
                      ? this.formatChartData(
                          reduceByMonth(this.props.historical_data.HISTORICAL),
                          "year"
                        )
                      : []
                  }
                  options={config.options}
                />
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    );
  }
}

// ---- Redux functions ---- //

const mapStateToProps = (state) => {
  return {
    last2_month: state.last2_month,
    last_month: state.last_month,
    month: state.month,
    historical_data: state.historical_data,
    historical_last_year: state.historical_last_year,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  };
};

export const updateState = (type, value) => (dispatch) => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
};

// ---- End Redux functions ---- //

export default connect(mapStateToProps, mapDispatchToProps)(CallBehaviourTabs);
