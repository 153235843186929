// Setting current month, last month and last 2 month for the application store.
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

var n_month = new Date().getMonth();

const month = monthNames[n_month];
var last_month, last2_month;

switch (n_month){
    case 0:
        last_month = monthNames[11];
        last2_month = monthNames[10];
        break;

    case 1:
        last_month = monthNames[n_month-1];
        last2_month = monthNames[11];
        break; 

    default:
        last_month = monthNames[n_month-1];
        last2_month = monthNames[n_month-2];
        break;
}

export { month, last_month, last2_month};