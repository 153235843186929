import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,   
    Nav,
    NavLink,
    NavItem,
    TabPane,
    TabContent,
} from "reactstrap";
import { connect } from "react-redux";
//Amplify
import { Auth } from "aws-amplify";
import {host} from "../variables/apis";
import TicketsChart from "./TicketsChart";
import TicketsChartGeneric from "./TicketsChartGeneric";
import ticketStatsMap from "AuxFunctions/ticketStatsMap";
import FetchingData from "./FetchingData";
import cleanStats from "AuxFunctions/cleanStats";


class TicketsChartTabs extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab : "0",
            isFetching : false,
            data :{
                state : {
                    new : 0,
                    in_progress : 0,
                    closed : 0,
                    canceled : 0,
                },
                location : {},
                category : {},
                priority : {},
                impact : {},
                urgency : {},
            },
        };
        this.fetchAllTickets = this.fetchAllTickets.bind(this);
    }

    async fetchAllTickets(){
        this.props.onChangeState("fetchingAllTickets", true);
        const init = {
            headers : { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
          }
        //Fetchs data from API for monthly stats and stores it on component state.
        fetch(`${host}snow-query?query=all_tickets`, init)
        .then(response => response.json())
        .then(data => {
            //console.log("all_tickets: ", data);
            if (data.result){
                this.props.onChangeState("all_tickets", data.result.data);
            }
            this.props.onChangeState("fetchingAllTickets", false);
        })
        .then(this.createTicketStats());
    }

    createTicketStats(){
        let ticket_concat = this.props.all_tickets.open.concat(
            this.props.all_tickets.in_progress,
            this.props.all_tickets.solved,
            this.props.all_tickets.canceled
            )
        let ticket_stats = ticketStatsMap(ticket_concat);
        this.setState({data : ticket_stats});
    }


    forceRefreshTicketStats(){
    // Ensures that TicketStats are correctly rendered.
        if ( this.props.all_tickets.canceled.length === 0 ) {
            let stats_refresh = setInterval(() => {
                this.createTicketStats();
                if (this.props.all_tickets.canceled.length !== 0 ){
                    clearInterval(stats_refresh);
                }
            }, 1500);
    
            setTimeout(()=>{
                try {
                    clearInterval(stats_refresh);
                }
                catch {
                    console.log("Interval already cleared");
                }
            }, 16000)
        }
    }


    componentDidMount(){
        this.createTicketStats();
        this.forceRefreshTicketStats();
    }

    render(){
        //console.log("TicketsChartTabs props",this.props);
        return(
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">
                        Incident Statistics &nbsp;
                        <i className="fas fa-chart-bar text-info"
                        />
                        
                    <span className="refresh-link" onClick={this.fetchAllTickets}>
                        Refresh data &nbsp;
                        <i
                        className="fas fa-sync text-info icon-spin"
                        style={{ fontSize: "1rem" }}
                        ></i>
                    </span> 
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper" style={{marginLeft:'20px'}}>
                        <Nav id="tabs" role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "0" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "0" })}
                                >Status
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "1" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "1" })}
                                >Location
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "2" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "2" })}
                                >Category
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "3" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "3" })}
                                >Priority
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "4" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "4" })}
                                >Impact
                                </NavLink> 
                            </NavItem>

                            <NavItem>
                                <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "5" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "5" })}
                                >Urgency
                                </NavLink> 
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                {
                    this.props.fetchingAllTickets 
                    ? <FetchingData />

                    :
                    <TabContent id="my-tab-content" activeTab={this.state.activeTab}>
                            <TabPane tabId="0" role="tabpanel"> 
                                <TicketsChart 
                                new={this.state.data.state.new}
                                in_progress={this.state.data.state.in_progress}
                                closed={this.state.data.state.closed}
                                canceled={this.state.data.state.canceled}
                                />
                            </TabPane>

                            <TabPane tabId="1" role="tabpanel"> 
                                <TicketsChartGeneric
                                    data={cleanStats(this.state.data.location,1.5)}
                                />
                            </TabPane>

                            <TabPane tabId="2" role="tabpanel"> 
                                <TicketsChartGeneric
                                    data={cleanStats(this.state.data.category,2)}
                                />
                            </TabPane>

                            <TabPane tabId="3" role="tabpanel"> 
                                <TicketsChartGeneric
                                    data={this.state.data.priority}
                                />
                            </TabPane>

                            <TabPane tabId="4" role="tabpanel"> 
                                <TicketsChartGeneric
                                    data={this.state.data.impact}
                                />
                            </TabPane>

                            <TabPane tabId="5" role="tabpanel"> 
                                <TicketsChartGeneric
                                    data={this.state.data.urgency}
                                />
                            </TabPane>
                    </ TabContent>
                }
                </CardBody>
            </Card>
        )
    }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
    return {
        all_tickets : state.all_tickets,
        ticket_stats : state.ticket_stats,
        fetchingAllTickets : state.fetchingAllTickets,
    };
}
  
const mapDispatchToProps = dispatch => {
    return {
        onChangeState: (type, value) => dispatch(updateState(type, value)),
    }
}

const updateState = (type, value) => dispatch => {
    dispatch({
        type: type,
        value,
    });
    return Promise.resolve();
}
// ENDS REDUX STUFF //

export default connect(mapStateToProps, mapDispatchToProps)(TicketsChartTabs);
