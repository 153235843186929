import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody,
} from "reactstrap";
import StoreDetails from "./StoreDetails";
import FetchingData from "./FetchingData";
import { Auth } from "aws-amplify";
import { host } from "../variables/apis";


class SearchForStore extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_fetching : false,
            fetchingLocation : false,
            query : "",
            results : [],
            store : {
                u_nsgn : false,
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e){
        e.preventDefault();
        this.handleFectch();
    }

    handleChange(e){
        let target = e.target
        let name = target.name;
        let value = target.value;
        this.setState({[name]:value});
    }

    async handleFectch(){
        this.setState({is_fetching:true});
        const init = {
            headers : { 
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
        }
        fetch(`${host}snow-query?query=users_store&keys=${this.state.query}`, init)
        .then(response => response.json())
        .then(res => {
            //console.log("Store Search Response",res);
            this.setState({results : res.data.result});
            this.setState({is_fetching:false});
            try {
                this.fetchLocationData(res.data.result[0].location.value)
            }
            catch {
                console.log("Fetch location failed or was canceled")
            }
        });
    }

    async fetchLocationData(location_id){
        const init = {
          headers : { 
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          }
        }
        this.setState({fetchingLocation:true});
        fetch(`${host}snow-query?query=location&sys_id=${location_id}`, init)
        .then(response => response.json())
        .then(data => {
          try {
            //console.log("User location query data",data);
            this.setState({store : data.data.result[0]});
          }
          catch {
            console.log("Error while fetching search location data")
          }
          this.setState({fetchingLocation:false});
        });
      }

    render(){
        return(
            <CardBody>
                <Row>
                    <Col md="10">
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="query">Enter phone number or store name:</Label>
                                <Input 
                                    placeholder="Search for..."
                                    value={this.state.query} 
                                    type="text" 
                                    id="query" 
                                    name="query" 
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col md="2">
                        <Button 
                            className="search-button"
                            onClick={this.handleSubmit} 
                            color="info">Search</Button>
                    </Col>
                </Row>
                <Row>
                {   
                    this.state.is_fetching 
                    ? <FetchingData />
                    : this.state.results.length > 0
                        ? <StoreDetails 
                            data={this.state.results} 
                            store={this.state.store}
                            fetchingLocation={this.state.fetchingLocation}
                            />
                        :  null
                }   
                </Row>
            </CardBody>
        )
    }
}

export default SearchForStore;