const blue = "#51bcda";
const green = "#5bce8f";
const yellow = "#f0b644";
const red = "#fb404b";
const purple = "#9b75e7";

const colors = [
    blue,
    red,
    green,
    yellow,
    purple,
]

export default colors;
export { blue, green, red, yellow, purple };