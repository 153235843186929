import React from "react";
import {
  CardBody,
  Badge,
  Container,
  Col,
  Row,
} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

var ps;

export default class Attachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.bytesToSize = this.bytesToSize.bind(this);
  }

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  handleRenderIcon = (contentType) => {
    let types = contentType.split("/");
    if (types[0] === "video") {
      return (
        <div className="timeline-badge danger">
          <i class="fa fa-file-video-o" aria-hidden="true"></i>
        </div>
      );
    } else if (types[0] === "text" && types[1].includes("plain")) {
      //contentType is containing html5, css, javascript or some sort of code.
      return (
        <div className="timeline-badge warning">
          <i class="fa fa-file-code-o" aria-hidden="true"></i>
        </div>
      );
    }
    switch (contentType) {
      case "image/jpeg":
        return (
          <div className="timeline-badge success">
            <i class="fa fa-file-image-o" aria-hidden="true"></i>
          </div>
        );
      case "image/jpg":
        return (
          <div className="timeline-badge success">
            <i class="fa fa-file-image-o" aria-hidden="true"></i>
          </div>
        );
      case "image/png":
        return (
          <div className="timeline-badge success">
            <i class="fa fa-picture-o" aria-hidden="true"></i>
          </div>
        );
      case "image/gif":
        return (
          <div className="timeline-badge success">
            <i class="fa fa-file-image-o" aria-hidden="true"></i>
          </div>
        );
      case "application/pdf":
        return (
          <div className="timeline-badge danger">
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          </div>
        );
      case "application/vnd.ms-excel":
        return (
          <div className="timeline-badge success">
            <i class="fa fa-file-image-o" aria-hidden="true"></i>
          </div>
        );
      case "application/msword":
        return (
          <div className="timeline-badge primary">
            <i class="fa fa-file-word-o" aria-hidden="true"></i>
          </div>
        );
      case "application/vnd.ms-powerpoint":
        return (
          <div className="timeline-badge danger">
            <i class="fa fa-file-powerpoint-o" aria-hidden="true"></i>
          </div>
        );

      case "text/plain":
        return (
          <div className="timeline-badge secondary">
            <i class="fa fa-file-text-o" aria-hidden="true"></i>
          </div>
        );
      case "application/zip":
        return (
          <div className="timeline-badge secondary">
            <i class="fa fa-file-archive-o" aria-hidden="true"></i>
          </div>
        );
      default:
        return (
          <div className="timeline-badge primary">
            <i class="fa fa-file" aria-hidden="true"></i>
          </div>
        );
    }
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.timeline);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  render() {
    var styles = {
      height: "650px",
    };

    return (
        <CardBody>
          <div className="ps-child wrapper" ref="timeline" style={styles}>
            <Container>
              <Row>
                <Col>
                  <div>
                    <ul className="timeline timeline-simple">
                      {this.props.incident_attachments &&
                      Object.keys(this.props.incident_attachments).length > 0
                        ? this.props.incident_attachments.map((item, idx) => {
                            return (
                              <li className="timeline-inverted">
                                {this.handleRenderIcon(item.content_type)}

                                <div className="timeline-panel">
                                  <div className="timeline-heading">
                                    {item ? (
                                      <Badge color="default" pill>
                                        {item.sys_created_by}
                                      </Badge>
                                    ) : (
                                      <Badge color="danger" pill>
                                        Unknow Author
                                      </Badge>
                                    )}
                                  </div>
                                  <div className="timeline-body">
                                    <p>
                                      {item ? (
                                        <a href={item.download_link}>
                                          {item.file_name}
                                        </a>
                                      ) : (
                                        "Empty Description"
                                      )}
                                    </p>
                                  </div>
                                  <h6>
                                    <b>
                                      <i
                                        class="fa fa-clock-o"
                                        aria-hidden="true"
                                      ></i>
                                      {item
                                        ? item.sys_created_on +
                                          " - " +
                                          this.bytesToSize(item.size_bytes)
                                        : "Unkown date"}
                                    </b>
                                  </h6>
                                </div>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </CardBody>
    );
  }
}
