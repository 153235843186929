const facts = [
  {
    fact: "Wild dolphins call each other by name.",
  },
  {
    fact: "Tomatoes are native to the Americas, so there was no such thing as tomato sauce in Italy until at least the 16th century.",
  },
  {
    fact: "The Pacific Ocean is larger than all land masses on Earth combined.",
  },
  {
    fact: "The longest hiccupping attack lasted 68 years.",
  },
  {
    fact: "Flamingos turn pink due to the sheer amount of shrimp they consume.",
  },
  {
    fact: "Hope you have a nice day!!.",
  },
  {
    fact: "Don't trust a pink elephant with a hat!.",
  },
];

export default facts;
