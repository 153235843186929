import React from "react";
import {Button, Row, Col, Card} from "reactstrap";
import "./BannerNotifications.css";
import formatDateTime from "AuxFunctions/formatDateTime";

class BannerNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerIsOpenAllUser: ('all_users' in this.props.notifications && this.props.notifications.all_users.length > 0)?true:false,
      bannerIsOpenEspUser: ('user' in this.props.notifications && this.props.notifications.user.length > 0)?true:false,
      notifications: props.notifications,
      notificationsAllUser: props.notifications.all_users,
      notificationsEspUser: props.notifications.user,
    };
    this.bannerToggleNotifAllUser = this.bannerToggleNotifAllUser.bind(this);
    this.bannerToggleNotifEspUser = this.bannerToggleNotifEspUser.bind(this);
    this.checkNewNotifications = this.checkNewNotifications.bind(this);
  }
  bannerToggleNotifAllUser(e) {
    this.setState({
      bannerIsOpenAllUser: false,
    });
  }
  bannerToggleNotifEspUser(e) {
    this.setState({
      bannerIsOpenEspUser: false,
    });
  }
  checkNewNotifications(notificationsProps,notificationsState,type){
    //PROPS for the new Notifications:
    //STATE for pass notifications:
    const checkInProps = (type === "All")? "all_users" : "user";
    const checkInStates = (type === "All")? "notificationsAllUser" : "notificationsEspUser";
    if(type === "All" && checkInProps in notificationsProps["notifications"] && notificationsProps["notifications"][checkInProps].length !== notificationsState[checkInStates].length){ //Get if there's a new notifications for ALL_USER [IN PROGRESS]
      if(notificationsProps["notifications"][checkInProps].length > notificationsState[checkInStates].length){
        this.setState({
          bannerIsOpenAllUser: true,
          notificationsAllUser: notificationsProps.notifications.all_users,
        });
      }
      if(notificationsProps["notifications"][checkInProps].length < notificationsState[checkInStates].length){
        this.setState({
          notificationsAllUser: notificationsProps.notifications.all_users,
        });
      }
    }
    if(type === "User" && checkInProps in notificationsProps["notifications"] && notificationsProps["notifications"][checkInProps].length !== notificationsState[checkInStates].length){ //Get if there's a new notifications for USER espexific [IN PROGRESS]
      if(notificationsProps["notifications"][checkInProps].length > notificationsState[checkInStates].length){
        this.setState({
          bannerIsOpenEspUser: true,
          notificationsEspUser: notificationsProps.notifications.user,
        });
      }
      if(notificationsProps["notifications"][checkInProps].length < notificationsState[checkInStates].length){
        this.setState({
          notificationsEspUser: notificationsProps.notifications.user,
        });
      }
    }
  }
  addNotifications(type){ //For testing ADD NOTIFICATIONS START (1/2)
    if(type==="All"){
      this.setState({
        bannerIsOpenAllUser: !this.state.bannerIsOpenAllUser,
      });
    }
    if(type==="User"){
      this.setState({
        bannerIsOpenEspUser: !this.state.bannerIsOpenEspUser,
      });
    }
  } 

  render() {
  console.log("this FROM BannerNotifications: ", this);
      return (
        <Row className="center w-100" style={{margin:"0px 10px 0px 10px"}}>
            {
              //ALL USERS NOTIFICATION START:
            }
            {(this.state.bannerIsOpenAllUser && this.state.notificationsAllUser.length > 0)?(
        <Row className="center w-100" style={{height:"auto"}}>
          <Col xs="10" sm="10" md="10" lg="11" xl="11" style={{height:"auto"}}>
            <Card className="notificationCard" color="warning" body style={{margin:"1px 10px", padding:"5px 10px", height:"auto"}}>
              <p className="font-weight-bold" style={{margin:"0px 0px", padding:"0px 0px", display: "-webkit-inline-box"}}>
                <i className="fa fa-globe" style={{transform:"none"}}/> 
                &nbsp;
                <font className="container-fluid w-100">
                  <span className="word-break">
                    {this.state.notificationsAllUser[this.state.notificationsAllUser.length-1].message.toString()}
                  </span>
                  <span className="word-break justify-content-end" style={{marginLeft:"auto"}}>
                    FROM: {this.state.notificationsAllUser[this.state.notificationsAllUser.length-1].from.toString()}
                    &nbsp; | &nbsp;
                    Date: {formatDateTime(this.state.notificationsAllUser[this.state.notificationsAllUser.length-1].created)}
                  </span>
                </font>
              </p>
            </Card>
          </Col>
          <Col xs="2" sm="2" md="2" lg="1" xl="1" style={{height:"auto", padding:"0px"}}>
            <Button
              color="danger"
              style={{
              margin:"0px"
              }}
              onClick={(e)=>{
                this.bannerToggleNotifAllUser(false)}            
            }
            >
            <h6 style={{ margin:"0px", padding:"0px"}}>
            X
            </h6>
            </Button>
          </Col>
        </Row>
        ):this.checkNewNotifications(this.props,this.state,"All")}
            {
              //ALL USERS NOTIFICATION END:
            }
            {
              //ESPECIFIC USER NOTIFICATION START:
            }
        {(this.state.bannerIsOpenEspUser && this.state.notificationsEspUser.length > 0)?(
        <Row className="center w-100">
          <Col xs="10" sm="10" md="10" lg="11" xl="11" style={{height:"auto"}}>
            <Card className="notificationCard" color="warning" body style={{margin:"1px 10px", padding:"5px 10px", height:"auto"}}>
              <p className="font-weight-bold" style={{margin:"0px 0px", padding:"0px 0px", display: "-webkit-inline-box"}}>
                <i className="fa fa-commenting-o" style={{transform:"none"}} />
                &nbsp;
                <font className="container-fluid w-100">
                  <span className="word-break">
                    {this.state.notificationsEspUser[this.state.notificationsEspUser.length-1].message.toString()}
                  </span>
                  <span className="word-break justify-content-end" style={{marginLeft:"auto"}}>
                    FROM: {this.state.notificationsEspUser[this.state.notificationsEspUser.length-1].from.toString()}  
                    &nbsp; | &nbsp;
                    Date: {formatDateTime(this.state.notificationsEspUser[this.state.notificationsEspUser.length-1].created).toString("DD-MM-YYYY")}
                  </span>
                </font>
              </p>
            </Card>
          </Col>
          <Col xs="2" sm="2" md="2" lg="1" xl="1" style={{height:"auto", padding:"0px"}}>
            <Button
              color="danger"
              style={{
              margin:"0px"
              }}
              onClick={(e)=>{
                this.bannerToggleNotifEspUser(false)}            
            }
            >
            <h6 style={{ margin:"0px", padding:"0px"}}>
            X
            </h6>
            </Button>
          </Col>
        </Row>
        ):this.checkNewNotifications(this.props,this.state,"User")}
            {
              //ESPECIFIC USER NOTIFICATION END:
            }
        </Row>
      );
  }
}

export default BannerNotifications;