import React,{ Component } from 'react';
import { Bar } from "react-chartjs-2";
// Router
import { withRouter } from "react-router";


function handleClick(activeTab){
  let tab = String(activeTab);
  //console.log("active tab",tab);
  this.props.history.push({
    pathname: "/main/incidents",
    state: { activeTab : tab}
  })
}

class TicketsChart extends Component {
// Small Card with Doghnout Chart for data display }
  constructor(props){
    super(props);
    handleClick = handleClick.bind(this);
  }

  chartConfig = {
      data: (canvas) => {
        return {
          labels: ["Incidents"],
          datasets: [
            {
              label: " New ",
              backgroundColor: "#51bcda",
              borderWidth: 0,
              data: [this.props.new],
            },
            {
              label: " In progress ",
              backgroundColor: "#FFC708",
              borderWidth: 0,
              data: [this.props.in_progress],
            },
            {
              label: " Closed ",
              backgroundColor: "#35d8a7",
              borderWidth: 0,
              data: [this.props.closed],
            },
            {
              label: " Canceled ",
              backgroundColor: "#FB404B",
              borderWidth: 0,
              data: [this.props.canceled],
            },
          ],
        };
      },
      options: {
        responsive : true,
        onClick: function(e){
          var element = this.getElementAtEvent(e);
          //console.log("click event",element);
          if (element.length > 0){
            let activeTab = element[0]._datasetIndex+1;
            handleClick(activeTab);
          }
        },
        legend: {
          display: true,
          position:"right",
          align:"center",
          maintainAspectRatio: false,
          onHover: function(e) {
            e.target.style.cursor = 'pointer';
          },
        },
        hover: {
            onHover: function(e) {
               let point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
    
        tooltips: {
          enabled: true,
        },
    
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
              },
              barPercentage: .90,
              gridLines: {
                drawBorder: true,
                zeroLineColor: "transparent",
                color: "transparent",
              },
            },
          ],
    
          yAxes: [
            {
              ticks: {
                display: true,
              },
              barPercentage: 1,
              gridLines: {
                borderDash: [5,8],
                lineWidth : .5,
                drawBorder: true,
                zeroLineColor: "#626675",
                color: "#626675",
              },
            },
          ],
        },
      },
    };

    render(){
      //console.log("TicketsChart props",this.props);
        return(
          <React.Fragment>
            <Bar
                data={this.chartConfig.data}
                options={this.chartConfig.options}
            />
            <br />
          </React.Fragment>
        );
    }
}


export default withRouter(TicketsChart);