import React, {Component} from 'react';

class ColorPicker extends Component {
    render(){
        return(
            <span
                className={
                this.props.bgColor === this.props.color
                    ? "badge filter badge-" + this.props.color + " active"
                    : "badge filter badge-" + this.props.color
                }
                data-color={this.props.color}
                onClick={() => {
                    this.props.handleBgClick(this.props.color);
                }}
            />
        );
    }
}

export default ColorPicker;