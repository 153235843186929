import React from "react";
import { 
  Row, 
  Col, 
} from "reactstrap";

// Custom Components
import OpeningScript from "components/OpeningScript/OpeningScript";
import InteractionTrend from "components/Interactions/InteractionTrend/InteractionTrend"; //Deleting this import will cause trouble on dashboard for unknown reasons.
// For some reason deleting the previous import will cause problems in dashboard components
import CallerDetailsTabs from "components/CallerDetailsTabs";
import TicketsTabMenu from "components/TicketsTabMenu/TicketsTabMenu";
import SnowActions from "components/SnowActions";

class ServicePanelView extends React.Component {
  
  componentDidMount(){
    // Set scroll to top
    let main = document.querySelector(".main-panel");
    main.scrollTo(0, 0);
  }

  render() {
    //console.log("Service Panel props:",this.props);
    //console.log("Service Panel this:",this);
    return (
      <div className="content " style={{marginTop: (this.props.getContentTopMargin()) }}>
        <Row className="service-1st-row">
          <Col md="5" sm="12">
            { 
              this.props.callInfo.userInfo & this.props.callInfo.userInfo.length > 0 
              || this.props.callInfo.phoneNumber 
              ? 
              <React.Fragment>
                <Row className="opening-script-row">
                  <OpeningScript />
                </Row>
                <Row className="snow-actions-row" >
                  <SnowActions />
                </Row>
              </ React.Fragment>
              : null}
          </Col>
          
          <Col md="7" sm="12">
            {
              this.props.callInfo.userInfo & this.props.callInfo.userInfo.length > 0 
              || this.props.callInfo.phoneNumber 
              ? <CallerDetailsTabs data={this.props.callInfo} />
              : null}
          </Col>


        </Row>

        <Row className="flex-row">
        <Col md="12" sm="12" className="d-flex align-items-stretch">
            <TicketsTabMenu
              callInfo={this.props.callInfo}
              handleGetAssigmentGroups={this.props.handleGetAssigmentGroups}
              handleGetAssignedTo={this.props.handleGetAssignedTo}
              handleGetUserTickets={this.props.handleGetUserTickets}
              notify={this.props.notify}
              successAlert={this.props.successAlert}
              handleUpdateTicket={this.props.handleUpdateTicket}
              /* handleGetKnowledgeArticle={
                this.props.handleGetKnowledgeArticle
              } */
            />
          </Col>

          {/* <Col md="5" sm="12" className="d-flex align-items-stretch">
            <UserSumTable
              {...this.props}
              handleUpdateTicket={this.props.handleUpdateTicket}
              notify={this.props.notify}
              successAlert={this.props.successAlert}
            />
          </Col> */}
        </Row>

        {/* <Row>
          <Col>
            {this.props.callInfo.userHardware ? (
              <DeviceManager
                data={this.props.callInfo.userHardware}
                deviceData={this.props.callInfo.userInstance}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.callInfo.dwsHardware &&
              this.props.callInfo.dwsHardware.length > 0
              ? (
                <DWSDeviceManager
                  data={this.props.callInfo.dwsHardware}
                  dwsAvgMemoryCpuUsage={this.props.callInfo.dwsAvgMemoryCpuUsage[0]}
                  commands={this.props.callInfo.dwsRemoteActions}
                  appList={this.props.callInfo.dwsInstallAppList}
                  handleGetDWSPingStatus={this.props.handleGetDWSPingStatus}
                  handleGetDWSAvgMemoryCpuUsage={this.props.handleGetDWSAvgMemoryCpuUsage}
                  successAlert={this.props.successAlert}
                  dangerAlert={this.props.dangerAlert}
                />
            ) : null}
          </Col>
        </Row> */}
        {/* {this.props.callInfo &&
          Object.keys(this.props.callInfo.userTickets).length > 0 &&
          Object.keys(this.props.callInfo.userLastInteractions).length > 0 ? (
          <Row>
            <Col
              lg="6"
              md="12"
              sm="12"
              className="d-flex align-items-stretch"
            >
              <RecentInteractions
                data={this.props.callInfo.userTickets}
              ></RecentInteractions>
            </Col>
            <Col
              lg="6"
              md="12"
              sm="12"
              className="d-flex align-items-stretch"
            >
              <InteractionTrend
                data={this.props.callInfo.userLastInteractions}
              ></InteractionTrend>
            </Col>
          </Row>
        ) : null} */}
      </div>
    );
  }
}

export default ServicePanelView;
