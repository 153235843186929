export default function cleanStats(stats_object, percent, min_count){
    //This function will clear any data that falls under desired percentage of the max value of an array of objects.
    if (isNaN(percent) && isNaN(min_count)){
        return stats_object;
    }
    
    const clear_percent = percent * .01;
    const values = Object.values(stats_object).map(Number);
    const max_register = Math.max.apply(Math, values);
    let comparison_point;

    if (percent !== 0){
        comparison_point = max_register * clear_percent;
    } 
    else {
        comparison_point = min_count;
    }
    
    Object.keys(stats_object).forEach(
        (key) =>{
            if (stats_object[key] < comparison_point){
                delete stats_object[key]
            }
        }
    );

    return stats_object;
}