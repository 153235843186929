import React, { Component } from "react";
import {
    CardBody,
} from "reactstrap";

class NoTicketsToShow extends Component {
    render(){
        return(
            <CardBody>
                <h5>There are no incidents to show</h5>
                <p>Incidents will be displayed here when they exist for the current store.</p>
            </CardBody>        
        );
    }
}

export default NoTicketsToShow;