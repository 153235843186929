import ManagerPostTabs from 'components/ManagerPostTabs';
import React, { Component } from 'react';
// Bootstrap
import {
    Row,
    Col,
} from "reactstrap";

// Custom Components
import AgentsTabsMenu from '../components/AgentsTabsMenu';


class ManagerView extends Component {

    render() {
        //console.log("ManagerView props",this.props);
        return (
            <div className="content" style={{marginTop: (this.props.getContentTopMargin()) }}>
                <Row>
                    <Col md="7">
                        <AgentsTabsMenu />
                    </Col>
                    <Col md="5">
                        <ManagerPostTabs 
                            dangerAlert={this.props.dangerAlert}
                            successAlert={this.props.successAlert}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ManagerView;