import React from "react";
import {
  CardBody,
  Badge
} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

var ps;

class Worknotes extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.timeline);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  render() {
    var styles = {
      maxHeight: "500px",
    };
    const maxCount = this.props.worklistMax;
    //console.log("Current Ticket info", JSON.stringify(this.props.currentTicketInfo));
    return (
        <CardBody style={{padding:"0"}}>
          <div className="ps-child wrapper" ref="timeline" style={styles}>
                  <ul className="timeline timeline-simple">
                    {this.props.data 
                      ? this.props.data.display_value
                          .split("\n\n")
                          .map(function (item, idx) {
                            if (item.includes("[code]")) {
                              let start = item.indexOf("[code]");
                              let end = item.indexOf("[/code]");
                              item = item.replace(
                                item.substring(start, end),
                                ""
                              );
                              item = item.replace("[/code]", "");
                            }

                            const splitItem = item.split(" - ");
                            const date = splitItem[0];
                            const desc = splitItem[1];
                            const other = desc
                              ? desc.split("(Work notes)")
                              : null;
                            
                            if (date && desc && other) {
                              return (
                                <li className="timeline-inverted">
                                  {other ? (
                                      <i className="nc-icon nc-check-2" />

                                  ) : (                                    
                                      <i className="nc-icon nc-simple-remove" />
                                  )}

                                  <div className="timeline-panel">
                                    <div className="timeline-heading">
                                      {other ? (
                                        <Badge color="info" pill>
                                          {other[0].trim()}
                                        </Badge>
                                      ) : (
                                        <Badge color="danger" pill>
                                          Unknow Author
                                        </Badge>
                                      )}
                                    </div>
                                    <div className="timeline-body">
                                      <p>
                                        {other
                                          ? other[1].trim()
                                          : "Empty Description"}
                                      </p>
                                    </div>
                                    <h6>
                                      <b>
                                        <i
                                          class="fa fa-clock-o"
                                          aria-hidden="true"
                                        ></i>
                                        {date ? date : "Unkown date"}
                                      </b>
                                    </h6>
                                  </div>
                                </li>
                              );
                            }
                          })
                      : null}
                  </ul>
                </div>
        </CardBody>
    );
  }
}

// Specifies the default values for props:
Worknotes.defaultProps = {
  worklistMax: 10,
};

export default Worknotes;
