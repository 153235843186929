import React from "react";
import App from "App";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import "amazon-connect-streams";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import LayoutVariables from "variables/layout";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "store/reducer.js"
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
//My custom styles:
import "custom_styles.css";
//Initial state fetch
import API_AgentProfileGet from './variables/apis';

const hist = createBrowserHistory();
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const store = createStore(reducer, composedEnhancer);

ReactDOM.render(LayoutVariables.brand, document.getElementById("brand"));
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById("root"));