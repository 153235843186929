import React, {Component} from "react";
import {CardBody} from "reactstrap";

class FetchingData extends Component {
    render(){
        return(
            <CardBody className="fetching-data text-center">
                <h5>Fetching data...</h5>  
                <i className="fas fa-spinner fa-pulse text-info icon-bigger"/>
            </CardBody>
        );
    }
}

export default FetchingData;