import React, { Component } from "react";
// Bootstrap
import {
    Card,
    CardHeader,
    CardBody,
    Nav,
    NavLink,
    NavItem,
    TabPane,
    TabContent,
    } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import NotificationPostPanel from "./NotificationPostPanel";
import OpeningScriptPost from "./OpeningScriptPost";


class ManagerPostTabs extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab : "1",
        }
    }

    render(){
        return(
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">
                        Posting Panel &nbsp;
                        <i className="fas fa-bullhorn text-primary"></i>
                    </CardTitle>
                    <p>Send notifications to agents and modify the default opening script.</p>
                </CardHeader>
                <CardBody>
                <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                    <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                            <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "1" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "1" })}
                                >Notifications
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "2" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "2" })}
                                >Opening script
                            </NavLink>
                        </NavItem>
                    </Nav>
                    </div>
                </div>
                    <TabContent id="my-tab-content" activeTab={this.state.activeTab}>
                        <TabPane tabId="1" role="tabpanel">
                             <NotificationPostPanel 
                                dangerAlert={this.props.dangerAlert}
                                successAlert={this.props.successAlert}
                            />
                        </TabPane>

                        <TabPane tabId="2" role="tabpanel">
                            <OpeningScriptPost 
                                dangerAlert={this.props.dangerAlert}
                                successAlert={this.props.successAlert}
                            />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        );
    }
}

export default ManagerPostTabs;