import React, {Component} from "react";
import {
    Card,
    CardBody,
    Nav,
    NavLink,
    NavItem,
    TabPane,
    TabContent,
} from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";

// Custom components
import CallerDetails from "./CallerDetails";
import SearchForStore from "./SearchForStore";
import SearchForUser from "./SearchForUser";

class CallerDetailsTabs extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab : "1",
        }
    }


    render(){
        console.log("CallerDetail props",this.props);
        return(
            <Card>
                <CardHeader>
                    <h5>
                        Caller details &nbsp;
                        <i class="far fa-address-card text-primary"></i>
                    </h5>
                </CardHeader>
                <CardBody>
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                            <Nav id="tabs" role="tablist" tabs>
                                <NavItem>
                                    <NavLink
                                        data-toggle="tab"
                                        role="tab"
                                        href="#"
                                        className={this.state.activeTab === "1" ? "active" : ""}
                                        onClick={() => this.setState({ activeTab: "1" })}
                                        >Current Call
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        data-toggle="tab"
                                        role="tab"
                                        href="#"
                                        className={this.state.activeTab === "2" ? "active" : ""}
                                        onClick={() => this.setState({ activeTab: "2" })}
                                        >Search store
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        data-toggle="tab"
                                        role="tab"
                                        href="#"
                                        className={this.state.activeTab === "3" ? "active" : ""}
                                        onClick={() => this.setState({ activeTab: "3" })}
                                        >Search user
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                    <TabContent id="my-tab-content" activeTab={this.state.activeTab}>
                        <TabPane tabId="1" role="tabpanel">
                            <CallerDetails data={this.props.data} />
                        </TabPane>

                        <TabPane tabId="2" role="tabpanel">
                            <SearchForStore />
                        </TabPane>

                        <TabPane tabId="3" role="tabpanel">
                            <SearchForUser />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        );
    }
}

export default CallerDetailsTabs;