/* Many functions that originally where executed in this componen have been moved to 
login screen in order to accelerate the loading process, ex get cognito user and
cheking if user has a profile picture available */

import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import MainNavbar from "components/Navbars/MainNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
//Amplify
import { Auth , API} from 'aws-amplify';

import {
  API_QueryData,
  API_SSM_describeInstanceInformation,
  API_LastInteractions,
  API_GetSnowAttachment,
  API_AgentSettings,
} from "variables/apis";

import { connect } from "react-redux";
import routes from "routes.js";
import qs from "querystring";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import TicketModal from "components/TicketModal/TicketModal";
import { host } from "variables/apis";

//Used to generate random contact points on demand
import randomChoice from "AuxFunctions/randomChoice";
import contactPoints from "variables/contactPoints";

var ps;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_toggle: false,
      alert: null,
    };
    this.modalToggle = this.modalToggle.bind(this);
    this.handleGetAgentProfile = this.handleGetAgentProfile.bind(this);
    this.getContentTopMargin = this.getContentTopMargin.bind(this);
    this.currentStateDuration = "";
    this.mainPanel = React.createRef();
  }

  notificationAlert = React.createRef();
  notify = (text, type, icon, place) => {
    var options = {};
    options = {
      place: place, //tc = top center, br = bottom right and so on
      message: (
        <div>
          <div>{text}</div>
        </div>
      ),
      type: type,
      icon: icon,
      autoDismiss: 7,
    };
    this.notificationAlert.current.notificationAlert(options);
  };

  basicAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={message}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        />
      ),
    });
  };

  titleAndTextAlert = (title, message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    });
  };

  successAlert = (title, text) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {text}
        </ReactBSAlert>
      ),
    });
  };

  dangerAlert = (title, text) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          {text}
        </ReactBSAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  // function that returns true if value is email, false otherwise
  verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  handleGetUserInfo = async (contactPoint) => {
    //if true, contact point is a phone number if not, it's an email
    this.handleResetInfo();
    const init = {
      headers : { 
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    //Setting fetching status
    this.props.onChangeState("fetchingUserinfo",true);
    this.props.onChangeState("fetchingLocation",true);

    //Generating a random valid contact point number
    //contactPoint = randomChoice(contactPoints);
    //

    if (!this.verifyEmail(contactPoint)) {
      contactPoint = contactPoint.slice(contactPoint.length - 7);
    }
    API_QueryData.getUserInfo.phoneNumber = contactPoint;
    axios.get(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getUserInfo)}`, init)
      .then((response) => {
        //console.log("user info: " + JSON.stringify(response));
        if (
          response.data.data.result &&
          response.data.data.result.length > 0
        ) {
          //console.log("userInfo antes: " + this.props.userInfo);
          this.props
            .onChangeState("userInfo", response.data.data.result)
            .then(() => {
              this.handleGetStoreTickets(response.data.data.result[0].location.value);
              //this.handleGetUserTickets(response.data.data.result[0].sys_id.value);
              // this.handleGetUserHardware(response.data.data.result[0].sys_id);
              // this.handleLastInteractions(response.data.data.result[0].phone);
              // if (response.data.data.result[0].location)
              this.handleGetUserLocation(response.data.data.result[0].location.value);
              // if (response.data.data.result[0].department)
              //   this.handleGetUserDepartment(
              //     response.data.data.result[0].department.value
              //   );
              // if (response.data.data.result[0].company)
              //   this.handleGetUserCompany(
              //     response.data.data.result[0].company.value
              //   );
            });
        }
        this.props.onChangeState("fetchingUserinfo",false);
      });
  };

  handleGetUserLocation = async (location_id) =>{
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    this.props.onChangeState("fetchingLocation",true);
    fetch(`${host}snow-query?query=location&sys_id=${location_id}`, init)
    .then(response => response.json())
    .then(data => {
      try {
        //console.log("User location query data",data);
        this.props.onChangeState("userLocation", data.data.result[0]);
      }
      catch {
        console.log("Error while fetching user location data")
      }
      this.props.onChangeState("fetchingLocation",false);
    });
  }


  handleGetUserCompany = async (company_id) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getCompany.company_id = company_id;
    axios.get(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getCompany)}`, init)
      .then((response) => {
        this.props.onChangeState("userCompany", response.data.data.result);
        /**
         *  this.setState({
           userCompany: response.data.data.result,
         });
         */
        //console.log("COMPANY: " + JSON.stringify(response));
      });
  };


  /* handleGetUserLocation = async (user_id_location) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getUserLocation.user_id_location = user_id_location;
    axios.get(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getUserLocation)}`, init)
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          this.props.onChangeState("userLocation", response.data.data.result);
        }
      });
  }; */

  handleGetUserHardware = async (user_id) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getHardware.user_id = user_id;
    axios.get(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getHardware)}`,init)
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          this.props
            .onChangeState("userHardware", response.data.data.result[0])
            .then(() => {
              this.handleGetInstanceInformation(
                this.props.userHardware.asset_tag.value
              );
            });

          /**
           *  this.setState(
             {
               userHardware: response.data.data.result[0],
             },
             () => {
               //console.log(JSON.stringify(this.state.userHardware));
               this.handleGetInstanceInformation(
                 this.state.userHardware.asset_tag.value
               );
               //console.log("HARDWARE: " + JSON.stringify(response));
             }
           );
           */
        }
      });
  };

  handleGetStoreTickets = async (store_id) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getStoreTickets.keys = store_id;
    fetch(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getStoreTickets)}`, init)
      .then(response => response.json())
      .then(data => {
          //console.log("Response", data);
          try {
            this.props.onChangeState("userTickets", data.data.result);
          }
          catch {
            console.log("Error while fetching Store Tickets.")
          }
        }
      );
  };

  handleGetUserTickets = async (user_id) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getTickets.sys_user_id = user_id;
    axios.get(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getTickets)}`, init)
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          this.props
            .onChangeState("userTickets", response.data.data.result)
            .then(() => {
              //this.handleGetUserTicketsLastMonth(this.props.userInfo.sys_id);
              this.handleGetUserClosedTickets(this.props.userInfo.sys_id.display_value);
            });
            //console.log("Tickets response:",response)
        }
      });
  };


  handleGetUserClosedTickets = async (user_id) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getClosedTickets.sys_user_id = user_id;
    axios.get(`${API_QueryData.endpoint}?${qs.stringify(API_QueryData.getClosedTickets)}`,init)
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          //Here we combine open tickets with the closed ones. This may need to change depending on business rules
          this.props.onChangeState('userTickets', this.props.userTickets.concat(response.data.data.result));
          //console.log("Closed tickets: ", response);
          /*this.setState((prevState) => ({
            userTickets: [...prevState.userTickets, response.data.data.result],
          }));*/
        }
      });
  };


  handleGetUserTicketsLastMonth =  async (user_id) => {
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_QueryData.getTicketsLastMonth.sys_user_id = user_id;
    axios
      .get(
        API_QueryData.endpoint +
          "?" +
          qs.stringify(API_QueryData.getTicketsLastMonth), init
      )
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          this.props.onChangeState(
            "userTickets30days",
            response.data.data.result
          );
          //console.log("Tickets last month: ", response);
        }
      });
  };

  //This API GET the file_sys_id or the Binary file from a ticket
  handleGetAttachment = async (table_sys_id, file_sys_id) => {
    API_GetSnowAttachment.headers.keys = table_sys_id; // Real Value for Prod
    //API_GetSnowAttachment.headers.keys = "d971b590dbbc601082506bba4b96191a"; //Harcoded value for testing
    //API_GetSnowAttachment.headers.file_sys_id = file_sys_id;
    //API_GetSnowAttachment.headers.table_sys_id = table_sys_id;
    //console.log("table_sys_id: "+table_sys_id);
    const init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    axios.get(`${API_GetSnowAttachment.endpoint}?${qs.stringify(API_GetSnowAttachment.headers)}`, init)
      .then((response) => {
        if (response.data && Object.keys(response.data).length > 0) {
          console.log("attachment response",response);
          this.props.onChangeState(
            "incident_attachments",
            response.data.data.result
          );
        }
      });
  };


  /* //This API GET the file_sys_id or the Binary file from a ticket 
  // MOVED DIRECTLY TO COMPONENT
  handleGetKnowledgeArticle = (keys) => {
    API_GetKnowledgeArticle.data.keys = keys;
    API_GetKnowledgeArticle.data.connectUser =
      this.props.ccpAgentProfile.username;
    axios
      .get(
        API_GetKnowledgeArticle.endpoint +
          "?" +
          qs.stringify(API_GetKnowledgeArticle.data)
      )
      .then((response) => {
        //console.log(JSON.stringify(response));
        if (response.data.data.result && response.data.data.result.length > 0) {
          this.props.onChangeState("kbArticles", response.data.data.result);
        }
      });
  }; */


  /* //Used on Navbars/Search/Search.js component for retrieve any ticket related.
  handleGetTicketSearch(key) => {
    console.log("Search key: ",key);
    if (key.length > 5){
      API_QueryData.getTicketSearch.keys = key;
      fetch(API_QueryData.endpoint + "?" + qs.stringify(API_QueryData.getTicketSearch))
        .then(response =>  response.json())
        .then(res => {
          console.log("Ticket Search:" , res);
          this.props.onChangeState("searchTickets", res.data.result);
        });
    }
  }; */


  //This one is for the user last interactions
  //Note: It is called on handleGetUserInfo()
  handleLastInteractions = (phoneNumber) => {
    API_LastInteractions.getLastInteraction.phoneNumber = phoneNumber;
    axios
      .get(
        API_LastInteractions.endpoint +
          "?" +
          qs.stringify(API_LastInteractions.getLastInteraction)
      )
      .then((response) => {
        //console.log("INTERACTIONS: " + JSON.stringify(response));

        if (response.data.data && Object.keys(response.data.data).length > 0) {
          this.props.onChangeState("userLastInteractions", response.data.data);
          /*
          this.setState({
            userLastInteractions: response.data.data,
          });*/
        }
      });
  };


  //Fetchs tickets assigment groups.
  handleGetAssigmentGroups = (key) => {
    API_QueryData.getAssigmentGroups.keys = key;
    this.props.onChangeState("isFetchingAssigmentGroups", true);
    axios
      .get(
        API_QueryData.endpoint +
          "?" +
          qs.stringify(API_QueryData.getAssigmentGroups)
      )
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          this.props.onChangeState(
            "assigmentGroups",
            Object.values(response.data.data.result)
          );
          this.props.onChangeState("isFetchingAssigmentGroups", false);
          this.props.onChangeState("assignedTo", undefined);
          /*
          this.setState({
            assigmentGroups: Object.values(response.data.data.result),
            isFetchingAssigmentGroups: false,
            assignedTo: undefined,
          });*/
        }
      });
  };


  //Fetchs tickets assigment groups.
  handleGetAssignedTo = (key, group_id) => {
    API_QueryData.getAssignedTo.keys = key;
    API_QueryData.getAssignedTo.group_id = group_id;
    axios
      .get(
        API_QueryData.endpoint + "?" + qs.stringify(API_QueryData.getAssignedTo)
      )
      .then((response) => {
        if (
          response.data.data.result &&
          Object.keys(response.data.data.result).length > 0
        ) {
          this.props.onChangeState(
            "assignedTo",
            Object.values(response.data.data.result)
          );
        }
      });
  };

  async handleGetAgentProfile(profile){
    // Fetches Agent Profile and Configurations
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }

    API.get("user360","agent-profile?username="+profile, Init)
    .then(response => {
      this.props.onChangeState("agentProfile", response).then(() => {
        //console.log("agentProfile: "+JSON.stringify(response.data))
        if (response.item.settings && Object.keys(response.item.settings).length > 0 ){
          if (response.item.settings.BgSB !== undefined){
            this.handleBgClick(response.item.settings.BgSB);
          }
          if (response.item.settings.MiniSB !== undefined){
            this.setMiniClick(response.item.settings.MiniSB);
          }
          this.setDarkMode(response.item.settings.DarkMode);
          //this.handleActiveClick(response.data.item.settings.FontSB);
        }
      });
    });
  };

  /* Deprepcated as we are now using  
  handleGetAgentProfile = (profile) => {
    //this.props.onChangeState("ccpAgentProfile", profile);
    //console.log(JSON.stringify(profile))
    API_AgentProfileGet.getProfile.username = this.props.user;
    axios
      .get(
        API_AgentProfileGet.endpoint +
          "?" +
          qs.stringify(API_AgentProfileGet.getProfile)
      )
      .then((response) => {
        if (response.data && Object.keys(response.data).length > 0) {
          this.props.onChangeState("agentProfile", response.data).then(() => {
            //console.log("agentProfile: "+JSON.stringify(response.data))
            if (
              response.data.item.settings &&
              Object.keys(response.data.item.settings).length > 0
            ) {
              this.handleBgClick(response.data.item.settings.BgSB);
              this.setMiniClick(response.data.item.settings.MiniSB);
              this.setDarkMode(response.data.item.settings.DarkMode);
              //this.handleActiveClick(response.data.item.settings.FontSB);
            }
          });
        }
      });
  }; */

  handleGetInstanceInformation = (instance_id) => {
    API_SSM_describeInstanceInformation.data.params.Filters[0].Values[0] =
      "i-0674d31621d77e847";
    axios({
      method: API_SSM_describeInstanceInformation.method,
      url: API_SSM_describeInstanceInformation.endpoint,
      data: API_SSM_describeInstanceInformation.data,
    })
      .then((response) => {
        this.props.onChangeState(
          "userInstance",
          response.data.InstanceInformationList[0]
        );
        //console.log(JSON.stringify(response));
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          this.props.onChangeState("userInstance", "Error: Device not found");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          this.props.onChangeState(
            "userInstance",
            "Error: Can't connect to device"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
        // console.log(error.config);
      });
  };


  //This one is used when the agent is ready to take another call.
  handleResetInfo = () => {
    this.props.onChangeState("phoneNumber", null)
    this.props.onChangeState("userInfo", {});
    this.props.onChangeState("userLocation", {});
    this.props.onChangeState("userHardware", {});
    this.props.onChangeState("userTickets", []);
    this.props.onChangeState("userTickets30days", []);
  };

  //This handler checks if credentials for snow are correct. If they are, then makes a callback.
 /*  handleCheckSnowAccount = (callback) => {
    API_CheckSnowAccount.headers.connectUser =
      this.props.ccpAgentProfile.username;
    axios
      .get(
        API_CheckSnowAccount.endpoint +
          "?" +
          qs.stringify(API_CheckSnowAccount.headers)
      )
      .then((response) => {
        //console.log(JSON.stringify(response));
        if (response.data.status === "success") {
          callback();
        } else {
          this.dangerAlert(
            "Something went wrong! ⚠️",
            "ServiceNow credentials need to be updated."
          );
        }
      })
      .catch((error) => {
        return false;
      });
  }; */

  handleNewAgentState = (newState) => {
    this.props.onChangeState("currentAgentState", newState);
  };

  handleStateDuration = (duration) => {
    this.currentStateDuration =
      duration !== undefined && duration !== "" ? duration : "N/A";
  };

  handleGetStateDuration = () => {
    return this.currentStateDuration;
  };

  handleQueue = (queue) => {
    console.log("setting queue");
    this.props.onChangeState(
      "currentQueue",
      queue !== null && queue !== "" ? queue : "N/A"
    );
  };

  /* handleToolsBadge = (newState) => {
    this.props.onChangeState("activeToolsBadge", newState);
  }; */

  /* handleActiveClick = (color) => {
    this.props.onChangeState("activeColor", color);
    this.handleUpdateSettings();
  }; */

  handleBgClick = (color) => {
    this.props.onChangeState("backgroundColor", color);
    setTimeout(this.handleUpdateSettings, 500);
  };


  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.props.onChangeState("sidebarMini", false);
      //setTimeout(this.handleUpdateSettings, 500);
    } else {
      this.props.onChangeState("sidebarMini", true);
      //setTimeout(this.handleUpdateSettings, 500);
    }
    document.body.classList.toggle("sidebar-mini");
    document.body.classList.toggle("content-full");
  };
  

  setMiniClick = (isMini) => {
    if (isMini) {
      this.props.onChangeState("sidebarMini", true);
      document.body.classList.add("sidebar-mini");
    } else {
      this.props.onChangeState("sidebarMini", false);
      document.body.classList.remove("sidebar-mini");
    }
  };


  handleDarkMode = () => {
    this.props.onChangeState("darkMode", !this.props.darkMode);
    document.body.classList.toggle("dark-mode");
    setTimeout(this.handleUpdateSettings, 500);
  };
  


  setDarkMode = (isActive) => {
    if (isActive) {
      this.props.onChangeState("darkMode", true);
      document.body.classList.add("dark-mode");
    } else {
      this.props.onChangeState("darkMode", false);
      document.body.classList.remove("dark-mode");
    }
  };
  
  getContentTopMargin = () => {
    const navBars = document.getElementsByClassName("navbar");
    const minMarging = 72;
    const maxMarging = 250;
    const navBar = (navBars !== undefined && navBars != null)? navBars[0]:null;
    const contentMarginTop = (navBars !== undefined && navBar != null && "offsetHeight" in navBar)?(navBar.offsetHeight).toString():"72px";
    return (contentMarginTop < 72)? "72px":contentMarginTop+"px"
  }

  modalToggle() {
    this.setState((state) => ({
      modal_toggle: !state.modal_toggle,
    }));
  }

  handleUpdateTicket = (ticket_info) => {
    this.props.onChangeState("update_ticket_info", ticket_info);
    this.modalToggle();
    this.handleGetAttachment(ticket_info.sys_id.value, "");
  };

  handleUpdateSettings = async() => {
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    API_AgentSettings.headers.username = this.props.user;
    /* API_AgentSettings.data. */let settings = {
      DarkMode: this.props.darkMode,
      BgSB: this.props.backgroundColor,
      //MiniSB: this.props.sidebarMini,
      FontSB: this.props.activeColor,
    };

    axios({
      method: API_AgentSettings.method,
      url:
        `${API_AgentSettings.endpoint}?${qs.stringify(API_AgentSettings.headers)}`,
      data: {settings : settings}, //API_AgentSettings.data,
      headers : Init.headers,
      })
      .then((response) => {})
      .catch((error) => {});
  };

  mainJSRender(){
    this.render();
  }

  componentDidMount() {
    // Perfect Scrollbar
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    
    // Setting sidebar mini by default.
    this.setMiniClick(true);
    window.addEventListener("resize", ()=>{
      this.render();
    }, true);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {

  }

  render() {
    //console.log("Main Props: ", this.props);
    return (
      <div className="wrapper">
        <NotificationAlert ref={this.notificationAlert} />
        {this.state.alert}
        <TicketModal
          update_ticket_info={this.props.update_ticket_info}
          assigmentGroups={this.props.assigmentGroups}
          assignedTo={this.props.assignedTo}
          ccpAgentProfile={this.props.ccpAgentProfile}
          handleGetAssigmentGroups={this.handleGetAssigmentGroups}
          handleGetAssignedTo={this.handleGetAssignedTo}
          handleGetUserTickets={this.handleGetUserTickets}
          handleToggleModal={this.modalToggle}
          isFetchingAssigmentGroups={this.props.isFetchingAssigmentGroups}
          successAlert={this.successAlert}
          dangerAlert={this.dangerAlert}
          incident_attachments={this.props.incident_attachments}
          modal_toggle={this.state.modal_toggle}
        />
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.props.backgroundColor}
          //activeColor={this.props.activeColor}
          agentProfile={this.props.agentProfile}
          ccpAgentProfile={this.props.ccpAgentProfile}
        />
        <div className="main-panel" ref="mainPanel">
          <MainNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            currentAgentState={this.props.currentAgentState}
            searchTickets={this.props.searchTickets}
            currentQueue={this.props.currentQueue}
            handleGetStateDuration={this.handleGetStateDuration}
            bgColor={this.props.backgroundColor}
            getContentTopMargin={this.getContentTopMargin}
            darkMode={this.props.darkMode}
            mainJSRender={this.mainJSRender}
            //activeColor={this.props.activeColor}
            sidebarMini={this.props.sidebarMini}
            handleGetTicketSearch={this.handleGetTicketSearch}
            handleActiveClick={this.handleActiveClick}
            handleBgClick={this.handleBgClick}
            handleUpdateTicket={this.handleUpdateTicket}
            handleDarkMode={this.handleDarkMode}
/*             handleCheckSnowAccount={this.handleCheckSnowAccount.bind(this)}
 */          />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={(props) => (
                    <prop.component
                      callInfo={this.props}
                      getContentTopMargin={this.getContentTopMargin}
                      handleGetAssigmentGroups={this.handleGetAssigmentGroups}
                      handleGetAssignedTo={this.handleGetAssignedTo}
                      handleGetUserTickets={this.handleGetUserTickets}
                      handleToolsBadge={this.handleToolsBadge}
                      handleGetAgentProfile={this.handleGetAgentProfile}
                      handleUpdateTicket={this.handleUpdateTicket}
                      /* handleGetKnowledgeArticle={this.handleGetKnowledgeArticle} */
                      notify={this.notify}
                      successAlert={this.successAlert}
                      dangerAlert={this.dangerAlert}
                      handleCheckSnowAccount={this.handleCheckSnowAccount}
                    />
                  )}
                  key={key}
                />
              );
            })}
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
        <FixedPlugin
          handleNewAgentState={this.handleNewAgentState}
          handleFetchCallData={this.handleGetUserInfo}
          handleResetInfo={this.handleResetInfo}
          handleQueue={this.handleQueue}
          handleStateDuration={this.handleStateDuration}
          handleGetAgentProfile={this.handleGetAgentProfile}
          notify={this.notify}
        />
      </div>
    );
  }
}


// ---- Redux functions ---- //

const mapStateToProps = (state) => {
  return {
    // Cognito User
    user : state.user,
    userdata : state.userdata,
    // Settings
    backgroundColor : state.backgroundColor,
    darkMode : state.darkMode,
    sidebarMini : state.sidebarMini,
    opening_script : state.opening_script,
    //activeColor : state.activeColor,


    //Service Now Data
    userInfo: state.userInfo,
    userLocation: state.userLocation,
    userHardware: state.userHardware,
    userInstance: state.userInstance,
    userTickets: state.userTickets,
    userTickets30days: state.userTickets30days,
    userDepartment: state.userDepartment,
    userLastInteractions: state.userLastInteractions,
    userCompany: state.userCompany,
    searchTickets: state.searchTickets,
    /* kbArticles: state.kbArticles, */
    assigmentGroups: state.assigmentGroups,
    assignedTo: state.assignedTo,
    incident_attachments: state.incident_attachments,
    isFetchingAssigmentGroups: state.isFetchingAssigmentGroups,
    update_ticket_info: state.update_ticket_info,
    fetchingLocation : state.fetchingLocation,
    fetchingUserinfo : state.fetchingUserinfo,

    //AWS Data
    ccpAgentProfile: state.ccpAgentProfile,
    agentProfile: state.agentProfile,
    metrics: state.metrics,
    currentAgentState: state.currentAgentState,
    currentQueue: state.currentQueue,
    activeToolsBadge: state.activeToolsBadge,
    phoneNumber: state.phoneNumber,

    // Uer profiling
    user_has_picture : state.user_has_picture,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  };
};

export const updateState = (type, value) => (dispatch) => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
};

// ---- End Redux functions ---- //

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
