import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody,
} from "reactstrap";
import qs from "querystring";
//Amplify
import { Auth } from "aws-amplify"
// Redux
import { connect } from "react-redux";

import { API_QueryData } from "variables/apis";
import TicketsTable from "./TicketsTable";
import FetchingData from "./FetchingData";


class TicketSearch extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_fetching : false,
            query : "",
            results : [],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e){
        e.preventDefault();
        this.handleFectch();
    }

    handleChange(e){
        let target = e.target
        let name = target.name;
        let value = target.value;
        this.setState({[name]:value});
    }

    async handleFectch(){
        this.setState({is_fetching:true});
        const init = {
            headers : { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
        }
        API_QueryData.getTicketSearch.keys = this.state.query;
        fetch(API_QueryData.endpoint + "?" + qs.stringify(API_QueryData.getTicketSearch), init)
        .then(response => response.json())
        .then(res => {
            //console.log("Tickets Search Response",res);
            try {
                if (res.data.result){
                    this.setState({results : res.data.result});
                }
            }
            catch{
                console.log("An error ocurred while fetching search ticket results");
            }
            this.setState({is_fetching:false});
        });
    }

    render(){
        return(
            <CardBody>
                <Row>
                    <Col md="7">
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="query">Search by incident number, employee name/id, department, category and description:</Label>
                                <Input 
                                    placeholder="Search keywords..."
                                    value={this.state.query} 
                                    type="text" 
                                    id="ticket-query" 
                                    name="query" 
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup> 
                        </Form>
                    </Col>
                    <Col md="3">
                        <Button 
                            className="search-button-plus"
                            onClick={this.handleSubmit} 
                            color="info">Search incidents
                        </Button>
                    </Col>
                    <Col md="2" />
                </Row>
                <Row>
                    <Col>
                        {   
                            this.state.is_fetching 
                            ? <FetchingData />
                            : this.state.results.length > 0
                                ? <TicketsTable
                                    handleUpdateTicket={this.props.handleUpdateTicket}
                                    bgColor={this.props.backgroundColor} 
                                    data={this.state.results}/>
                                
                                :   <React.Fragment>
                                    <br />
                                    <p>
                                        No results to display, try to perform a new search.
                                    </p>   
                                    </React.Fragment>
                        }   
                    </Col>
                </Row>
            </CardBody>
        )
    }
}

// REDUX
const mapStateToProps = (state) => {
    return {
        backgroundColor: state.backgroundColor,
    };
};
  
  
export default connect(mapStateToProps, null)(TicketSearch);
  