import React, {Component} from 'react';
import "amazon-connect-streams";
import { Auth } from 'aws-amplify';
import LayoutVariables from 'variables/layout';
import { NavLink } from 'reactstrap';


class SignOutLink extends Component { 
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  Window;
  timer;
  
  async signout(){
    this.Window = window.open(LayoutVariables.CCP_SignOut, "_blank", "width=400, height=650");
    this.timer = setTimeout(()=>{this.Window.close()}, 500);
    try {
      await Auth.signOut({ global: true });
      } 
    catch (error) {
      console.log('error signing out: ', error);
      await Auth.signOut();
    }
  }
  

  handleClick(e) { 
    e.preventDefault();  
    this.signout();
  }


  render(){
    return(
      <li className="stick-to-bottom">
        <NavLink href="#" onClick={this.handleClick}>  
          <i className="fa fa-power-off" />
            <p>
              Sign out
            </p>            
        </NavLink>
      </li>            
    );
  }
} 


export default SignOutLink;
