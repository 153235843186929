import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  TabPane,
  TabContent,
  Container,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Button,
  Spinner,
} from "reactstrap";

import TicketDetails from "components/TicketDetails";
import UpdateTicketForm from "components/TicketForm/UpdateTicketForm/UpdateTicketForm";
import Worknotes from "components/TicketForm/Worknotes/Worknotes";
import Attachments from "components/TicketForm/Attachments/Attachments";

import { SNOW_TICKET_URL } from "variables/snow_urls";


export default class TicketModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      timeOut: false
    };
    this.toggle = this.toggle.bind(this);
    this.handleTicketUrl = this.handleTicketUrl.bind(this);
  }

  handleTicketUrl(sys_id){
    let popup = window.open(
        SNOW_TICKET_URL +  sys_id,
        "Ticket details", 
        "left=50,top=50,width=1000,height=700"
    );
}

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    setInterval(()=>{
      this.setState({
        timeOut: true,
      });
    }, 5000);
  }
  
  render() {
    return (
      <Modal
        isOpen={this.props.modal_toggle}
        toggle={this.props.handleToggleModal}
        size={"xl"}
      >
        <ModalHeader toggle={this.modal_toggle}>
        Incident Details
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col lg="8" md="12" sm="12" XS="12">
                <TicketDetails
                  currentTicketInfo={this.props.update_ticket_info}
                  ccpAgentProfile={this.props.ccpAgentProfile}
                  assigmentGroups={this.props.assigmentGroups}
                  assignedTo={this.props.assignedTo}
                  handleGetAssigmentGroups={this.props.handleGetAssigmentGroups}
                  handleGetAssignedTo={this.props.handleGetAssignedTo}
                  handleGetUserTickets={this.props.handleGetUserTickets}
                  handleToggleModal={this.props.handleToggleModal}
                  isFetchingAssigmentGroups={
                    this.props.isFetchingAssigmentGroups
                  }
                  successAlert={this.props.successAlert}
                  dangerAlert={this.props.dangerAlert}
                />

                <div className="text-center">
                  <Button
                    disabled={Boolean(this.state.ticketBeingCreated)}
                    className="btn-purple"
                    onClick={()=>{this.handleTicketUrl(this.props.update_ticket_info.sys_id.value)}}
                  >Update on Service Now
                  </Button>
                </div>
              </Col>

              <Col lg="4" md="12" sm="12">
                <Row>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tab"
                            href="#"
                            className={
                              this.state.activeTab === "1" ? "active" : ""
                            }
                            onClick={() => this.setState({ activeTab: "1" })}
                          >Worknotes
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tab"
                            href="#"
                            className={
                              this.state.activeTab === "2" ? "active" : ""
                            }
                            onClick={() => this.setState({ activeTab: "2" })}
                          >Attachments{" "}
                            {(this.props.incident_attachments &&
                            Object.keys(this.props.incident_attachments)
                              .length > 0) || this.state.timeOut === true ? null : (
                              <Spinner size="sm" color="primary" />
                            )}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>

                  <TabContent
                    className="text-left"
                    id="my-tab-content"
                    activeTab={this.state.activeTab}
                  >
                    <TabPane tabId="1" role="tabpanel">
                      { 
                        this.props.update_ticket_info.work_notes &&
                        this.props.update_ticket_info.work_notes.display_value != "" ?
                        <Worknotes
                          data={this.props.update_ticket_info.work_notes}
                        />
                        :
                        null
                      }
                    </TabPane>

                    <TabPane tabId="2" role="tabpanel">
                      {this.props.incident_attachments &&
                      Object.keys(this.props.incident_attachments).length >
                        0 ? (
                        <Attachments
                          incident_attachments={this.props.incident_attachments}
                        />
                      ) : null}
                    </TabPane>
                  </TabContent>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}
