import React, { Component } from "react";
import { 
    CardBody, 
    CardTitle, 
    CardFooter,
    CardHeader, 
    Button,
} from "reactstrap";
import PropTypes from 'prop-types';
import { SNOW_INSTANCE_URL, SNOW_KB_URL } from "../../../variables/snow_urls";


class KnowledgeDisplay extends Component {

    handleKbUrl(kb_number){
        let popup = window.open(
            SNOW_KB_URL +  kb_number,
            "Ticket details", 
            "left=50,top=50,width=1000,height=700"
        );
    }

    render() {
        return (
            <React.Fragment>
                <CardHeader>
                    <CardTitle>
                        <h4>{this.props.title}</h4> 
                    </CardTitle>
                </CardHeader>
                <CardBody className="kb-body">
                    <div 
                    id="inner-html"
                    dangerouslySetInnerHTML={{__html: this.props.body
                        .replace('sys_user.do', SNOW_INSTANCE_URL+"sys_user.do")
                        .replace("<A","<A target=_blank")}} 
                    />
                    {
                        this.props.number 
                        ? <Button 
                            className="btn-purple"
                            onClick={()=>{this.handleKbUrl(this.props.number)}}>
                            View on Service Now
                        </Button>

                        : null
                    }
                </CardBody>
                <CardFooter>
                    {this.props.updated}
                </CardFooter>
            </React.Fragment>
        );
    }
}

KnowledgeDisplay.propTypes = { 
    title: PropTypes.string, 
    body: PropTypes.string, 
    updated: PropTypes.string, 
}
KnowledgeDisplay.defaultValues = { 
    title: "", 
    body: "", 
    updated: "", 
}

export default KnowledgeDisplay;