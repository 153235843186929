// ---- ServiceNow CONFIGURATION ---- //

const SNOW_INSTANCE_URL = "https://advance.service-now.com/";

// Ticket Details Root URL
const SNOW_TICKET_URL = SNOW_INSTANCE_URL + "/nav_to.do?uri=incident.do?sys_id=";
// KB Details Root URL
const SNOW_KB_URL = SNOW_INSTANCE_URL + "/kb_view.do?sysparm_article=";
// Create ticket URL
const SNOW_CREATE_TICKET_URL = SNOW_INSTANCE_URL + "/nav_to.do?uri=incident.do?sys_id=-1"; 
// Create Call URL
const SNOW_CREATE_CALL_URL = SNOW_INSTANCE_URL + "/nav_to.do?uri=new_call.do?sys_id=-1";

export {
    SNOW_INSTANCE_URL,
    SNOW_TICKET_URL,
    SNOW_KB_URL,
    SNOW_CREATE_TICKET_URL,
    SNOW_CREATE_CALL_URL
};

