import React, { Component } from "react";
// Redux 
import { connect } from "react-redux";
// Bootstrap
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody,
    Row,
    Col,
} from "reactstrap";
// Amplify
import {Auth} from "aws-amplify";
import {host} from "../variables/apis";

var today_raw = new Date();
var max_date_raw = new Date().setDate(today_raw.getDate()+13);
var today = new Date().toISOString().split("T")[0];
var max_date = new Date(max_date_raw).toISOString().split("T")[0];

class NotificationPostPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            message : "",
            date : today,
            for : "All agents",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    handleChange(event){
        const id = event.target.id;
        this.setState({[id]:event.target.value});
    }

    clearForm(event){
        event.preventDefault();
        this.setState({message:"",date:today,for:"All agents"})
    }

    async handleSubmit(event){
        event.preventDefault();
        const Init = {
            headers : { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
        }
        let now = new Date();
        let datetime = now.toISOString();
        let data = {
            info : `${this.props.ccpAgentProfile.username} to ${this.state.for} on ${now}`,
            message : this.state.message ,
            from : this.props.ccpAgentProfile.username,
            for : this.state.for,
            created : datetime,
            expiration : this.state.date,
        }
        fetch(`${host}notifications`,
            {
                method:"POST",
                headers : Init.headers,
                body : JSON.stringify(data),
            }
        ).then(response => response.json())
        .then(response => {
            //console.log("Response", response);
            if (response.ResponseMetadata.HTTPStatusCode === 200){
                this.setState({message:"",date:today,for:"All agents"})
                this.props.successAlert("Success","Notification message has been posted.");
            } 
            else {
                this.props.dangerAlert("Error","Something happened. Please try again later.");
            }
        });
    }

    render(){
        return(
            <CardBody>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="message">Message</Label>
                                <Input onChange={this.handleChange} 
                                    type="textarea"
                                    required
                                    value={this.state.message} 
                                    id="message" 
                                    placeholder="Type your message"></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="for">Send to...</Label>
                                <Input
                                    value={this.state.for}
                                    onChange={this.handleChange} 
                                    type="select"
                                    required
                                    id="for">
                                        <option>All agents</option>
                                        {
                                            this.props.agents_status.map((agent,id) =>
                                                agent.agentstatus !== "offline" //Agents must be online
                                                && (Date.now() - agent.RequestTimestamp)/1000 < 28800 // This will discard bugged agents with status longer than 8 hours 
                                                ?
                                                <option key={id}>{agent.agentname}</option>
                                                :
                                                null
                                            )
                                        }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>   
                        <Col>
                            <FormGroup>
                                <Label for="expiration">Expiration date</Label>
                                <Input 
                                    onChange={this.handleChange} 
                                    min={today}
                                    max={max_date}
                                    value={this.state.date} 
                                    type="date" 
                                    required
                                    id="date" 
                                    placeholder="Expiration"></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button className="btn-info">Send Notification</Button>
                            <Button onClick={this.clearForm} 
                                className="btn-warning">Reset Form</Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        );
    }
}

// ---- REDUX STUFF ---- //
const mapStateToProps = state => {
    return {
        ccpAgentProfile : state.ccpAgentProfile,
        monthly_data : state.monthly_data,
        agents_status : state.agents_status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeState: (type, value) => dispatch(updateState(type, value)),
    }
}

const updateState = (type, value) => dispatch => {
    dispatch({
        type: type,
        value,
    });
    return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(NotificationPostPanel);