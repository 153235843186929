function formatDateTime(string){
// Receives a datetime raw string and formats it into a display format.
    if (!string){
        return "unavailable"
    }
    let date, time
    try {
        let s = string.replace("T"," ").replace("Z","").split(" ")
        date = s[0]
        time = s[1].split(".")[0] //eliminates miliseconds
        date = (date.split("-").reverse()).join("/")

    }
    catch {
        return "unavailable"
    }
    
    return date + " at " + time
}

export default formatDateTime;