import { month, last_month, last2_month} from "../variables/months";

const initialState = {
    // Date
    month : month,
    last_month : last_month,
    last2_month : last2_month,
    //UI Settings
    backgroundColor: "tcs",
    darkMode: false,
    sidebarMini: true,
    opening_script : "",
    //activeColor: "black",

    /* //DWS Data
    dwsHardware: [],
    dwsRemoteActions: [],
    dwsInstallAppList: [],
    dwsPingStatus: false,
    dwsAvgMemoryCpuUsage: [], */

    //Service Now Data
    userInfo: [],
    userLocation: {},
    fetchingLocation : false,
    fetchingUserinfo : false,
    fetchingAllTickets : false,
    userHardware: {},
    userInstance: {},
    userTickets: [],
    userTickets30days: [],
    userDepartment: {},
    userLastInteractions: {},
    userCompany: {},
    searchTickets: [],
    kbArticles: [],
    selectedKB : {},
    assigmentGroups: [],
    assignedTo: [],
    incident_attachments: [],
    isFetchingAssigmentGroups: Boolean(true),
    update_ticket_info: {},

    // Tickets View
    all_tickets: {
        open : [],
        in_progress : [],
        solved : [],
        canceled : []
    },

    //AWS Data
    ccpAgentProfile: {},
    agentProfile: {},
    metrics: {},
    currentAgentState: "",
    currentQueue: "",
    activeToolsBadge: Boolean(true),
    phoneNumber: null,

    //Amplify user
    user : "",
    userdata : "",
    api_token : "",

    // Dashboard JSON Stats
    daily_data : {
        DETAILS: {},
        INBOUND: {
            TODAY : {
                AGENTS:[]
            },
        },
        OUTBOUND: {
            TODAY : {},
        }
    },
    weekly_data : {
        DETAILS: {},
        INBOUND: {
            WEEK : {
                TOTAL : {},
                AGENTS:[]
            },
        },
        OUTBOUND: {
            TODAY : {},
        }
    },
    monthly_data : {
        DETAILS: {},
        INBOUND: {
            MONTH : {
                TOTAL : {},
                AGENTS : []
            } 
        },
        OUTBOUND: {
            TODAY : {}
        },
    },
    active_outages : "0",
    chart_data : {
        d_sla_ans_data : [],
        d_sla_aban_data :  [],
        w_sla_ans_data : [],
        w_sla_aban_data :  [],
        m_sla_ans_data : [],
        m_sla_aban_data : []
    },

    percents : {
        // day
        day_sla_ans_percent : undefined,
        day_sla_aban_percent : undefined,
        // week
        week_sla_ans_percent : undefined,
        week_sla_aban_percent : undefined,
        // month
        mon_sla_ans_percent :  undefined,
        mon_sla_aban_percent : undefined,
    },

    // Agent JSON Stats
    d_agent_stats : {
        AGENT: "",
        COUNT : "0",
        AHT : "0",
        AVG_AHT : "0",
        ACW : "0",
        HOLD : "0",
        LHOLD : "0"
      },
    w_agent_stats : {
    AGENT: "",
    COUNT : "0",
    AHT : "0",
    AVG_AHT : "0",
    ACW : "0",
    HOLD : "0",
    LHOLD : "0"
    },  
    m_agent_stats : {
        AGENT: "",
        COUNT : "0",
        AHT : "0",
        AVG_AHT : "0",
        ACW : "0",
        HOLD : "0",
        LHOLD : "0"
    },

    agents_status : [],
    calls_in_queue : [],

    historical_data : {  
        HISTORICAL : [],
        TIMELINE_MAP : {
            WEEK : {},
            MONTH : {},
            LAST_MONTH : {},
            YEAR : {},
        }
    },

    historical_last_year : {  
        HISTORICAL : [],
        TIMELINE_MAP : {
            WEEK : {},
            MONTH : {},
            LAST_MONTH : {},
            YEAR : {},
        }
    },

    // User profiling
    manager_access : false,
    user_has_picture : false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'backgroundColor':
            return {
                ...state,
                backgroundColor: action.value
            }
        case 'activeColor':
            return {
                ...state,
                backgroundColor: action.value
            }
        case 'darkMode':
            return {
                ...state,
                darkMode: action.value
            }
        case 'sidebarMini':
            return {
                ...state,
                sidebarMini: action.value
            }
        case 'dwsHardware':
            return {
                ...state,
                dwsHardware: action.value
            }
        case 'dwsRemoteActions':
            return {
                ...state,
                dwsRemoteActions: action.value
            }
        case 'dwsInstallAppList':
            return {
                ...state,
                dwsInstallAppList: action.value
            }
        case 'dwsPingStatus':
            return {
                ...state,
                dwsPingStatus: action.value
            }
        case 'dwsAvgMemoryCpuUsage':
            return {
                ...state,
                dwsAvgMemoryCpuUsage: action.value
            }
        case 'userInfo':
            return {
                ...state,
                userInfo: action.value
            }
        case 'userLocation':
            return {
                ...state,
                userLocation: action.value
            }
        case 'userHardware':
            return {
                ...state,
                userHardware: action.value
            }
        case 'userInstance':
            return {
                ...state,
                userInstance: action.value
            }
        case 'userTickets':
            return {
                ...state,
                userTickets: action.value
            }
        case 'userTickets30days':
            return {
                ...state,
                userTickets30days: action.value
            }
        case 'userDepartment':
            return {
                ...state,
                userDepartment: action.value
            }
        case 'userLastInteractions':
            return {
                ...state,
                userLastInteractions: action.value
            }
        case 'userCompany':
            return {
                ...state,
                userCompany: action.value
            }
        case 'searchTickets':
            return {
                ...state,
                searchTickets: action.value
            }
        case 'kbArticles':
            return {
                ...state,
                kbArticles: action.value
            }
        case 'selectedKB':
                return {
                    ...state,
                    selectedKB : action.value
                }
        case 'assigmentGroups':
            return {
                ...state,
                assigmentGroups: action.value
            }
        case 'assignedTo':
            return {
                ...state,
                assignedTo: action.value
            }
        case 'update_ticket_info':
            return {
                ...state,
                update_ticket_info: action.value
            }
        case 'incident_attachments':
            return {
                ...state,
                incident_attachments: action.value
            }
        case 'isFetchingAssigmentGroups':
            return {
                ...state,
                isFetchingAssigmentGroups: action.value
            }
        case 'ccpAgentProfile':
            return {
                ...state,
                ccpAgentProfile: action.value
            }
        case 'agentProfile':
            return {
                ...state,
                agentProfile: action.value
            }
        case 'metrics':
            return {
                ...state,
                metrics: action.value
            }
        case 'currentAgentState':
            return {
                ...state,
                currentAgentState: action.value
            }
        case 'currentQueue':
            return {
                ...state,
                currentQueue: action.value
            }
        case 'activeToolsBadge':
            return {
                ...state,
                activeToolsBadge: action.value
            }
        case 'phoneNumber':
            return {
                ...state,
                phoneNumber: action.value
            }
        case "daily_data" :
            return {
                ...state,
                daily_data : action.value
            }
        case "weekly_data" :
            return {
                ...state,
                weekly_data : action.value
            }
        case "monthly_data" :
            return {
                ...state,
                monthly_data : action.value
            }
        case "historical_data" :
            return {
                ...state,
                historical_data : action.value
            }
        case "historical_last_year" :
            return {
                ...state,
                historical_last_year : action.value
            }
        case "chart_data" :
            return {
                ...state,
                chart_data : action.value

            }
        case "percents" :
            return {
                ...state,
                percents : action.value

            }   
        case "d_agent_stats" :
            return {
                ...state,
                d_agent_stats : action.value
            }  
        case "w_agent_stats" :
            return {
                ...state,
                w_agent_stats : action.value
            }     
        case "m_agent_stats" :
            return {
                ...state,
                m_agent_stats : action.value
            }  

        case "isAuthenticated" :
            return {
                ...state,
                isAuthenticated : action.value
            } 
        case "all_tickets" : 
            return {
                ...state,
                all_tickets : action.value
            }
        case "agents_status" : 
            return {
                ...state,
                agents_status : action.value
            }
        case "ticket_count" : 
            return {
                ...state,
                ticket_count : action.value
            }
        case "user" : 
            return {
                ...state,
                user : action.value
            }
        case "userdata" : 
            return {
                ...state,
                userdata : action.value
            }
        case "api_token" : 
            return {
                ...state,
                api_token : action.value
            }
        case "calls_in_queue" :
            return {
                ...state,
                calls_in_queue : action.value
            }
        case "manager_access" : 
            return {
                ...state,
                manager_access : action.value
            }
        case "user_has_picture" :
            return {
                ...state,
                user_has_picture : action.value
            }
        case "opening_script" :
            return {
                ...state,
                opening_script : action.value
            }
        case "fetchingLocation" : 
            return {
                ...state,
                fetchingLocation : action.value
            }
        case "fetchingUserinfo" : 
            return {
                ...state,
                fetchingUserinfo : action.value
            }
        case "fetchingAllTickets" : 
            return {
                ...state,
                fetchingAllTickets : action.value
            }
    }

    return state;
}

export default reducer;