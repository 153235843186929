function arrayOfMonthDays(length){
    // Returns an array of numbers with total days of current month. If a integer is passed that will be the number of days.
    let total_days;
    if (!length){
        let date = new Date();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        total_days = new Date(year, month, 0).getDate();
    }
    else{
        total_days = length;
    }
    //console.log("Total days:",length);
    let n_days =  Array.from(Array(total_days+1).keys());
    n_days.shift(); 

    return n_days
}

function getTrueABAN(aban, sla_aban){
    // Returns Average Speed of Answer depending on desired metric: day / week / month //
      if (isNaN(aban) || isNaN(sla_aban)){
        return 0
      }
      return aban - sla_aban;
    }

function reduceByMonth(arrayOfDays){
    //Map reduces and array of daily metrics to return an array of monthly metrics.
    if(arrayOfDays.length === 0){return []}
    let temp = [];
    let output = [];
    arrayOfDays.forEach( (day, index) => {
        let month = day.DATE.MONTH.MONTH_N
        if (temp[month] === undefined){
            temp[month] = [];
            output[month] = {
                DATA:{
                    INBOUND:{
                        TOTAL:{}
                    }
                }
            };
        }
        temp[month].push(day);
    });
    
    temp.forEach((month,index)=>{
        output[index] = month.reduce(function(a,b){
            return {
                DATA : {
                    INBOUND : {
                        TOTAL:{
                            COUNT : a.DATA.INBOUND.TOTAL.COUNT + b.DATA.INBOUND.TOTAL.COUNT,
                            ANS : a.DATA.INBOUND.TOTAL.ANS + b.DATA.INBOUND.TOTAL.ANS,
                            SLA_ANS : a.DATA.INBOUND.TOTAL.SLA_ANS + b.DATA.INBOUND.TOTAL.SLA_ANS,
                            ABAN : a.DATA.INBOUND.TOTAL.ABAN + b.DATA.INBOUND.TOTAL.ABAN,
                            SLA_ABAN : a.DATA.INBOUND.TOTAL.SLA_ABAN + b.DATA.INBOUND.TOTAL.SLA_ABAN,
                            QUEUE : a.DATA.INBOUND.TOTAL.QUEUE + b.DATA.INBOUND.TOTAL.QUEUE,
                            AHT : a.DATA.INBOUND.TOTAL.AHT + b.DATA.INBOUND.TOTAL.AHT,
                            HOLD : a.DATA.INBOUND.TOTAL.HOLD + b.DATA.INBOUND.TOTAL.HOLD,
                        }
                    }
                }
            }
        });
    });
    //console.log("output",output);
    return output;
}

function arrayOfMonthData(arrayOfDays, monthIndex){
    //Returns an array of daily data for the desired month
    if(arrayOfDays.length === 0){return []}
    let output = [];
    arrayOfDays.forEach( (day, index) => {
        let month = day.DATE.MONTH.MONTH_N
        if (month === monthIndex){
            output.push(day);
        }
    });
    return output;
}

export {arrayOfMonthDays, reduceByMonth, getTrueABAN, arrayOfMonthData };