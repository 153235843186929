const months = [
    "Jan", 
    "Feb", 
    "Mar", 
    "Apr", 
    "May", 
    "Jun", 
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

var config = {
  data: {
    labels: [],
    datasets: [
      {
        label: "Total calls",
        borderColor: "#51bcda",
        pointBackgroundColor: "#51bcda",
        pointRadius: 3,
        pointHoverRadius: 5,
        lineTension: 0,
        fill: true,
        backgroundColor: "rgba(81, 188, 218,.15)",
        borderWidth: 2,
        barPercentage: 1.6,
        data: [],
      },
    ],
  },
  options: {
    legend: {
      display: true,
      position: "bottom",
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }  
    },
    hover: {
        onHover: function(e) {
           var point = this.getElementAtEvent(e);
           if (point.length) e.target.style.cursor = 'pointer';
           else e.target.style.cursor = 'default';
        }
    },
    tooltips: {
      enabled: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#626675",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            borderDash: [5, 8],
            lineWidth: 0.5,
            zeroLineColor: "transparent",
            color: "#626675",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: true,
            borderDash: [5, 8],
            lineWidth: 0.5,
            color: "#9f9f9f",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

export { config, months, days };
