import React, { Component } from "react";
import {
    Card,
    Row,
    Col,
    CardHeader,
    CardTitle,
    CardBody,
} from "reactstrap";
// Custom components
import FeedbackForm from "../components/FeedbackForm";


class FeedbackView extends Component {

    resetScroll(){
        let main = document.querySelector(".main-panel");
        main.scrollTo(0, 0);
    }

    componentDidMount(){
        this.resetScroll();
    }

    render(){
        return(
            <div className="content" style={{marginTop: (this.props.getContentTopMargin()) }}>
                <br />
                <Row>
                <Col md="2"/>
                <Col>
                    <Card className="feedback-card">
                        <CardHeader>
                            <CardTitle tag="h5">
                                Your opinion matters! &nbsp;
                                <i className="far fa-thumbs-up text-primary"></i>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <p>
                                <span className="text-info">TCS i2i User360 Dev Team cares for it's users</span>, 
                                so kindly send your feedback in order to let us know what you think and learn 
                                how we can improve our application for you. We will answer as soon as possible!
                            </p>
                            <FeedbackForm 
                                dangerAlert={this.props.dangerAlert}
                                successAlert={this.props.successAlert} 
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md="2"/>

                </Row>
            </div>
        )
    }
}

export default FeedbackView;