import React from "react";
import classnames from "classnames";

import {
  Button,
  Collapse,
  Row,
  Col,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  NavbarToggler,
  Nav,
  Container,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//Amplify 
import {Auth} from "aws-amplify";
import {connect} from "react-redux";
import routes from "routes.js";
import SettingsMenu from "components/Settings/SettingsMenu";
import NotificationDropdown from "components/Navbars/NotificationDropdown/NotificationDropdown";
import BannerNotifications from "components/Navbars/BannerNotifications/BannerNotifications";
import Search from "components/Navbars/Search/Search";
import {host} from "../../variables/apis";

class MainNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      currentStateDuration: "",
      modal_toggle: false,
      notifications : {
        user : [],
        all_users : [],
      },
    };
    this.modal_toggle = this.modal_toggle.bind(this);
  }

  async getCallsInQueue(){
  //Fetches calls in queue as an array.
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    fetch(`${host}/json-stats?report=calls_queue`, Init)
      .then(response => response.json())
      .then(data => {
        //console.log("Calls in queue" ,data);
        this.props.onChangeState("calls_in_queue", data);
      });
  }


  async getNotifications(){
  //Fetches notifications from dynamoDB user360-notifications
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    const url = `${host}notifications?user=${this.props.ccpAgentProfile.username}`;
    fetch(url,Init)
      .then(response => response.json())
      .then(data => {
        //console.log("NOTIFICATIONS" ,data);
        this.setState(
          {
            ...this.state,
            notifications : data
          }
        );
      });
  }
  
  modal_toggle() {
    this.setState((state) => ({
      modal_toggle: !state.modal_toggle,
    }));
  }

  agentStateRenderer() {
    switch (this.props.currentAgentState) {
      case "Break":
        return (
          <Badge color="warning" pill>
            Break
          </Badge>
        );
      case "Lunch":
        return (
          <Badge color="warning" pill>
            Lunch
          </Badge>
        );
      case "Offline":
        return (
          <Badge color="default" pill>
            Offline
          </Badge>
        );
      case "Available":
        return (
          <Badge color="success" pill>
            Available
          </Badge>
        );
      case "On call":
        return (
          <Badge color="success" pill>
            On call
          </Badge>
        );
      case "AfterCallWork":
        return (
          <Badge color="success" pill>
            ACW
          </Badge>
        );
      default:
        return (
          <Badge color="danger" pill>
            N/A
          </Badge>
        );
    }
  }

  contactQueueRenderer() {
    // console.log("contactQueueRenderer")
    // console.log(this.props.currentQueue);
    return (
      <Badge color="info" pill>
        {this.props.currentQueue !== "" ? this.props.currentQueue : "N/A"}
      </Badge>
    );
  }

  agentStateDurationRenderer = () => {
    return (
      <Badge color="info" pill>
        {this.state.currentStateDuration !== ""
          ? this.state.currentStateDuration
          : "N/A"}
      </Badge>
    );
  };

  componentDidMount() {
    this.getNotifications();
    window.addEventListener("resize", this.updateColor);
    setInterval(
      () => {
        this.getCallsInQueue();
        this.setState({
          currentStateDuration: this.props.handleGetStateDuration(),
        });
      },
      2000
    );
    let notif = setInterval(
      ()=>{
        this.getNotifications();
      },
      10000
    )
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  getBrand() {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  }

  getIcon() {
    let iconName = "Default Icon";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        iconName = prop.icon;
      }
      return null;
    });
    return iconName;
  }


  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }

  
  render() {
    //console.log("MainNavbar this.props", this.props);
    //console.log("MainNavbar this", this);
    return (
      <>
        <Modal 
          isOpen={this.state.modal_toggle}
          toggle={this.modal_toggle}
          className={this.state.className}
          size={"md"}
        >
          <ModalHeader>Settings</ModalHeader>
          <ModalBody>
            <SettingsMenu
              bgColor={this.props.backgroundColor}
              darkMode={this.props.darkMode}
              activeColor={this.props.activeColor}
              sidebarMini={this.props.sidebarMini}
              handleActiveClick={this.props.handleActiveClick}
              handleBgClick={this.props.handleBgClick}
              handleMiniClick={this.props.handleMiniClick}
              handleDarkMode={this.props.handleDarkMode}
            />
          </ModalBody>
        </Modal>
        <Navbar
        style={{padding:"auto"}}
          className={classnames("navbar-absolute fixed-top",this.state.color, this.props.sidebarMini ? "" : "cancel-absolute")}
          expand="lg"
        > 
          <div className="w-100 row-fluid"> 
          {
            //changing div container to Row
          }
          <BannerNotifications
            notifications={this.state.notifications} //PROD
            /*//TEST BannerNotif START
            notifications={
                {
                  all_users:[{info:"test01",from:"Tony Diaz", created:"2011-06-11T10:30:00.000Z",message:'NOTIFICATIONS ALL 1'}, {info:"test02",from:"Tony Diaz", created:"2022-09-05T11:12:00.000Z",message:'NOTIFICATION ALL TEST 2'}],
                  user:[{info:"test03",from:"Tony Diaz", created:"2022-08-12T14:53:00.000Z",message:'NOTIFICATIONS USER 1'}, {info:"test04",from:"Tony Diaz", created:"2022-08-11T13:48:00.000Z",message:'NOTIFICATION USER TEST 2'},{info:"test05",from:"Tony Diaz", created:"2011-09-07T11:32:00.000Z",message:'NOTIFICATION USER TEST 3 orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to'}]
                }
            }
            *///TEST BannerNotif END
          />
          <Container fluid style={{paddingRight:"45px"}}>
            <div className="navbar-wrapper" style={{marginBottom: "auto", marginTop: "10px"}}>
              <div className="navbar-minimize ">
                    {
                      //Arrow SideBar Modal
                    }
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {
                /* SEPARATION NAVBAR (1/3)
              }
              <Row className="container">
              <div className="w-80">
              {
                */
              }
              <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
                {this.getBrand()} <i className={this.getIcon()}></i>
              </NavbarBrand>
              </div>
              <div style={{margingLeft:"auto", marginBottom: "auto", marginTop: "10px"}}>
                <button
                  aria-controls="navigation-index"
                  aria-expanded={this.state.collapseOpen}
                  aria-label="Toggle navigation"
                  className="navbar-toggler justify-content-end"
                  style={{marginBottom: "auto", marginTop: "10px"}}
                  // data-target="#navigation"
                  data-toggle="collapse"
                  type="button"
                  onClick={this.toggleCollapse}
                >
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </button>
              </div>
              {
                //Collapse Button
              }
            {
            /*
            </div>
            </Row>
            */
            }

            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="justify-content-center"
            >
              {/*  <h6 className="nav-link">
                Agent current status: {this.agentStateRenderer()}
              </h6>
              <div className="p-3"></div>
              <h6> State Duration: {this.agentStateDurationRenderer()}</h6>
              <div className="p-3"></div>
              <h6> Agent Queue: {this.contactQueueRenderer()}</h6> */}
              <Row style={{minWidth: "fit-content", maxWidth: "fit-content", flex:"initial"}}>
                <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ padding:"0px 5px", minWidth:"75px"}}>
                  <div className="little-box" >
                    <small className="small">
                      <b>
                        <i className="fa fa-user-tie" /> Status:
                       </b>
                    </small>
                    <br />
                    <p className="pill">{this.agentStateRenderer()}</p>
                  </div>
                </Col>
                
                <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ padding:"0px 5px"}}>
                  <div className="little-box">
                    <small className="small">
                      <b>
                        <i className="fa fa-clock-o" /> Duration:
                      </b>
                    </small>
                    <br />
                    <p className="pill">{this.agentStateDurationRenderer()}</p>
                  </div>
                </Col>

                <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ padding:"0px 5px"}}>
                  <div className="little-box">
                    <small className="small">
                      <b>
                        <i className="fas fa-headset" /> My queue:
                      </b>
                    </small>
                    <br />
                    <p className="pill">{this.contactQueueRenderer()}</p>
                  </div>
                </Col>

                <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ padding:"0px 5px"}}>
                  <div className="little-box">
                    <small className="small">
                      <b>
                        <i className="fas fa-phone" /> In queue:
                      </b>
                    </small>
                    <br />
                    <p className="pill">
                      <Badge color="info" pill>
                      {this.props.calls_in_queue.length}
                      </Badge>
                    </p>
                  </div>
                </Col>
              </Row>

            </Collapse>
            <Collapse
              style={{minWidth: "fit-content", maxWidth: "fit-content"}}
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Search
                searchTickets={this.props.searchTickets}
                //handleGetTicketSearch={this.props.handleGetTicketSearch}
                handleUpdateTicket={this.props.handleUpdateTicket}
              />
              <Nav navbar>
                <NotificationDropdown 
                notifications={this.state.notifications}
                />
                <NavItem>
                  <NavLink
                    className="btn-magnify text-info"
                    href="#"
                    onClick={() => {
                      this.modal_toggle();
                    }}
                  >
                    <i className="nc-icon nc-settings-gear-65" />
                    <p>
                      <span className="d-lg-none d-md-block">Settings</span>
                    </p>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
            <div style={{marginLeft:"auto", marginBottom: "auto", marginTop: "10px"}}>
              <button
                aria-controls="navigation-index"
                aria-expanded={this.state.collapseOpen}
                aria-label="Toggle navigation"
                className="navbar-toggler justify-content-end"
                style={{marginBottom: "auto", marginTop: "10px"}}
                // data-target="#navigation"
                data-toggle="collapse"
                type="button"
                onClick={this.toggleCollapse}
              >
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>
            </div>
          </Container>
          </div>
        </Navbar>
      </>
    );
  }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    ccpAgentProfile : state.ccpAgentProfile,
    calls_in_queue : state.calls_in_queue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

export const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);
