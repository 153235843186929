function objectKeysSort(input){
    //Receives an object as parameter and returns the same object ordered by key
    let output = {};
    Object.keys(input).sort().forEach((key,index)=>{
        output[key] = input[key]
    });
    return output;
}

export default function ticketStatsMap(tickets){
    //receives an array of tickets and returns an object with different property counters
    let output = {
        state : {
            new : 0,
            in_progress : 0,
            closed : 0,
            canceled : 0,
        },
        location : {},
        category : {},
        priority : {},
        impact : {},
        urgency : {},
    };
    if (tickets.length===0){
        return output
    }

    tickets.forEach(ticket => {
        switch (ticket.state.value){
            case "1":
                output.state.new ++;
                break
            case "2":
                output.state.in_progress ++;
                break
            case "8":
                output.state.canceled ++;
                break
            case "7":
            default:
                output.state.closed ++;
                break
        }

        let loc = ticket.location.display_value;
        if (!output.location[loc]){
            output.location[loc] = 0;
        }
        output.location[loc] ++;

        let cat = ticket.category.display_value;
        if (!output.category[cat]){
            output.category[cat] = 0;
        }
        output.category[cat] ++;

        let prio = ticket.priority.display_value;
        if (!output.priority[prio]){
            output.priority[prio] = 0;
        }
        output.priority[prio] ++;

        let urg = ticket.urgency.display_value ? 
            `${ticket.urgency.value} - ${ticket.urgency.display_value}`
            : "null";
        if (!output.urgency[urg]){
            output.urgency[urg] = 0;
        }
        output.urgency[urg] ++;
        
        
        let imp = ticket.impact.display_value ? 
        `${ticket.impact.value} - ${ticket.impact.display_value}`
        : "null";
        if (!output.impact[imp]){
            output.impact[imp] = 0;
        }
        output.impact[imp] ++;
    });

    // Removing Null data from fields in order to display only useful information
    delete output.urgency.null;
    delete output.impact.null;

    //Ordering keys before outputing for better info readability.
    Object.keys(output).forEach( key => {
        output[key] = objectKeysSort(output[key])
    });

    //console.log("Ticket Statistics", output);
    return output
}