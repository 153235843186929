import React from "react";
import axios from "axios";
import Select from "react-select";
import qs from "querystring";
import {
  Alert,
  Button,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Label,
  Collapse,
  Spinner,
  Form,
  Input,
} from "reactstrap"; // used for making the prop types of this component

import { API_UpdateTicket } from "variables/apis";
import FileUpload from "components/FileUpload/FileUpload";

// set value for default selection

export default class CreateTicketForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {
      user_info: {},
      ticketBeingCreated: Boolean(false),
      ticketCreatedSuccess: Boolean(false),
      ticketCreatedNumber: String(""),
      assigmentGroupSelected: {},
      assignedToSelected: {},
      collapse_toggle: false,
      // =-=-=-=-=-=-=-=-=-= Form Data -==-=-=-=-==-=-==-=-=-=-==-=
      registerName: "",
      registerNameState: "",

      registerContactType: "",
      registerContactTypeState: "",

      registerTicketState: "",
      registerTicketStateState: "",

      registerImpact: "",
      registerImpactState: "",

      registerUrgency: "",
      registerUrgencyState: "",

      registerAssigmentGroup: {},
      registerAssigmentGroupState: "",

      registerAssignedToSelected: {},
      registerAssignedToSelectedState: "",

      registerShortDescription: "",
      registerShortDescriptionState: "",

      registerDescription: "",
      registerDescriptionState: "",

      registerCloseCode: "",
      registerCloseCodeState: "",

      registerCloseNotes: "",
      registerCloseNotesState: "",

      registerWorkNotes: "",
      registerWorkNotesState: "",
    };
    this.toggle = this.toggle.bind(this);
    this.fileUpload = React.createRef();
  }

  onChangeAssigmentGroup = (event) => {
    this.setState({ assigmentGroupSelected: event, assignedToSelected: null });
  };

  onChangeAssignedTo = (event) => {
    this.setState({ assignedToSelected: event });
  };

  onInputChangeAssignedTo = (event) => {
    if (this.state.assigmentGroupSelected !== null) {
      this.props.handleGetAssignedTo(
        event,
        this.state.assigmentGroupSelected.value
      );
    }
  };

  toggle(value) {
    this.setState(() => ({
      collapse_toggle: value,
    }));
  }

  handleTicketStateCollapse = (event) => {
    switch (event.target.value) {
      case "1":
      case "2":
      case "3":
        this.toggle(false);

        break;

      case "6":
      case "7":
      case "8":
        this.toggle(true);
        break;
    }
  };

  // function that returns true if value is email, false otherwise
  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  verifyUrl = (value) => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "has-success" });
          this.setState({ [stateNameEqualTo + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
          this.setState({ [stateNameEqualTo + "State"]: "has-danger" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "min-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "max-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  handleValidSubmit() {
    var isFormValid = true;
    if (this.props.currentTicketInfo.caller_id.value === "") {
      this.setState({ registerNameState: "has-danger" });
      isFormValid = false;
    }
    if (this.state.registerContactType === "") {
      this.setState({ registerContactTypeState: "has-danger" });
      isFormValid = false;
    }
    if (this.state.registerTicketState === "") {
      this.setState({ registerTicketStateState: "has-danger" });
      isFormValid = false;
    }
    if (this.state.registerImpact === "") {
      this.setState({ registerImpactState: "has-danger" });
      isFormValid = false;
    }
    if (this.state.registerUrgency === "") {
      this.setState({ registerUrgencyState: "has-danger" });
      isFormValid = false;
    }
    if (this.state.registerShortDescription === "") {
      this.setState({ registerShortDescriptionState: "has-danger" });
      isFormValid = false;
    }
    if (this.state.registerDescription === "") {
      this.setState({ registerDescriptionState: "has-danger" });
      isFormValid = false;
    }
    /*if (this.state.registerWorkNotes === "") {
      this.setState({ registerWorkNotesState: "has-danger" });
      isFormValid = false;
    }*/
    if (
      this.state.assigmentGroupSelected &&
      Object.keys(this.state.assigmentGroupSelected).length > 0
    ) {
    }

    if (isFormValid) {
      this.onSubmit();
    }
  }

  onSubmit = () => {
    this.setState({ ticketBeingCreated: true });
    API_UpdateTicket.headers.ticket_number = this.props.currentTicketInfo.sys_id.display_value;

    API_UpdateTicket.data.data = {
      assigned_to: this.state.assignedToSelected.value,
      assignment_group: this.state.assigmentGroupSelected.value,
      short_description: this.state.registerShortDescription,
      description: this.state.registerDescription,
      contact_type: this.state.registerContactType,
      state: this.state.registerTicketState,
      impact: this.state.registerImpact,
      urgency: this.state.registerUrgency,
      caller_id: this.props.currentTicketInfo.caller_id.display_value,
      close_code: this.state.registerCloseCode
        ? this.state.registerCloseCode
        : "",
      close_notes: this.state.registerCloseNotes
        ? this.state.registerCloseNotes
        : "",
      work_notes: this.state.registerWorkNotes,
    };
    axios({
      method: API_UpdateTicket.method,
      url:
        API_UpdateTicket.endpoint +
        "?" +
        qs.stringify(API_UpdateTicket.headers),
      data: API_UpdateTicket.data,
    }).then((response) => {
      this.props.successAlert(
        "Yay!",
        "Ticket #" + response.data.data.result.number + " updated!"
      );
      this.setState({ ticketBeingCreated: false });
      this.setState({ ticketCreatedSuccess: true });
      this.setState({ ticketCreatedNumber: response.data.data.result.number });
      this.props.handleGetUserTickets(
        this.props.currentTicketInfo.caller_id.value
      );
      this.props.handleToggleModal();
      this.fileUpload.current.handleSubmit(); //handleSubmit
    });
  };

  componentDidMount() {
    this.setState({
      registerName:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.caller_id.display_value
          : null,
      registerContactType:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.contact_type.value
          : null,
      registerTicketState:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.state.value
          : null,
      registerImpact:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.impact.value
          : null,
      registerUrgency:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.urgency.value
          : null,
      registerAssigmentGroup: {
        label:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assignment_group.display_value
            : null,
        value:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assignment_group.value
            : null,
      },
      assigmentGroupSelected: {
        label:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assignment_group.display_value
            : null,
        value:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assignment_group.value
            : null,
      },
      assignedToSelected: {
        label:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assigned_to.display_value
            : null,
        value:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assigned_to.value
            : null,
      },
      registerAssignedToSelected: {
        label:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assigned_to.display_value
            : null,
        value:
          this.props.currentTicketInfo &&
          Object.keys(this.props.currentTicketInfo).length > 0
            ? this.props.currentTicketInfo.assigned_to.value
            : null,
      },
      registerShortDescription:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.short_description.value
          : null,
      registerDescription:
        this.props.currentTicketInfo &&
        Object.keys(this.props.currentTicketInfo).length > 0
          ? this.props.currentTicketInfo.description.value
          : null,
      registerCloseCode:
        this.props.currentTicketInfo &&
        this.props.currentTicketInfo.close_code !== undefined
          ? this.props.currentTicketInfo.close_code.value
          : null,
      registerCloseNotes:
        this.props.currentTicketInfo &&
        this.props.currentTicketInfo.close_notes !== undefined
          ? this.props.currentTicketInfo.close_notes.value
          : null,
    });
  }

  render() {
    //console.log("Update ticket props:" , this.props.currentTicketInfo);
    let {
      registerNameState,

      registerContactTypeState,

      registerTicketStateState,

      registerImpactState,

      registerUrgencyState,

      registerAssigmentGroupState,

      registerAssignedToSelectedState,

      registerShortDescriptionState,

      registerDescriptionState,

      registerCloseCodeState,

      registerCloseNotesState,

      registerWorkNotesState,
    } = this.state;
    return (
      <React.Fragment>
        <CardHeader>
          <Alert color="success" hidden={!this.state.ticketCreatedSuccess}>
            Last ticket created: {this.state.ticketCreatedNumber}
          </Alert>
          {/*Header*/}
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <FormGroup className={`col-md-12 has-label ${registerNameState}`}>
                <Label>Name</Label>
                <Input
                  disabled
                  name="name"
                  label="Name"
                  value={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.caller_id.display_value
                      : null
                  }
                  onChange={(e) => this.change(e, "registerName", "length", 1)}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup
                className={`col-md-6 has-label ${registerContactTypeState}`}
              >
                <Label>Contact Type</Label>
                <Input
                  type="select"
                  name="contact_type"
                  label="Contact Type"
                  onChange={(e) =>
                    this.change(e, "registerContactType", "length", 1)
                  }
                  defaultValue={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.contact_type.value
                      : null
                  }
                >
                  <option disabled  value="">
                    Select an option...
                  </option>
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                </Input>
                {this.state.registerContactTypeState === "has-danger" ? (
                  <label className="error">Please select a contact type.</label>
                ) : null}
              </FormGroup>
              <FormGroup
                className={`col-md-6 has-label ${registerTicketStateState}`}
              >
                <Label>State</Label>
                <Input
                  type="select"
                  name="state"
                  label="State"
                  onChange={(e) => {
                    this.change(e, "registerTicketState", "length", 1);
                    this.handleTicketStateCollapse(e);
                  }}
                  defaultValue={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.state.value
                      : null
                  }
                >
                  <option disabled  value="">
                    Select an option...
                  </option>
                  <option value="1">New</option>
                  <option value="2">In Progress</option>
                  <option value="3">On hold</option>
                  <option value="6">Resolved</option>
                  <option value="7">Closed</option>
                  <option value="8">Canceled</option>
                </Input>
                {this.state.registerTicketStateState === "has-danger" ? (
                  <label className="error">Please select a state.</label>
                ) : null}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup
                className={`col-md-6 has-label ${registerCloseCodeState}`}
              >
                <Collapse isOpen={this.state.collapse_toggle}>
                  <Label>Close Code</Label>
                  <Input
                    type="select"
                    name="close_code"
                    label="Close Code"
                    onChange={(e) =>
                      this.change(e, "registerCloseCode", "length", 1)
                    }
                    value={
                      this.props.currentTicketInfo &&
                        this.props.currentTicketInfo.close_code !== undefined
                        ? this.props.currentTicketInfo.close_code.value
                        : null
                    }
                  >
                    <option value="SELECTED" >
                      Select an option...
                    </option>
                    <option value="Solved (Work Around)" >
                      Solved (Work Around)
                    </option>
                    <option value="Solved (Permanently)" >
                      Solved (Permanently)
                    </option>
                    <option value="Solved Remotely (Work Around)" >
                      Solved Remotely (Work Around)
                    </option>
                    <option value="Solved Remotely (Permanently)" >
                      Solved Remotely (Permanently)
                    </option>
                    <option value="Not Solved (Not Reproducible)" >
                      Not Solved (Not Reproducible)
                    </option>
                    <option value="Not Solved (Too Costly)" >
                      Not Solved (Too Costly)
                    </option>
                    <option value="Closed/Resolved by Caller" >
                      Closed/Resolved by Caller
                    </option>
                  </Input>
                  {this.state.registerCloseCodeState === "has-danger" ? (
                    <label className="error">Please select a close code.</label>
                  ) : null}
                </Collapse>
              </FormGroup>
              <FormGroup
                className={`col-md-6 has-label ${registerCloseNotesState}`}
              >
                <Collapse isOpen={this.state.collapse_toggle}>
                  <Label>Close Notes</Label>
                  <Input
                    name="close_notes"
                    placeholder="Example: Ticket resolved successfully"
                    onChange={(e) =>
                      this.change(e, "registerCloseNotes", "length", 1)
                    }
                    defaultValue={
                      this.props.currentTicketInfo &&
                      this.props.currentTicketInfo.close_notes !== undefined
                        ? this.props.currentTicketInfo.close_notes.value
                        : null
                    }
                  />
                  {this.state.registerCloseNotesState === "has-danger" ? (
                    <label className="error">Please write a close note.</label>
                  ) : null}
                </Collapse>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup
                className={`col-md-6 has-label ${registerImpactState}`}
              >
                <Label>Impact</Label>
                <Input
                  type="select"
                  name="impact"
                  label="Impact"
                  onChange={(e) =>
                    this.change(e, "registerImpact", "length", 1)
                  }
                  defaultValue={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.impact.value
                      : null
                  }
                >
                  <option disabled  value="">
                    Select an option...
                  </option>
                  <option value="3">3 - Low</option>
                  <option value="2">2 - Medium</option>
                  <option value="1">1 - High</option>
                </Input>
                {this.state.registerImpactState === "has-danger" ? (
                  <label className="error">Please select an Impact.</label>
                ) : null}
              </FormGroup>
              <FormGroup
                className={`col-md-6 has-label ${registerUrgencyState}`}
              >
                <Label>Urgency</Label>
                <Input
                  type="select"
                  name="urgency"
                  label="Urgency"
                  onChange={(e) =>
                    this.change(e, "registerUrgency", "length", 1)
                  }
                  defaultValue={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.urgency.value
                      : null
                  }
                >
                  <option disabled  value="">
                    Select an option...
                  </option>
                  <option defaultValue value="3">
                    3 - Low
                  </option>
                  <option value="2">2 - Medium</option>
                  <option value="1">1 - High</option>
                </Input>
                {this.state.registerUrgencyState === "has-danger" ? (
                  <label className="error">
                    Please select a Urgency state for the ticket.
                  </label>
                ) : null}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup
                className={`col-md-6 has-label ${registerAssigmentGroupState}`}
              >
                <Label>Assignment Group</Label>
                <Select
                  className="react-select primary "
                  classNamePrefix="react-select"
                  name="singleSelect"
                  options={
                    //Because the Select component NEEDS an array with label and value, we need to copy the state values to a valid one.
                    this.props.assigmentGroups.map((opt) => ({
                      label: opt.name.display_value,
                      value: opt.sys_id.display_value,
                    }))
                  }
                  onInputChange={this.props.handleGetAssigmentGroups}
                  onChange={this.onChangeAssigmentGroup}
                  value={this.state.assigmentGroupSelected}
                  autoFocus={true}
                  isDisabled={false}
                  isClearable={true}
                  defaultValue={{
                    label:
                      this.props.currentTicketInfo &&
                      Object.keys(this.props.currentTicketInfo).length > 0
                        ? this.props.currentTicketInfo.assignment_group.display_value
                        : null,
                    value:
                      this.props.currentTicketInfo &&
                      Object.keys(this.props.currentTicketInfo).length > 0
                        ? this.props.currentTicketInfo.assignment_group.value
                        : null,
                  }}
                />
                {this.state.registerAssigmentGroupState === "has-danger" ? (
                  <label className="error">
                    Please select a valid Assigment Group.
                  </label>
                ) : null}
              </FormGroup>

              <FormGroup
                className={`col-md-6 has-label ${registerAssignedToSelectedState}`}
              >
                <Label>Assigned To</Label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  options={
                    //Because the Select component NEEDS an array with label and value, we need to copy the state values to a valid one.
                    this.props.assignedTo
                      ? this.props.assignedTo.map((opt) => ({
                          label: opt.name.display_value,
                          value: opt.sys_id.value,
                        }))
                      : undefined
                  }
                  onInputChange={this.onInputChangeAssignedTo}
                  onChange={this.onChangeAssignedTo}
                  value={this.state.assignedToSelected}
                  autoFocus={true}
                  defaultValue={{
                    label:
                      this.props.currentTicketInfo &&
                      Object.keys(this.props.currentTicketInfo).length > 0
                        ? this.props.currentTicketInfo.assigned_to.display_value
                        : null,
                    value:
                      this.props.currentTicketInfo &&
                      Object.keys(this.props.currentTicketInfo).length > 0
                        ? this.props.currentTicketInfo.assigned_to.value
                        : null,
                  }}
                  isDisabled={
                    this.props.isFetchingAssigmentGroups ? true : false
                  }
                  isClearable={true}
                />
                {this.state.registerAssignedToSelectedState === "has-danger" ? (
                  <label className="error">
                    Please select a valid Assignee.
                  </label>
                ) : null}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup
                className={`col-md-12 has-label ${registerShortDescriptionState}`}
              >
                <Label>Short Description</Label>
                <Input
                  name="short_description"
                  placeholder="Example: Unable to access computer"
                  onChange={(e) =>
                    this.change(e, "registerShortDescription", "length", 1)
                  }
                  defaultValue={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.short_description.value
                      : null
                  }
                />
                {this.state.registerShortDescriptionState === "has-danger" ? (
                  <label className="error">
                    Please select a valid Short Description.
                  </label>
                ) : null}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup
                className={`col-md-12 has-label ${registerDescriptionState}`}
              >
                <Label>Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  onChange={(e) =>
                    this.change(e, "registerDescription", "length", 1)
                  }
                  defaultValue={
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.description.value
                      : null
                  }
                />
                {this.state.registerDescriptionState === "has-danger" ? (
                  <label className="error">
                    Please select a valid Description.
                  </label>
                ) : null}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup
                className={`col-md-12 has-label ${registerWorkNotesState}`}
              >
                <Label>Worknote</Label>
                <Input
                  name="worknotes"
                  type="textarea"
                  label="Worknotes"
                  onChange={(e) =>
                    this.change(e, "registerWorkNotes", "length", 1)
                  }
                />
                {this.state.registerWorkNotesState === "has-danger" ? (
                  <label className="error">Please add a worknote.</label>
                ) : null}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-12">
                <FileUpload
                  ticket_sysid={
                    this.props.currentTicketInfo.sys_id.display_value
                  }
                  ref={this.fileUpload}
                  ccpAgentProfile={this.props.ccpAgentProfile}
                  successAlert={this.props.successAlert}
                  dangerAlert={this.props.dangerAlert}
                />
              </FormGroup>
            </Row>
            <div className="d-flex flex-row-reverse">
              <Button
                color="primary"
                disabled={Boolean(this.state.ticketBeingCreated)}
                className="btn-round"
                onClick={this.handleValidSubmit}
              >
                Update
                <Spinner
                  className="ml-1"
                  size="sm"
                  hidden={!Boolean(this.state.ticketBeingCreated)}
                ></Spinner>
              </Button>
            </div>
          </Form>
        </CardBody>
      </React.Fragment>
    );
  }
}
