import React, { Component } from "react";
import AgentStatsCard from "./AgentStatsCard";


class AgentCards extends Component {

  render() {
    //console.log("AgentCards props: "+JSON.stringify(this.props));
    return (
      <>
        <AgentStatsCard 
          titleIcon={"fa fa-arrow-circle-down text-success"}
          title={"Inbound Calls"}
          subtitle={this.props.d_agent_stats.COUNT}
        />

        <AgentStatsCard 
          titleIcon={"far fa-clock text-info"}
          title={"Avg. Handle Time"}
          subtitle={this.props.d_agent_stats.AVG_AHT}
        />

        <AgentStatsCard 
          titleIcon={"far fa-clock text-info"}
          title={"After Call Work"}
          subtitle={this.props.d_agent_stats.ACW}
        />

        <AgentStatsCard 
          //titleIcon={"nc-icon nc-time-alarm text-info"}
          titleIcon={"far fa-clock text-info"}
          title={"Time on Hold"}
          subtitle={this.props.d_agent_stats.HOLD}
        />
      </>
    );
  }
}

export default AgentCards;
