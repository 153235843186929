import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import LayoutVariables from "../../variables/layout";
import LiveImage from "components/LiveImage/LiveImage";

import SignOutLink from "./SignoutLink";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /main/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.isHidden) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#p"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      if (!prop.manager_only || this.props.manager_access){
        return (
          <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink to={prop.layout + prop.path} activeClassName="">
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
            </NavLink>
          </li>
        );
      }
    });
  };


  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };


  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: true,
      });
    }
  }

  render() {
    //console.log("Sidebar Props", this.props);
    return (
      <div
        className="sidebar off-canvas-sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
      <div className="logo">
        <a className="simple-text logo-mini">
          <div className="logo-img">
            <img src={LayoutVariables.brand_logo_URL} alt="react-logo" />
          </div>
        </a>
        <a className="simple-text logo-normal">{LayoutVariables.brand}</a>
      </div>
      <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="photo">
              <LiveImage
                image={
                  this.props.user_has_picture === true
                    ? LayoutVariables.profile_images_bucket + this.props.user + "_profile"
                    : LayoutVariables.default_user_profile
                }
                alt="Avatar"
                interval={5000}
              ></LiveImage>
            </div>
            <div className="info">
              <a
                href="#"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {
                    this.props.agentProfile.item && !(this.props.userdata.name === "" || this.props.userdata.given_name === "")
                    ? this.props.userdata.name + " " + this.props.userdata.given_name
                    : this.props.username
                  }
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  <li className={this.activeRoute("/main/profile")}>
                    <NavLink to="/main/profile" activeClassName="">
                      <span className="sidebar-mini-icon">
                        <i className="fas fa-id-card" />
                      </span>
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                  </li>
                  <li className={this.activeRoute("/main/edit-profile")}>
                    <NavLink to="/main/edit-profile" activeClassName="">
                      <span className="sidebar-mini-icon">
                        <i className="fas fa-user-cog" />
                      </span>
                      <span className="sidebar-normal">Edit Profile</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          
          <Nav>
            {this.createLinks(this.props.routes)}
            <SignOutLink/>
          </Nav>
        </div>
      </div>
    );
  }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    user : state.user,
    userdata : state.userdata,
    user_has_picture : state.user_has_picture,
    manager_access : state.manager_access,
  };
}


const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
