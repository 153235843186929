import React, { Component } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
} from "reactstrap";


class MiniCard extends Component {
  render() {
    return (
      <Card className="card-stats card-chungus">
        <CardBody>
          <Row>
            <Col md="3" xs="4">
              <div className="icon-big text-center icon-warning">
                <i className={this.props.titleIcon} />
              </div>
            </Col>
            <Col md="9" xs="8">
              <div className="numbers">
                <p className="card-category">{this.props.title}</p>
                <CardTitle tag="p" style={{fontSize:"1.3rem"}}>
                  {this.props.subtitle}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default MiniCard;
