import React, { Component } from "react";
import {
  Table
} from "reactstrap";
// Redux 
import { connect } from "react-redux";


class UsersInStoreTable extends Component {

    render(){
        return(
            <Table className="table-hover table-responsive table-sm"
                data-color={this.props.backgroundColor}
                >
                <thead className="text-info">
                    <tr>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Title</th>
                        <th>Division</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                {
                    this.props.users.map( (user,index) => 
                        <tr key={index}>
                            <td>{user.name.display_value}</td>
                            <td>{user.location.display_value}</td>
                            <td>{user.title.display_value}</td>
                            <td>{user.u_job_family.display_value}</td>
                            <td>{user.email.display_value}</td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
        )
    }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
    return {
      backgroundColor : state.backgroundColor,
    };
  }

// ENDS REDUX STUFF //
  
export default connect(mapStateToProps, null)(UsersInStoreTable);
