import React from "react";
import { Card, CardImgOverlay, CardTitle, CardImg } from "reactstrap";
import facts from "variables/facts";
import { connect } from "react-redux";
import LayoutVariables from "../../variables/layout";


class NothingHere extends React.Component {
  getRandomFact() {
    var randomIndex = Math.floor(Math.random() * facts.length);
    return facts[randomIndex].fact;
  }
  render() {
    return (
      <Card>
        <CardImg
          className="nothing"
          src={ this.props.darkMode === true 
            ? LayoutVariables.nothing_background_dark 
            : LayoutVariables.nothing_background
          }
          alt="..."
        />

        <CardImgOverlay>
          <p className="">
            <h1>
              {/* <div className="icon-big text-center icon-warning">
                <i className="far fa-grin-tongue-squint"></i>
              </div> */}
              <CardTitle>Oops!</CardTitle>
            </h1>
            {this.props.message ? (
              <h6>{this.props.message}</h6>
            ) : (
              <>
                <h6>
                  There's nothing to show so here's a fact you probably didn't
                  know:
                </h6>
                <h4> {this.getRandomFact()}</h4>
                <br />
              </>
            )}
          </p>
        </CardImgOverlay>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    darkMode: state.darkMode,
  };
};


export default connect(mapStateToProps, null)(NothingHere);