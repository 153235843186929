import React, { Component } from "react";
// react plugin used to create switch buttons
import CCP from "components/CCP/CCP";

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneClass: "fa fa-phone fa-2x",

      classes: "dropdown",
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    if (this.state.classes === "dropdown") {
      this.setState({ classes: "dropdown show" });
    } else {
      this.setState({ classes: "dropdown" });
    }
  }
  handlerOpen = () => {
    this.setState({ classes: "dropdown show" });
  };

  handlerClose = () => {
    this.setState({ classes: "dropdown" });
  };

  handleShake = (shake) => {
    if (shake === true) {
      this.setState({ phoneClass: "fa fa-phone fa-2x shake" });
    } else {
      this.setState({ phoneClass: "fa fa-phone fa-2x" });
    }
  };

  render() {
    return (
      <div className="fixed-plugin">
        <div className={this.state.classes}>
          <div onClick={this.handleClick} className="btn-magnify">
            <i className={this.state.phoneClass} />
          </div>
          <ul className="dropdown-menu show">
            <CCP
              notify={this.props.notify}
              openHandler={this.handlerOpen}
              shakeHandler={this.handleShake}
              handleNewAgentState={this.props.handleNewAgentState}
              handleFetchCallData={this.props.handleFetchCallData}
              handleResetInfo={this.props.handleResetInfo}
              handleQueue={this.props.handleQueue}
              handleStateDuration={this.props.handleStateDuration}
              handleMetric={this.props.handleMetric}
              handleGetAgentProfile={this.props.handleGetAgentProfile}
            ></CCP>
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPlugin;
