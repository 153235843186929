import React, { Component } from "react";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
// Bootstrap
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Table,
} from "reactstrap";


var mapData = {
    "US-LA": 2920,
    "US-NC": 1300,
    "US-TN": 760,
    "US-OH" : 690,
    "US-SC" : 550,
};


class DemoMap extends Component {
    render(){
        return(
            <Card>
                <CardHeader>
                  <CardTitle tag="h4">Incidents by Location</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Table data-color={this.props.backgroundColor} hover>
                        <thead className="text-primary">
                          <tr>
                              <th></th>
                              <th>Store</th>
                              <th className="text-right">Volume</th>
                              <th className="text-right">% out of Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/flags/US.png")}
                                />
                              </div>
                            </td>
                            <td>Store 9787 - Marrero</td>
                            <td className="text-right">2.920</td>
                            <td className="text-right">53.23%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/flags/US.png")}
                                />
                              </div>
                            </td>
                            <td>Store 4850 - Mooresville</td>
                            <td className="text-right">1.300</td>
                            <td className="text-right">20.43%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/flags/US.png")}
                                />
                              </div>
                            </td>
                            <td>Store 3990 - Crossville</td>
                            <td className="text-right">760</td>
                            <td className="text-right">10.35%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/flags/US.png")}
                                />
                              </div>
                            </td>
                            <td>Store 8708 - Pearl Rd</td>
                            <td className="text-right">690</td>
                            <td className="text-right">7.87%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/flags/US.png")}
                                />
                              </div>
                            </td>
                            <td>Store 5780 - Laurens</td>
                            <td className="text-right">550</td>
                            <td className="text-right">4.34%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col className="ml-auto mr-auto" md="6">
                      <VectorMap
                        map={"us_aea"}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                          height: "300px",
                        }}
                        containerClassName="map"
                        regionStyle={{
                          initial: {
                            fill: "#e4e4e4",
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0,
                          },
                        }}
                        series={{
                          regions: [
                            {
                              values: mapData,
                              scale: ["#AAAAAA", "#444444"],
                              normalizeFunction: "polynomial",
                            },
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
        );
    }
}

export default DemoMap;