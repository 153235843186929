import React, { Component } from "react";
import Select, { components } from "react-select";
import qs from "querystring";
import KnowledgeDisplay from "./KnowledgeDisplay/KnowledgeDisplay";
import {API_GetKnowledgeArticle} from "variables/apis";
//Redux
import { connect } from "react-redux";
// Bootstrap
import { 
  Spinner,
  Row, 
  Col, 
  CardBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
//Amplify
import { Auth } from "aws-amplify";


class KbSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input : "",
      is_fetching : false,
    };
    this.onChangeKB = this.onChangeKB.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  init;

  fetch_KBs(keys){
    // Fetchs Knowledge Base articles
    this.setState({is_fetching:true})
    API_GetKnowledgeArticle.data.keys = keys;
    fetch(API_GetKnowledgeArticle.endpoint+"?"+qs.stringify(API_GetKnowledgeArticle.data),this.init)
      .then((response) => response.json())
      .then(data => {
        this.props.onChangeState("kbArticles", data.data.result);
        this.setState({is_fetching:false})
      }
    );
  }

  handleSubmit(e){
    e.preventDefault();
    //console.log("event",e);
    this.fetch_KBs(this.state.input);
  }

  handleChange(e){
    this.setState({input : e});
  }


  onChangeKB = (event) => {
    //console.log("event: ", event);
    if (event === null) {
      this.props.onChangeState("selectedKB", {});
    } else {
      this.props.onChangeState("selectedKB", event.value );
    }
  };

  async componentDidMount(){
    this.init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
  }

  render() {
    //console.log("KB props", this.props);
    return (
        <CardBody>
          <Row>
            <Col>
            <Form onSubmit={this.handleSubmit}>
                <p className="text-info">
                  Type your keywords and press enter perform a knowledge base search
                </p>
                <Select
                components={{
                  DropdownIndicator,
                  Option,
                  LoadingIndicator,
                  LoadingMessage,
                  NoOptionsMessage,
                }}
                  id="kb-search"
                  name="kb-search"
                  isLoading={this.state.is_fetching}
                  placeholder="Enter keywords..."
                  className="react-select primary "
                  classNamePrefix="react-select"
                  options={
                    //Because the Select component NEEDS an array with label and value, we need to copy the state values to a valid one.
                    this.props.kbArticles.map((opt) => ({
                      label: opt.short_description.display_value,
                      value: opt,
                    }))
                  }
                  onInputChange={this.handleChange}
                  /* onInputChange={this.fetch_KBs} */
                  onChange={this.onChangeKB}
                  value={this.state.input}
                  //value={this.props.selectedKB}
                  autoFocus={true}
                  isDisabled={false}
                  isClearable={true}
                />
            </Form>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col>
              <KnowledgeDisplay
                title={
                    this.props.selectedKB.short_description
                      ? this.props.selectedKB.short_description.display_value
                      : ""
                  }
                  body={
                    this.props.selectedKB.text
                      ? this.props.selectedKB.text.value
                      : ""
                  }
                  // Updated does not exist in AAP's Knowledgebase articles...
                  updated={
                    this.props.selectedKB.sys_updated_on
                      ? "Updated On:" + this.props.selectedKB.sys_updated_on.display_value
                      : ""
                  }
                  number={
                    this.props.selectedKB.number
                    ? this.props.selectedKB.number.value
                    : ""
                  }
              ></KnowledgeDisplay>
            </Col>
          </Row>
        </CardBody>
    );
  }
}


// REACT SELECT COMPONENTS //
const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className="nc-icon nc-zoom-split" />
      </components.DropdownIndicator>
    )
  );
};

const Option = (props) => {
  return <components.Option {...props}>{props.label}</components.Option>;
};

const LoadingIndicator = (props) => {
  return (
    <components.LoadingIndicator {...props}>
      <Spinner color="info" />
    </components.LoadingIndicator>
  );
};

const LoadingMessage = (props) => {
  return (
    <components.LoadingMessage {...props}>
      Loading... <Spinner size="sm" color="info" />
    </components.LoadingMessage>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Please perform a new search... <i className="fas fa-search text-info" /> 
    </components.NoOptionsMessage>
  );
};



// ---- Redux functions ---- //
const mapStateToProps = (state) => {
  return {
    kbArticles : state.kbArticles,
    selectedKB : state.selectedKB,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //

export default connect(mapStateToProps,mapDispatchToProps)(KbSearch);