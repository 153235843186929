import Dashboard from "views/DashboardView.jsx";
import Login from "views/Login";

import ServicePanelView from "views/ServicePanelView.jsx";
import AgentView from "views/AgentView";
import EditProfile from "views/EditProfile";
import ManagerView from "views/ManagerView";
import TicketsView from "views/TicketsView";
import FeedbackView from "views/FeedbackView";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-36",//"nc-icon nc-layout-11",
    component: Dashboard,
    isOnFooter: false,
    isHidden: false,
    layout: "/main",
  },
  {
    path: "/service",
    name: "Service Panel",
    icon: "fas fa-headset",
    component: ServicePanelView,
    isOnFooter: false,
    isHidden: false,
    layout: "/main",
  },
  {
    path: "/profile",
    name: "Agent Profile",
    icon: "fas fa-user",
    component: AgentView,
    isOnFooter: false,
    isHidden: true,
    layout: "/main",
  },
  {
    path: "/edit-profile",
    name: "Agent Profile",
    icon: "fas fa-user-cog",
    component: EditProfile,
    isOnFooter: false,
    isHidden: true,
    layout: "/main",
  },
  {
    path: "/incidents",
    name: "Incidents",
    icon: "fas fa-ticket-alt",
    component: TicketsView,
    isOnFooter: false,
    isHidden: false,
    layout: "/main",
  },
  {
    path: "/manager",
    name: "Manager",
    icon: "fas fa-clipboard-list",
    component: ManagerView,
    isOnFooter: false,
    isHidden: false,
    layout: "/main",
    manager_only : true,
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: "fa fa-comments-o",
    component: FeedbackView,
    isOnFooter: false,
    isHidden: false,
    layout: "/main",
  },
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    isOnFooter: false,
    isHidden: true,
    layout: "/auth",
  },  
];

export default routes;
