import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, CardBody, Card, CardFooter } from "reactstrap";
import { NavLink } from "react-router-dom";
import LayoutVariables from "../../../variables/layout";
import LiveImage from "components/LiveImage/LiveImage";
// Aux functions
import capitalize from "AuxFunctions/capitalize";


class AgentProfile extends Component {

  renderAgentStatus(status){
    let color;
    switch (status.toLowerCase()) {
      case "offline":
        color = "text-danger"
        break;
      case "break":
      case "lunch":
        color = "text-warning"
        break;  
      case "available":
        color = "text-success"
        break
      default:
        color = "text-info"
        break;
    }

    return(
      <h5>
        <i className={"fa fa-circle " + color} />
        <br />
        Status
        <br />
        <small>{capitalize(status)}</small>
      </h5>
    );
  }

  render() {
    //console.log("AgentProfile props: ", this.props);
    return (
      <Card className="card-user">
        <div className="image">
          <img alt="..." src={require("assets/img/aap-red.png")} />
        </div>
        <CardBody>
          <div className="author">
            <NavLink to="/main/edit-profile" activeClassName="">
              <LiveImage
                image={
                  this.props.user_has_picture === true
                    ? LayoutVariables.profile_images_bucket + this.props.user + "_profile"
                    : LayoutVariables.default_user_profile
                }
                className="avatar border-gray"
                interval = {5000}
              ></LiveImage>
             
              <h5 className="title text-info">
                {this.props.userdata
                  ? this.props.userdata.name +
                    " " +
                    this.props.userdata.given_name
                  : "No name"}
              </h5>
            </NavLink>
            <p className="text-primary">Employee ID: {this.props.userdata.nickname}</p>
            <p>
              <i className="fa fa-envelope-o text-info" />{" "}
              {this.props.userdata.email
                ? this.props.userdata.email
                : "Undefined"}
            </p>
          </div>

          <p style={{padding:"10px"}} className="text-justify"><i>
            "{this.props.agentProfile.item.About !== "" ? this.props.agentProfile.item.About : "No description"}"
          </i></p>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="button-container">
            <Row>
              <Col className="ml-auto" lg="3" md="6" xs="6">
                <h5>
                  <i className="fa fa-map-marker text-warning" />
                  <br />
                  Location
                  <br />
                  <small>
                    {this.props.agentProfile.item
                      ? this.props.agentProfile.item.City +
                        ", " +
                        this.props.agentProfile.item.Country
                      : "Undefined"}
                  </small>
                </h5>
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="6" xs="6">
                <h5>
                  <i className="fa fa-lock text-primary" />
                  <br />
                  Access Level
                  <br />
                  <small>
                    { this.props.manager_access
                      ? "Manager"
                      : "Agent"
                    }
                  </small>
                </h5>
              </Col>
              <Col className="mr-auto" lg="3">
                {this.renderAgentStatus(this.props.currentAgentState)}
              </Col>
            </Row>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    currentAgentState : state.currentAgentState,
    user : state.user,
    userdata : state.userdata,
    user_has_picture : state.user_has_picture,
    manager_access : state.manager_access,
  };
}


const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile);
