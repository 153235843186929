import React from "react";
//Redux 
import { connect } from "react-redux"
// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";
//Amplify
import { Auth } from "aws-amplify";
//Custom components
import AgentProfile from "components/AgentData/AgentProfile/AgentProfile";
//import AgentTeamMembers from "components/AgentData/AgentTeamMembers/AgentTeamMembers";
import AgentCards from "components/AgentData/AgentCards/AgentCards";
import OverallMetrics from "components/AgentData/OverallMetrics/OverallMetrics";
import convertTime from "../AuxFunctions/convertTime";

import {host} from "../variables/apis";
import TicketsChartTabs from "components/TicketsChartTabs";

function calcAvrgAHT(aht, count){
  //Calculates average AHT for user stats
  if (aht === null || count === null){
    return "0";
  }
  return aht / count;
}


class AgentView extends React.Component {
  agent = this.props.ccpAgentProfile.username;
  //agent = "Ahernandez"; // HARDCODED AGENT VALUE FOR TESTING

  async getUserMetrics(){
    //Fetchs data from API for daily and monthly stats and places current agent metrics to state.
    //--------------DAILY STATS FETCH------------//
    const init = {
      headers : { 
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    let d_raw_data;
    let d_agent_stats;
    fetch(`${host}json-stats?report=daily`, init)
    .then(response => response.json())
    .then(data => {
      d_raw_data = data;
      d_raw_data.INBOUND.AGENTS.forEach(element => {
        if (element.AGENT === this.agent){
          d_agent_stats = element;
        }
      });
      if (d_agent_stats != null){
        /* This step intends to verify the agent has data before trying to set the state, as agnets with no activity
        will not appear on the data */
        this.props.onChangeState(
          "d_agent_stats",
          {
            AGENT: d_agent_stats.AGENT,
            COUNT : d_agent_stats.COUNT,
            AHT : convertTime(d_agent_stats.AHT),
            AVG_AHT : convertTime(calcAvrgAHT(d_agent_stats.AHT, d_agent_stats.COUNT)),
            ACW : convertTime(d_agent_stats.ACW),
            HOLD : convertTime(d_agent_stats.HOLD),
            LHOLD : convertTime(d_agent_stats.LHOLD),
            OUTBOUND : 0
          }
        );
      }
      //console.log("d_agent_stats:",this.props.d_agent_stats);
    });  

    //--------------WEEKLY STATS SET ------------//
    let w_raw_data = this.props.weekly_data.INBOUND.WEEK.AGENTS;
    let w_agent_stats;
    w_raw_data.forEach(element => {
        if (element.AGENT === this.agent){
          w_agent_stats = element;
        }
      });
    if (w_agent_stats != null){
      /* This step intends to verify the agent has data before trying to set the state, as agnets with no activity
      will not appear on the data */
      this.props.onChangeState(
        "w_agent_stats",
        {
          AGENT: w_agent_stats.AGENT,
          COUNT : w_agent_stats.COUNT,
          AHT : convertTime(w_agent_stats.AHT),
          AVG_AHT : convertTime(calcAvrgAHT(w_agent_stats.AHT, w_agent_stats.COUNT)),
          ACW : convertTime(w_agent_stats.ACW),
          HOLD : convertTime(w_agent_stats.HOLD),
          LHOLD : convertTime(w_agent_stats.LHOLD),
          OUTBOUND : 0
        }
      );
    }

    // ---------MONTHLY STATS SET ---------//
    let m_raw_data = this.props.monthly_data.INBOUND.MONTH.AGENTS;
    let m_agent_stats;
    m_raw_data.forEach(element => {
        if (element.AGENT === this.agent){
          m_agent_stats = element;
        }
    });
    if (m_agent_stats != null){
      /* This step intends to verify the agent has data before trying to set the state, as agnets with no activity
      will not appear on the data */
      this.props.onChangeState(
        "m_agent_stats",
        {
          AGENT: m_agent_stats.AGENT,
          COUNT : m_agent_stats.COUNT,
          AHT : convertTime(m_agent_stats.AHT),
          AVG_AHT : convertTime(calcAvrgAHT(m_agent_stats.AHT, m_agent_stats.COUNT)),
          ACW : convertTime(m_agent_stats.ACW),
          HOLD : convertTime(m_agent_stats.HOLD),
          LHOLD : convertTime(m_agent_stats.LHOLD),
          OUTBOUND : 0
        }
      );
    }
  }


  
  componentDidMount(){
    this.getUserMetrics();
    this.interval = setInterval(() => this.getUserMetrics(), 5000);
    // Set scroll to top
    let main = document.querySelector(".main-panel");
    main.scrollTo(0, 0);
  }

  componentWillUnmount(){
    try{
      clearInterval(this.interval);
    }
    catch{
      console.log("Cleared");
    }
  }

  render() {
    //console.log("AgentView props",this.props);
    return (
        <div className="content" style={{marginTop: (this.props.getContentTopMargin()) }}>
          <h3>Today stats &nbsp;<i className="fas fa-calendar-check text-primary"></i></h3>
          <Row>
            <AgentCards d_agent_stats={this.props.d_agent_stats} />
          </Row>

          <Row>
            <Col md="6" sm="12">
              <AgentProfile agentProfile={this.props.callInfo.agentProfile} />
            </Col>

            <Col md="6" sm="12">
              <Row>
                <Col sm="12">
                  <OverallMetrics
                      bgColor={this.props.backgroundColor}
                      metrics="Overall"
                      month={this.props.month}
                      dailyMetrics={this.props.d_agent_stats}
                      weeklyMetrics={this.props.w_agent_stats}
                      monthlyMetrics={this.props.m_agent_stats}
                    />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <TicketsChartTabs />
                </Col>
              </Row>
            </Col>
          </Row>

          {/*  <Col md="6" sm="12" className="d-flex align-items-stretch">
              <AgentTeamMembers />
          </Col> */}
          
        </div>
    );
  }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    backgroundColor : state.backgroundColor,
    month: state.month,
    monthly_data : state.monthly_data,
    weekly_data : state.weekly_data,
    daily_data : state.daily_data,
    d_agent_stats : state.d_agent_stats,
    w_agent_stats : state.w_agent_stats,
    m_agent_stats : state.m_agent_stats,
    ticket_count : state.ticket_count,
    ccpAgentProfile : state.ccpAgentProfile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

export const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(AgentView);
