import React, { Component } from 'react';
//Redux 
import { connect } from "react-redux";
// Bootstrap
import {
    Card,
    CardBody,
    CardTitle,
    Nav,
    NavLink,
    NavItem,
    TabPane,
    TabContent,
    CardHeader,
} from "reactstrap";
// Amplify 
import {Auth} from "aws-amplify";
import {host} from "../variables/apis";
// Custom Components
import AgentStatusTable from 'components/AgentStatusTable';
import AgentsTable from './AgentsTable';

class AgentsTabsMenu extends Component {
    // A view component to display all agents stats and post notifications
    constructor(props) {
        super(props);
        this.state = { activeTab: "live" };
    }
    timer;
    
    async fetchAgentsStatus(){
        //Fetchs data from API for agents statuss and stores it on component state.
        const init = {
            headers : { 
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
          }
        fetch(`${host}json-stats?report=agents_status`, init)
        .then(response => response.json())
        .then(data => {
            //console.log("all_tickets: ", data);
            this.props.onChangeState("agents_status", data);
        });
    }

    componentDidMount(){
        this.timer = setInterval(()=>{this.fetchAgentsStatus()},1000);
        // Set scroll to top
        let main = document.querySelector(".main-panel");
        main.scrollTo(0, 0);
    }

    componentWillUnmount(){
        window.clearInterval(this.timer);
    }


    render(){
        return(
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">
                        All agents stats &nbsp;
                        <i className="fas fa-users text-primary"></i>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper" style={{marginLeft:'20px'}}>
                    <Nav id="tabs" role="tablist" tabs>
                        
                        <NavItem>
                            <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "live" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "live" })}
                            >Live Status
                            </NavLink>
                        </NavItem>
                        
                        <NavItem>
                            <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "1" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "1" })}
                            >Today
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "2" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "2" })}
                            >This week
                            </NavLink>

                        </NavItem>

                        <NavItem>
                            <NavLink
                                data-toggle="tab"
                                role="tab"
                                href="#"
                                className={this.state.activeTab === "3" ? "active" : ""}
                                onClick={() => this.setState({ activeTab: "3" })}
                            >{this.props.month}
                            </NavLink> 
                        </NavItem>
                    </Nav>
                    </div>
                    </div>

                    {/* -------- Tab contents begin here -------- */}
                    <TabContent id="my-tab-content" activeTab={this.state.activeTab}>
                        <TabPane tabId="live" role="tabpanel">
                            <AgentStatusTable 
                                bgColor={this.props.backgroundColor}
                                agents_status={this.props.agents_status}
                                />    
                        </TabPane>
                        
                        <TabPane tabId="1" role="tabpanel">
                            <AgentsTable
                                bgColor={this.props.backgroundColor}
                                data={this.props.daily_data.INBOUND.AGENTS}
                                />
                        </TabPane>

                        <TabPane tabId="2" role="tabpanel">
                            <AgentsTable
                                bgColor={this.props.backgroundColor}
                                data={this.props.weekly_data.INBOUND.WEEK.AGENTS}
                                />
                        </TabPane>

                        <TabPane tabId="3" role="tabpanel">
                            <AgentsTable
                                bgColor={this.props.backgroundColor}
                                data={this.props.monthly_data.INBOUND.MONTH.AGENTS}
                                />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>

        );
    }
}

// ---- REDUX STUFF ---- //
const mapStateToProps = state => {
    return {
        backgroundColor : state.backgroundColor,
        month : state.month,
        monthly_data : state.monthly_data,
        weekly_data : state.weekly_data,
        daily_data : state.daily_data,
        agents_status : state.agents_status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeState: (type, value) => dispatch(updateState(type, value)),
    }
}

const updateState = (type, value) => dispatch => {
    dispatch({
        type: type,
        value,
    });
    return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(AgentsTabsMenu);
