import React, { Component } from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap";
// Redux
import { connect } from "react-redux";
// Amplify
import { Auth } from "aws-amplify";
//API
import { host } from "../variables/apis";


class FeedbackForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            message : "",
            remitent : this.props.userdata.email,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    resetForm(){
        this.setState({message:""})
    }

    async handleSubmit(event){
        event.preventDefault();
        let data = {
            remitent:this.state.remitent, 
            message:this.state.message
        }
        const init = {
            method : "POST",
            headers : { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body : JSON.stringify(data),
        }

        fetch(`${host}feedback`, init)
        .then(response => {
            //console.log(response);
            if (response.ok){
                this.props.successAlert("Success","Your feedback message has been sent.");
                this.resetForm();
            }
            else {
                this.props.dangerAlert("Error","Something happened. Please try again later.");
            }
        });
    }

    render(){
        //console.log("FeedbackForm props",this.props);
        return(
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label for="remitent">Your email:</Label>
                    <Input 
                        disabled
                        name="remitent"
                        id="remitent" 
                        value={this.state.remitent} 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for ="message">Your message:</Label>
                    <Input 
                        required
                        placeholder="Type your message here..."
                        type="textarea"
                        id="message"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <div className="text-center">
                    <Button className="btn-info">Send message</Button> 
                </div>
            </Form>
        )
    }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
    return {
      userdata : state.userdata,
    };
}; 
// ENDS REDUX STUFF //
  
  
export default connect(mapStateToProps, null)(FeedbackForm);
  