import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody,
} from "reactstrap";
import UserSearchTable from "./UserSearchTable";
import FetchingData from "./FetchingData";
import { Auth } from "aws-amplify";
import { host } from "../variables/apis"
const query_path = "snow-query?query=search_user&keys=";


class SearchForStore extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_fetching : false,
            query : "",
            results : [],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e){
        e.preventDefault();
        this.handleFectch();
    }

    handleChange(e){
        let target = e.target
        let name = target.name;
        let value = target.value;
        this.setState({[name]:value});
    }

    async handleFectch(){
        this.setState({is_fetching:true});
        const init = {
            headers : { 
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
        }
        fetch(`${host}${query_path}${this.state.query}`, init)
        .then(response => response.json())
        .then(res => {
            //console.log("Store Search Response",res);
            this.setState({results : res.data.result});
            this.setState({is_fetching:false});
        });
    }

    render(){
        return(
            <CardBody>
                <Row>
                    <Col md="10">
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="query">User name or emp id:</Label>
                                <Input 
                                    value={this.state.query} 
                                    placeholder="Search for..."
                                    type="text" 
                                    id="query" 
                                    name="query" 
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col md="2">
                        <Button 
                            className="search-button"
                            onClick={this.handleSubmit} 
                            color="info">Search</Button>
                    </Col>
                </Row>
                <Row>
                {   
                    this.state.is_fetching 
                    ? <FetchingData />
                    : this.state.results.length > 0
                        ? <UserSearchTable users={this.state.results}/>
                        :  null
                }   
                </Row>
            </CardBody>
        )
    }
}

export default SearchForStore;