import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import randomChoice from "AuxFunctions/randomChoice";
import colors from "variables/colors";


class TicketsChartGeneric extends Component {
  render() {
    const data = {
      labels: Object.keys(this.props.data).map(key=>{if(key!==""){return key}else{return "Undefined"}}),
      datasets: [
        {
          label: "Incidents",
          backgroundColor: randomChoice(colors),
          borderWidth: 0,
          data: Object.values(this.props.data),
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        display: false,
        position: "top",
        align: "center",
        maintainAspectRatio: true,
        onHover: function (e) {
          e.target.style.cursor = "pointer";
        },
      },
      hover: {
        onHover: function (e) {
          let point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor = "default";
        },
      },
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              display: true,
            },
            barPercentage: 0.9,
            gridLines: {
              drawBorder: true,
              zeroLineColor: "transparent",
              color: "transparent",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: true,
            },
            barPercentage: 1,
            gridLines: {
              borderDash: [5, 8],
              lineWidth: 0.5,
              drawBorder: true,
              zeroLineColor: "#626675",
              color: "#626675",
            },
          },
        ],
      },
    };

    return <Bar data={data} options={options} />;
  }
}

export default TicketsChartGeneric;
