import React, {Component} from "react";
//Redux
import { connect } from "react-redux";
import {
  Button,
} from "reactstrap";
//import ReactTable from "react-table";
import ReactTable from "components/ReactTable/ReactTable";
import formatDateTime from "AuxFunctions/formatDateTime";

class UserTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data.map((prop, key) => {
        return {
          id: key,
          number: this.handlerRenderTicketButton(prop),
          updated: formatDateTime(prop.sys_updated_on.display_value),
          category: prop.category.display_value,
          subcategory: prop.subcategory.display_value,
          impact: prop.impact.display_value,
          urgency: prop.urgency.display_value,
          priority: this.handlerRenderPriority(prop.priority.display_value),
          state: prop.state.display_value,
          caller_id : prop.caller_id.display_value,
          short_description: prop.short_description.display_value,
          assignment_group: prop.assignment_group.display_value,
          assigned_to: prop.assigned_to.display_value,
          search: prop.number.display_value + ", " + prop.priority.display_value
        };
      }),
    };
    this.state.data.bgColor = this.props.backgroundColor;
  }

  componentDidUpdate() {
    if (this.state.data && this.state.data.lenght > 0 && this.state.data[0].number.props.children !== this.props.data.number.display_value) {
      this.setState({
        data: this.props.data.map((prop, key) => {
          return {
            id: key,
            number: this.handlerRenderTicketButton(prop),
            updated: prop.sys_updated_on.display_value,
            category: prop.category.display_value,
            subcategory: prop.subcategory.display_value,
            impact: prop.impact.display_value,
            urgency: prop.urgency.display_value,
            priority: this.handlerRenderPriority(prop.priority.display_value),
            state: prop.state.display_value,
            caller_id : prop.caller_id.display_value,
            short_description: prop.short_description.display_value,
            assignment_group: prop.assignment_group.display_value,
            assigned_to: prop.assigned_to.display_value,
            search: prop.number.display_value + ", " + prop.priority.display_value
          };
        })
      })
    }
    this.state.data.bgColor = this.props.backgroundColor;
  }


  handlerRenderTicketButton(prop) {
    return (
      <Button
        className="btn-purple"
        onClick={() => this.props.handleUpdateTicket(prop)}
        size="sm"
      >
        {prop.number.display_value}
      </Button>
    );
  }

  handlerRenderPriority(value) {
    let color;
    switch (value) {
      case "1 - Critical":
        color = "danger"
        break;
      case "2 - High":
        color = "warning"
        break;
      case "3 - Moderate":
        color = "success"
        break;
      case "4 - Low":
        color = "info"
        break;
      case "5 - Planning":
        color = "default"
        break;
      default:
        color = "secondary"
        break;
    }
    return(
      <span className={"text-"+color+" bolder"}>
        {value}
      </span>
    );
  }

  render() {
    //console.log("UserTickets props:",this.props);
    return (
      <div  className="content table-responsive responsive-scroll">
        <ReactTable
          data={this.state.data}
          columns={[
            {
              Header: "Number",
              accessor: "number",
              disableFilters: true,
            },
            {
              Header: "User",
              accessor: "caller_id",
              disableFilters: true,
            },
            {
              Header: "Description",
              accessor: "short_description",
              disableFilters: true,
            },
            {
              Header: "Priority",
              accessor: "priority",
              disableFilters: true,
            },
            {
              Header: "State",
              accessor: "state",
              disableFilters: true,
            },
            {
              Header: "Assign group",
              accessor: "assignment_group",
              disableFilters: true,
            },
            {
              Header: "Assigned to",
              accessor: "assigned_to",
              disableFilters: true,
            },
            {
              Header: "Category",
              accessor: "category",
              disableFilters: true,
            },
            {
              Header: "Subcategory",
              accessor: "subcategory",
              disableFilters: true,
              
            },
            {
              Header: "Updated",
              accessor: "updated",
              disableFilters: true,
              
            },
          ]
        }
        />
      </div>
    );
  }
}

// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    backgroundColor : state.backgroundColor
  };
}


const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(UserTickets);
