import React, {Component} from "react";
import {Table} from "reactstrap";
import convertTime from "../AuxFunctions/convertTime";

class AgentsTable extends Component{
    render(){
        //console.log("Table Props", this.props);
        return(
            <Table data-color={this.props.bgColor} striped responsive hover>
                <thead className="text-primary">
                    <tr>
                        <th>Agent</th>
                        <th>Answered</th>
                        <th>AHT</th>
                        <th>ACW</th>
                        <th>HOLD</th>
                        {/* <th>IDLE</th> */}
                    </tr>
                </thead>
                <tbody>
                    {   
                        this.props.data.map( (agent, id) =>
                            <tr key={id}>
                                <th>{agent.AGENT}</th>
                                <td>{agent.COUNT}</td>
                                <td>{convertTime(agent.AHT/agent.COUNT)}</td>
                                <td>{convertTime(agent.ACW)}</td>
                                <td>{convertTime(agent.HOLD)}</td>
                                {/* <td>{convertTime(1223)}</td> */}
                            </tr>
                        )  
                    }
                </tbody>
            </Table>
        );
    }
}

export default AgentsTable;