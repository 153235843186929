import React, { Component } from "react";
// Redux 
import { connect } from "react-redux";
// Bootstrap
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody,
    Row,
    Col,
} from "reactstrap";
// Amplify
import { Auth } from "aws-amplify";
import formatDateTime from "AuxFunctions/formatDateTime";
import { host } from "../variables/apis";

class OpeningScriptPost extends Component {
    constructor(props){
        super(props);
        this.state = {
            opening_script : this.props.opening_script,
            script : this.props.opening_script.value,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    handleChange(event){
        const id = event.target.id;
        this.setState({[id]:event.target.value});
    }

    resetForm(event){
        event.preventDefault();
        this.setState({opening_script:this.props.opening_script});
    }

    async handleSubmit(event){
        event.preventDefault();
        const Init = {
            headers : { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
        }
        let today = new Date();
        let data = {
            value : this.state.script, 
            last_update : {
                user : this.props.ccpAgentProfile.username,
                date : today,
            }
        }
        fetch(`${host}config?config_name=opening_script`,
            {
                method:"PUT",
                headers : Init.headers,
                body : JSON.stringify(data),
            }
        ).then(response => response.json())
        .then(response => {
            console.log("Response", response);
            if (response.message === "Values Updated"){
                this.props.onChangeState("opening_script",response.item.Attributes);
                //alert("Updated");
                this.props.successAlert("Success","Opening script has been saved.");
            } 
            else {
                //alert("An error ocurred");
                this.props.dangerAlert("Error","Something happened. Please try again later.");
            }
        });
    }

    render(){
        //console.log("OpeningScriptPost props",this.props);
        return(
            <CardBody>
                <Form onSubmit={this.handleSubmit}>
                    <p className="text-info">
                        Here you can modify the agents default opening lines.
                        Type <i>{"<agent>"}</i> as a placeholder for agent's name;
                        e.g. "Hi, my name is <i>{"<agent>"}</i>".
                    </p>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label for="script">Opening script text:</Label>
                                <Input type="textarea"
                                    onChange={this.handleChange} 
                                    value={this.state.script} 
                                    id="script" 
                                    required
                                    placeholder="Type the default opening script..."></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p> 
                                Last updated on &nbsp;
                                {
                                    this.state.opening_script.last_update.date !== undefined 
                                        ? formatDateTime(this.state.opening_script.last_update.date)
                                        : ""
                                }
                                &nbsp; by &nbsp;
                                {
                                    " " + 
                                    this.state.opening_script.last_update.user !== undefined 
                                        ? this.state.opening_script.last_update.user
                                        : ""
                                }
                                .
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button className="btn-info">Save script</Button>
                            <Button onClick={this.resetForm} 
                                className="btn-warning">Undo changes</Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        );
    }
}

// ---- REDUX STUFF ---- //
const mapStateToProps = state => {
    return {
        opening_script : state.opening_script,
        ccpAgentProfile : state.ccpAgentProfile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeState: (type, value) => dispatch(updateState(type, value)),
    }
}

const updateState = (type, value) => dispatch => {
    dispatch({
        type: type,
        value,
    });
    return Promise.resolve();
}
// ENDS REDUX STUFF //
export default connect(mapStateToProps,mapDispatchToProps)(OpeningScriptPost);