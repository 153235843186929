import React, {
    useContext,
    createContext,
    useState,
    useEffect,
    useRef,
  } from "react";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import MainLayout from "layouts/Main.js";
import "amazon-connect-streams";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import LayoutVariables from "variables/layout";
//My custom styles:
import "custom_styles.css";
// Amplify
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { AmplifyConfig } from "AmplifyConfig";
import CustomAuthenticator from "components/Authenticator/CustomAuthenticator";


Amplify.configure(AmplifyConfig);

const hist = createBrowserHistory();
//const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
//const store = createStore(reducer, composedEnhancer);

const authContext = createContext();


function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}


function useAuth() {
  return useContext(authContext);
}


function useProvideAuth() {
  const [user, setUser] = useState();

  const signin = (cb) => {
    return fakeAuth.signin(() => {
      setUser("user");
      cb();
    });
  };

  const signout = (cb) => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 10); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 10);
  },
};


function AuthCheck() {
  const containerDiv = useRef(null);
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/main/dashboard" } };


  let login = () => {
    auth.signin(() => {
      history.replace(from);
    });
  };

  
  useEffect(() => {
    /* global connect */
    connect.core.initCCP(containerDiv.current, {
      ccpUrl: LayoutVariables.CCP_URL,
      loginPopup: false, // <== default false
      loginPopupAutoClose: true,
      softphone: {
        allowFramedSoftphone: true,
      },
    });
  
    connect.agent((agent) => {
      //let config = agent.getConfiguration();
      //console.log("CCP Agent", config);
      agent.onStateChange(() => {
        // some time later...
        connect.core.terminate();
        login();
      });
    });
  }, []);
  
  
  const styles = {
    visibility: "hidden",
    display: "none",
  };
  return <div className="containerDiv" style={styles} ref={containerDiv} />;
}

function App(){
  return (
    <ProvideAuth>
      <Router history={hist}>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props } />} />
          
          <PrivateRoute>
            <Route
                path="/"
                
              />
            <Redirect to="/main/dashboard" />

            <Route
              path="/main"
              render={(props) => <MainLayout {...props } />}
              />
            <Redirect to="/main/dashboard" />

          </PrivateRoute>
        </Switch>
        <AuthCheck />
      </Router>
    </ProvideAuth>
  );
}


export default withAuthenticator(App, false, [
  <CustomAuthenticator/>
]);
