import React, { Component } from "react";
import { 
  Card, 
  CardBody, 
  CardHeader,
} from "reactstrap";
import { connect } from "react-redux";
const reactStringReplace = require("react-string-replace");


function colorAgentName(string, agent){
  //Receives the opening script and replaces <agent> with agent name with span tags giving color
    return reactStringReplace(string, "<agent>", (match,i)=>(
      <b key="agent" className="text-purple">{agent}</b>
    ));
}

class OpeningScript extends Component {
  render() {
    //console.log("Opening Script props:",this.props);
    const agent = `${this.props.userdata.name} ${this.props.userdata.given_name}`
    return (
      <Card>
        <CardHeader tag="h5">
          Opening script &nbsp;
          <i class="far fa-comment-dots text-primary"></i>
        </CardHeader>
        <CardBody>
            <p className="opening-script">
              {
                this.props.opening_script !== "" 
                ? colorAgentName(this.props.opening_script.value, agent)
                : 
                "Hi, my name is " + agent +
                 " from Tata Consultancy Services Service Desk. How can I help you today?"  
              }
            </p>
        </CardBody>
      </Card>
    );
  }
}
// Specifies the default values for props:
OpeningScript.defaultProps = {
  operator: "X",
  caller: "X",
  company: "IT",
};

// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    userdata : state.userdata,
    opening_script : state.opening_script,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

export const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(OpeningScript);
