import React, { Component } from "react";
// react plugin used to create charts
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";

var darkChBgColor = "#474c53";
var ChBgColor = "#f4f3ef";

class ChartCard extends Component {
  setChartColor() {
    /* Returns a color for the chart based on the percentage it presents. */
    let percent = this.props.data[0];
    if (this.props.ans) {
      if (percent >= 90) {
        return "#6bd098";
      } 
      else if (percent >= 85) {
        return "#fbc658";
      } 
      else if (percent >= 80) {
        return "#f38434";
      } 
      else {
        return "#FB404B";
      }
    } 
    else {
      if (percent <= 2) {
        return "#6bd098";
      } 
      else if (percent <= 4) {
        return "#fbc658";
      } 
      else if (percent <= 6) {
        return "#f38434";
      } 
      else {
        return "#FB404B";
      }
    }
  }

  render() {
    //console.log("props",this.props);
    return (
      <Card className="chart-card">
        <CardHeader>
          <CardTitle style={{ fontSize: "1.2rem" }}>
            {this.props.title}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Pie
            data={{
              labels:
                this.props.labels !== undefined ? this.props.labels : [1, 2],
              datasets: [
                {
                  label: "Nothing",
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  backgroundColor: [
                    this.setChartColor(),
                    this.props.darkMode ? darkChBgColor : ChBgColor,
                  ],
                  borderWidth: 0,
                  barPercentage: 1.6,
                  data: this.props.data,
                },
              ],
            }}
            options={{
              elements: {
                center: {
                  text: isNaN(this.props.data[0]) ? "Loading..." : `${this.props.data[0]}%`,
                  color: this.props.darkMode ? "white" : "black", // Default is #000000 
                  fontStyle: "Arial", // Default is Arial
                  sidePadding: 20, // Defualt is 20 (as a percentage)
                },
              },
              cutoutPercentage: 80,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      display: false,
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: "transparent",
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      color: "transparent",
                      zeroLineColor: "transparent",
                    },
                    ticks: {
                      display: false,
                    }
                  }
                ]
              }
            }}
            className="ct-chart ct-perfect-fourth"
            height={300}
            width={456}
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    darkMode: state.darkMode,
  };
};

export default connect(mapStateToProps, null)(ChartCard);
