/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import qs from "querystring";
import axios from "axios";
import { API_SnowAttachment } from "variables/apis";
import { Button } from "reactstrap";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileUpload = React.createRef();
  }

  ///For some reason, ms office files are not working properly and have no content type by default
  assigntContentType(extension) {
    switch (extension) {
      case "doc":
        return "application/msword";
        
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

      case "xls":
        return "application/vnd.ms-excel";

      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      case "ppt":
        return "application/vnd.ms-powerpoint";

      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      
      default:
        return "unknown format";
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
      });
    };
    reader.readAsDataURL(file);
  }

  handleSubmit() {
    if (this.state.file === null) return;
    let format = this.state.file.type.split("/")[1];
    let extension = this.state.file.name.split(".")[1];
    let date = new Date();
    date =
      "_" + date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear();

    API_SnowAttachment.headers.table_sys_id = this.props.ticket_sysid;

    API_SnowAttachment.headers.file_type = this.state.file.type;
    API_SnowAttachment.headers.connectUser = this.props.ccpAgentProfile.username;
    API_SnowAttachment.headers.file_name =
      this.state.file.name.split(".")[0] + date + "." + format;

    //Could contain JPG, PNG, JPEG etc...
    API_SnowAttachment.data = new Blob([this.state.file]);

    //API_SnowAttachment.data = this.state.file;
    //const fd = new FormData();
    //fd.append('image', this.state.file, this.state.file.name);
    //API_SnowAttachment.data = fd;

    //console.log(API_SnowAttachment.data);
    axios({
      method: API_SnowAttachment.method,
      url:
        API_SnowAttachment.endpoint +
        "?" +
        qs.stringify(API_SnowAttachment.headers),
      headers: {
        "Content-Type": this.state.file.type
          ? this.state.file.type
          : this.assigntContentType(extension),
      },
      data: API_SnowAttachment.data,
    })
      .then((response) => {
        const nameCapitalized =
          response.data.status.charAt(0).toUpperCase() +
          response.data.status.slice(1);
        if (response.data.status === "success") {
          this.props.successAlert(
            nameCapitalized + "!!!",
            "File " +
              response.data.data.result.file_name +
              " was attached successfully 👍"
          );
        } else {
          this.props.dangerAlert(
            nameCapitalized + "!!!",
            "File " +
              response.data.data.result.file_name +
              " was not attached 🤔"
          );
        }
      })
      .catch((error) => {
        this.props.dangerAlert("Oh no!", JSON.stringify(error));
      });
  }

  render() {
    return (
      <div>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => this.handleImageChange(e)}
          ref={this.fileUpload}
        />
        <Button color="primary" onClick={() => this.fileUpload.current.click()}>
          {this.state.file ? this.state.file.name : "Attach File"}
        </Button>
        {this.state.file ? (
          <Button
            color="danger"
            onClick={() => {
              this.setState({
                file: null,
              });
            }}
          >
            Remove Attachment
          </Button>
        ) : null}
      </div>
    );
  }
}

export default FileUpload;
