import React, { Component } from 'react';
import { Authenticator, SignIn } from 'aws-amplify-react';
import "../../custom_styles.css";
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    Container,
    Col,
    Row,
} from "reactstrap";


class CustomAuthenticator extends Component {
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    Window;
    btn;
    
    callLogin(){      
        this.popup = setTimeout(()=>{
            try{
                this.btn = document.querySelector("#Button__oAuthSignInButton___3UGOl").click();
            }
            catch(e){
                //console.log("User already authenticated");
            }
        }, 500);
    }

    handleClick(e){
        e.preventDefault();
        try{
            this.btn = document.querySelector("#Button__oAuthSignInButton___3UGOl").click();
        }
        catch(e){
            //console.log("Button not exists");
        }
      }

    componentDidMount(){
        this.callLogin();
    }

    render() {
        return (
            <React.Fragment>
                <Authenticator hideDefault={true} onStateChange={this.handleAuthStateChange}>
                    <SignIn />
                </Authenticator>

                <div
                style={{
                    "background" : "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/aap.png)",
                    backgroundSize : "auto !important",
                    backgroundRepeat : "no-repeat",
                    backgroundPosition : "center",
                    "height" : "100%",
                    "position" : "absolute",
                    "top" : "0px",
                    "width" : "100%",
                }}>


                <Container style ={{margin : "100px auto"}}>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="5" md="6">
                            <Card className="card-login">
                            <CardHeader>
                                <CardHeader>
                                <h3 className="header text-center">
                                    Advance Autoparts User360
                                </h3>
                                </CardHeader>
                                <h6 className="text-center">
                                    Redirecting to single sign on...
                                </h6>
                                <div className="text-center loading">
                                <i className="fas fa-spinner fa-pulse text-info"></i>
                                </div>
                            </CardHeader>
                    
                            <CardFooter>
                                <Button
                                block
                                className="btn-round mb-3"
                                color="info"
                                href="#"
                                onClick={this.handleClick}
                                id="login-btn"
                                >
                                Login now
                                </Button>
                                <p className="text-center">
                                    Please allow popups from this site 
                                    in order to improve your authentication experience.
                                </p>
                            </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </div>
            </React.Fragment>
        );
    }
}

  
export default CustomAuthenticator;