import React, {Component} from 'react';
import { Col } from "reactstrap";
import MiniCard from "components/MiniCard/MiniCard";

class AgentStatsCard extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return(
            <Col lg="3" md="6" sm="6" className="d-flex align-items-stretch">
                <MiniCard
                    titleIcon={this.props.titleIcon}
                    title={this.props.title}
                    subtitle={this.props.subtitle}
                    updateIcon={this.props.updateIcon}
                    updateTime={this.props.updateTime} />
            </Col>
        )
    }
}

export default AgentStatsCard;