/* A lot of request are happening in this component in order to use the load time that 
is taken for Amazon Connect to detect a change in session and allow the user to access
the application */
import React, {Component} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
//Amplify
import { Auth , API} from 'aws-amplify';
// Connect 
import "amazon-connect-streams";
//Redux
import { connect as redux_connect } from 'react-redux';
import LayoutVariables from "variables/layout";
import { host } from "../variables/apis";


class Login extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  timer;
  popup;
  Window;
  
  callPopUp(){
    try{
      this.Window.close();
    } catch(e){}
    this.Window = window.open(LayoutVariables.CCP_URL,"_blank", "width=400, height=650");
  }
  

  handleClick(e){  
    e.preventDefault();
    this.Window = window.open(LayoutVariables.CCP_URL,"_blank", "width=400, height=650");
  }

  handleBgClick = (color) => {
    this.props.onChangeState("backgroundColor", color);
    setTimeout(this.handleUpdateSettings, 500);
  };

  setMiniClick = (isMini) => {
    if (isMini) {
      this.props.onChangeState("sidebarMini", true);
      document.body.classList.add("sidebar-mini");
    } else {
      this.props.onChangeState("sidebarMini", false);
      document.body.classList.remove("sidebar-mini");
    }
  };
  
  setDarkMode = (isActive) => {
    if (isActive) {
      this.props.onChangeState("darkMode", true);
      document.body.classList.add("dark-mode");
    } else {
      this.props.onChangeState("darkMode", false);
      document.body.classList.remove("dark-mode");
    }
  };
  
  async getAgentProfile(profile){
    // Fetches Agent Profile and Configurations
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }

    API.get("user360","agent-profile?username="+profile, Init)
    .then(response => {
      this.props.onChangeState("agentProfile", response).then(() => {
        //console.log("agentProfile: "+JSON.stringify(response.data))
        if (response.item.settings && Object.keys(response.item.settings).length > 0 ){
          if (response.item.settings.BgSB !== undefined){
            this.handleBgClick(response.item.settings.BgSB);
          }
          if (response.item.settings.MiniSB !== undefined){
            this.setMiniClick(response.item.settings.MiniSB);
          }
          this.setDarkMode(response.item.settings.DarkMode);
          //this.handleActiveClick(response.data.item.settings.FontSB);
        }
      });
    });
  };

  checkUserPicture(username){
    // Checks if user has a profile picture uploaded on bucket.
    let url = LayoutVariables.profile_images_bucket + username + "_profile"; 
    const img = new Image();
    img.src = url;
  
    if (img.complete) {
      //console.log("User picture found");
      this.props.onChangeState("user_has_picture", true);
    } 
    else {
      img.onload = () => {
        //console.log("User picture found");
        this.props.onChangeState("user_has_picture", true);   
      } 
      img.onerror = () => {
        //console.log("User picture not found. Using default picture");
      };
    }
  }

  async handleGetOpeningScript(){
  // This function fetchs the opening script from DynamoDB and sets it
    let Init = {
      headers: { 
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    
    API.get("user360","config?config_name=opening_script", Init)
    .then(response => {
      //console.log("Opening script", response);
      this.props.onChangeState("opening_script", response)
    });
  }

  async fetchAllTickets(){
    this.setState({is_fetching: true})
    const init = {
        headers : { 
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }
      }
    //Fetchs data from API for monthly stats and stores it on component state.
    this.props.onChangeState("fetchingAllTickets", true);
    fetch(`${host}snow-query?query=all_tickets`, init)
    .then(response => response.json())
    .then(data => {
        //console.log("all_tickets: ", data);
        if (data.result){
            this.props.onChangeState("all_tickets", data.result.data);
        }
        this.props.onChangeState("fetchingAllTickets", false);
    });
  }

  componentDidMount() {
    this.callPopUp();
    document.body.classList.toggle("login-page");

    // Getting Amplify Username and calling get user profile function.
    Auth.currentAuthenticatedUser()
      .then(user => {
        //console.log("User:", user);
        this.props.onChangeState("user", user.username);
        this.props.onChangeState("userdata", user.attributes);
        this.props.onChangeState("api_token", user.signInUserSession.accessToken.jwtToken);
        // Get Agent profile
        this.getAgentProfile(user.username);
        //Check for user picture
        this.checkUserPicture(user.username);
      })
      .catch(err => console.log(err));
    
    //Get opening script
    this.handleGetOpeningScript();
    this.fetchAllTickets();
    this.timer = window.setInterval(()=>{this.handleGetOpeningScript()}, 30000)
  }

  componentWillUnmount() {
    try{
      this.Window.close();
    }
    catch(e){
      // Do something ???
    };
    document.body.classList.toggle("login-page");
  }

  
  render() {
    //console.log("Login props",this.props);
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login" style={{boxShadow: "none"}}>
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">
                        Login into Amazon Connect
                      </h3>
                    </CardHeader>
                    <h6 className="text-center">
                      Waiting for Amazon connect session...
                    </h6>
                    <div className="text-center loading">
                      <i className="fas fa-spinner fa-pulse text-info"></i>
                    </div>
                  </CardHeader>
          
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="info"
                      href="#"
                      onClick={this.handleClick}
                      id="login-btn"
                    >
                      Login now
                    </Button>
                    <p className="text-center">
                      Please allow popups from this site 
                      in order to improve your authentication experience.
                    </p>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>

        <div
          className="full-page-background"
          style={{backgroundImage: "url(/aap.png)"}}
        />
      </div>
    );
  }
}


// ---- Redux functions ---- //

const mapStateToProps = (state) => {
  return {
    // Cognito User
    user : state.user,
    userdata : state.userdata,
    api_token : state.api_token,
    // Settings
    backgroundColor : state.backgroundColor,
    darkMode : state.darkMode,
    sidebarMini : state.sidebarMini,
    opening_script : state.opening_script,

    //AWS Data
    ccpAgentProfile: state.ccpAgentProfile,
    agentProfile: state.agentProfile,
    metrics: state.metrics,
    currentAgentState: state.currentAgentState,
    currentQueue: state.currentQueue,
    activeToolsBadge: state.activeToolsBadge,
    phoneNumber: state.phoneNumber,
    all_tickets : state.all_tickets,
    fetchingAllTickets : state.fetchingAllTickets,

    // Uer profiling
    user_has_picture : state.user_has_picture,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  };
};

export const updateState = (type, value) => (dispatch) => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
};

// ---- End Redux functions ---- //

export default redux_connect(mapStateToProps, mapDispatchToProps)(Login);
