import React, { Component } from "react";
import {
  Table,
  CardBody,
} from "reactstrap"; // used for making the prop types of this component


// set value for default selection

export default class TicketDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: {},
      ticketBeingCreated: Boolean(false),
      ticketCreatedSuccess: Boolean(false),
      ticketCreatedNumber: String(""),
      assigmentGroupSelected: {},
      assignedToSelected: {},
      collapse_toggle: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(value) {
    this.setState(() => ({
      collapse_toggle: value,
    }));
  }

  handleTicketStateCollapse = (event) => {
    switch (event.target.value) {
      case "1":
      case "2":
      case "3":
        this.toggle(false);
        break;

      case "6":
      case "7":
      case "8":
      default:
        this.toggle(true);
        break;
    }
  };

  render() {
    //console.log("TicketDetail props:" , this.props.currentTicketInfo);
    return (
      <React.Fragment>
        <CardBody>
          <Table >
            <thead>
              <th colspan="4">
                <h5>
                  {
                    this.props.currentTicketInfo &&
                    this.props.currentTicketInfo.short_description.display_value !== ""
                      ? this.props.currentTicketInfo.short_description.display_value
                      : "Untitled"
                  }
                </h5>
              </th>
            </thead>
            <tr>  
            <th>Created by</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.opened_by.display_value
                    : "-"
                }
              </td>
              <th>Updated on</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.sys_updated_on.display_value
                    : "-"
                }
              </td>
            </tr>

            <tr>  
              <th>Ticket ID</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.number.display_value
                    : "-"
                }
              </td>  
              <th>Department</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.location.display_value
                    : "-"
                }
              </td>
            </tr>
            <tr>
              <th>Contact Name</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.caller_id.display_value
                    : "-"
                }
              </td>
              <th>Contact Type</th>
              <td>
                {
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.contact_type.display_value
                      : "-"
                  }
              </td>
            </tr>

            <tr>
              <th>Category</th>
              <td>
                {
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.category.display_value
                      : "-"
                  }
              </td>

              <th>Subcategory</th>
              <td>
                {
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.subcategory.display_value
                      : "-"
                  }
              </td> 
            </tr>

            <tr>
                <th>Impact</th>
                <td>
                  {
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.impact.display_value
                      : "-"
                  }
                </td>

                <th>Urgency</th>
                <td>
                  {
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.urgency.display_value
                      : "-"
                  }
                </td>
            </tr>

            <tr>
              <th>Priority</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.priority.display_value
                    : "-"
                }
              </td>
              <th>State</th>
              <td>
                {
                  this.props.currentTicketInfo &&
                  Object.keys(this.props.currentTicketInfo).length > 0
                    ? this.props.currentTicketInfo.state.display_value
                    : "-"
                }
              </td>
            </tr>

            <tr>
                <th>Assignment Group</th>
                <td>
                  {
                    this.props.currentTicketInfo &&
                    Object.keys(this.props.currentTicketInfo).length > 0
                      ? this.props.currentTicketInfo.assignment_group.display_value
                      : "-"
                  }
                </td>
       
                <th>Assigned To</th>
                <td>
                    {
                      this.props.currentTicketInfo && 
                      this.props.currentTicketInfo.assigned_to.display_value !== ""
                        ? this.props.currentTicketInfo.assigned_to.display_value
                        : "Pending"
                      }
                </td>
            </tr>
            <tr>
              <th>Close Code</th>
              <td>
                {
                    this.props.currentTicketInfo &&
                      this.props.currentTicketInfo.close_code !== undefined
                      ? this.props.currentTicketInfo.close_code.display_value
                      : "-"
                  }
              </td>

              <th>Close Notes</th>
              <td>
                {
                    this.props.currentTicketInfo &&
                    this.props.currentTicketInfo.close_notes !== undefined
                      ? this.props.currentTicketInfo.close_notes.display_value
                      : "-"
                  }
              </td> 
            </tr>
            <tr>
              <th>Description</th>
              <td colspan="3">
                {
                  this.props.currentTicketInfo &&
                  this.props.currentTicketInfo.description.display_value !== ""
                    ? this.props.currentTicketInfo.description.display_value
                    : "No description provided"
                }
              </td>
            </tr>
          </Table>
        </CardBody>
      </React.Fragment>
    );
  }
}
