import React, { Component } from 'react';
// Bootstrap
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Nav,
    NavLink,
    NavItem,
    TabPane,
    TabContent,
    CardHeader,
} from "reactstrap";
//Redux 
import { connect } from "react-redux";
//Amplify
import { Auth } from "aws-amplify";
// Custom components
import TicketsTable from 'components/TicketsTable';
import FetchingData from '../components/FetchingData';
import TicketSearch from '../components/TicketSearch';
// Router
import { withRouter } from "react-router";

import {host} from "../variables/apis";


// -------- MAIN COMPONENT -------- //
class TicketsView extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab: this.props.location.state ? this.props.location.state.activeTab : "1",
            is_fetching : false,
        }
        this.fetchAllTickets = this.fetchAllTickets.bind(this);
    }

    async fetchAllTickets(){
        this.setState({is_fetching: true})
        const init = {
            headers : { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
          }
        //Fetchs data from API for monthly stats and stores it on component state.
        fetch(`${host}snow-query?query=all_tickets`, init)
        .then(response => response.json())
        .then(data => {
            //console.log("all_tickets: ", data);
            if (data.result){
                this.props.onChangeState("all_tickets", data.result.data);
            }
            this.setState(
                {
                    ...this.state,
                    is_fetching : false,
                }
            )
        });
    }

    componentDidMount(){
        if (this.props.all_tickets.solved.length === 0){
            this.fetchAllTickets();
        }
        let main = document.querySelector(".main-panel");
        main.scrollTo(0, 0);
    }

    render(){
        //console.log("TicketsView props", this.props);
        //console.log("TicketsView state",this.state);
        return(
            <div className="content" style={{marginTop: (this.props.getContentTopMargin()) }}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5">
                            Incidents in last 30 days &nbsp;
                            <i className="fas fa-history text-primary"></i>    
                        </CardTitle>
                        <CardSubtitle>
                            (Limited to 600)
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper" style={{marginLeft:'20px'}}>

                            {/* ------- Tab Navigation ------- */}
                            <Nav id="tabs" role="tablist" tabs>
                                <NavItem>
                                    <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "1" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "1" })}
                                    >New
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "2" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "2" })}
                                    >In progress
                                    </NavLink>

                                </NavItem>

                                <NavItem>
                                    <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "3" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "3" })}
                                    >Closed
                                    </NavLink> 
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "4" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "4" })}
                                    >Canceled
                                    </NavLink> 
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                    data-toggle="tab"
                                    role="tab"
                                    href="#"
                                    className={this.state.activeTab === "5" ? "active" : ""}
                                    onClick={() => this.setState({ activeTab: "5" })}
                                    >Search
                                    </NavLink> 
                                </NavItem>

                                <NavItem>
                                    <NavLink onClick={this.fetchAllTickets} className="fake-nav-link">
                                        Refresh data&nbsp;
                                        <i className="fas fa-sync text-info icon-spin"
                                            style={{fontSize:"1rem"}}></i>
                                    </NavLink>   
                                </NavItem>
                            </Nav>
                        </div>
                    </div>

                    {/* -------- Tab contents begin here -------- */}
                    <TabContent id="my-tab-content" activeTab={this.state.activeTab}>
                        <TabPane tabId="1" role="tabpanel">
                            {
                                this.state.is_fetching
                                ? <FetchingData/>
                                : <TicketsTable 
                                        bgColor={this.props.backgroundColor}
                                        handleUpdateTicket={this.props.handleUpdateTicket} 
                                        data={this.props.all_tickets.open}
                                    />   
                            }
                        </TabPane>

                        <TabPane tabId="2" role="tabpanel">
                        {
                                this.state.is_fetching
                                ? <FetchingData/>
                                :
                                <TicketsTable 
                                    bgColor={this.props.backgroundColor}
                                    handleUpdateTicket={this.props.handleUpdateTicket} 
                                    data={this.props.all_tickets.in_progress}/>     
                        }              
                        </TabPane>

                        <TabPane tabId="3" role="tabpanel">
                        {
                                this.state.is_fetching
                                ? <FetchingData/>
                                :
                                <TicketsTable 
                                    bgColor={this.props.backgroundColor}
                                    handleUpdateTicket={this.props.handleUpdateTicket}  
                                    data={this.props.all_tickets.solved}/>   
                        } 
                        </TabPane>

                        <TabPane tabId="4" role="tabpanel">
                        {
                                this.state.is_fetching
                                ? <FetchingData/>
                                :
                                <TicketsTable 
                                    bgColor={this.props.backgroundColor}
                                    handleUpdateTicket={this.props.handleUpdateTicket}  
                                    data={this.props.all_tickets.canceled}/>
                        }
                        </TabPane>

                        <TabPane tabId="5" role="tabpanel">
                            <TicketSearch 
                                bgColor={this.props.backgroundColor} 
                                handleUpdateTicket={this.props.handleUpdateTicket}
                            />                 
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </div>
        );
    }
} 

// --- REDUX STUFF --- //
const mapStateToProps = state => {
    return {
        backgroundColor : state.backgroundColor,
        month : state.month,
        all_tickets : state.all_tickets
    };
  };
  
  
const mapDispatchToProps = dispatch => {
    return {
        onChangeState: (type, value) => dispatch(updateState(type, value)),
    }
}

const updateState = (type, value) => dispatch => {
    dispatch({
        type: type,
        value,
    });
    return Promise.resolve();
}
// END REDUX STUFF //
  
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketsView));
