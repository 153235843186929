import React from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import formatDateTime from "AuxFunctions/formatDateTime";

class NotificationDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    //console.log("NofiticationDropdown Props",this.props);
    return (
      <>
        <UncontrolledDropdown className="btn-rotate" nav>
          <DropdownToggle
            aria-haspopup={false}
            caret
            color="default"
            data-toggle="dropdown"
            id="navbarDropdownMenuLink"
            nav
          >
            <i className="nc-icon nc-bell-55 text-info" />
            <p>
              <span className="d-lg-none d-md-block">Notifications</span>
            </p>{" "}
            <span>
              <Badge color="info" pill>
                {this.props.notifications.user.length + this.props.notifications.all_users.length}
              </Badge>
            </span>
          </DropdownToggle>

          <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
            {
              this.props.notifications.user.map(noti => {
                return (
                  <DropdownItem key={noti.info}>
                    <p>
                      <i className="fa fa-commenting-o" style={{transform:"none"}} />
                      &nbsp;
                      <span className="word-break">
                      {noti.from + ": " + noti.message} 
                      </span>
                      <br />
                      <sub>{formatDateTime(noti.created)}</sub>
                    </p> 
                  </DropdownItem>
                );
              })
            }
            {
              this.props.notifications.all_users.map(noti => {
                return (
                  <DropdownItem key={noti.info}>
                    <p>
                      <i className="fa fa-globe" style={{transform:"none"}}/> 
                      &nbsp;
                      <span className="word-break">
                        {noti.from + ": " + noti.message} 
                      </span>
                      <br />
                      <sub>{formatDateTime(noti.created)}</sub>
                    </p> 
                  </DropdownItem>
                );
              })
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}

export default NotificationDropdown;
