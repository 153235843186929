import React, { Component } from "react";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
// Variables
import {
    SNOW_CREATE_TICKET_URL, 
    SNOW_CREATE_CALL_URL
} from "../variables/snow_urls";


class SnowActions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleCreateTicket = this.handleCreateTicket.bind(this);
    this.handleCreateCallRecord = this.handleCreateCallRecord.bind(this);
  }
  popup;

  handleCreateTicket() {
    this.popup = window.open(
      SNOW_CREATE_TICKET_URL,
      "Create Ticket",
      "left=10,top=10,width=1000,height=700"
    );
  }

  handleCreateCallRecord() {
    this.popup = window.open(
      SNOW_CREATE_CALL_URL,
      "Create Ticket",
      "left=10,top=10,width=1000,height=700"
    );
    //alert("This function is not available at this moment");
  }

  render() {
    return (
      <Card>
        <CardHeader tag="h5">
          Service Now Actions &nbsp;<i class="fas fa-tasks text-primary"></i>
        </CardHeader>
        <CardBody className="text-center">
          <Button
            onClick={this.handleCreateTicket}
            color="info"
            className="snow-btn"
          >
            Create Incident
          </Button>
          <br />
          <Button
            onClick={this.handleCreateCallRecord}
            className="btn-purple snow-btn"
          >
            Create Call Record
          </Button>
        </CardBody>
      </Card>
    );
  }
}

export default SnowActions;
