import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// reactstrap components
import {
  CardBody,
  Row,
  Col,
} from "reactstrap";

//Custom Color Picker Badges
import ColorPicker from "./ColorPicker";
const colors = [
  "custom-default",
  "black",
  "brown",
  "purple",
  "navy",
  "aqua",
  "green",
  "red",
  "orange",
  "pink",
  "gold",
  "white",
  "tcs",
  "gr1",
  "gr2",
  "gr3",
  "gr4",
  "gr5",
  "gr6",
  "sky",
  "gr7",
  "gr8",
  "gr9",
  "rbw",
]

class SettingsMenu extends React.Component {
  render() {
    return (
        <div className="content d-flex settings-menu justify-content-center">
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col sm="2"/> {/* Empty columns are meant to center other columns with no need for extra css tags */}
                        <Col sm="4">
                          <p className="category">Dark Mode</p>
                        </Col>
                        <Col sm="4">
                          <p className="category">Mini Sidebar</p>
                        </Col>
                        <Col sm="2"/>
                      </Row>
                      
                      <Row>
                        <Col sm="2"/>
                        <Col sm="4">
                            <Switch
                              onChange={this.props.handleDarkMode}
                              value={!this.props.darkMode}
                              offColor="warning"
                              offText={<i className="fa fa-moon-o text-info" />}
                              onColor="warning"
                              onText={<i className="nc-icon nc-bulb-63" />}
                            />
                        </Col>
                        <Col sm="4">
                          <Switch
                            onChange={this.props.handleMiniClick}
                            value={this.props.sidebarMini}
                            offColor="info"
                            offText={<i className="fa fa-search-plus" />}
                            onColor="info"
                            onText={<i className="fa fa-search-minus" />}
                          />
                        </Col>
                        <Col sm="2"/>
                      </Row>

                      <hr></hr>
                      <Row>
                        <Col>
                          <p className="category text-center">Sidebar Color</p>
                          <Row className="badge-colors">

                            {/* --- Map array of colors into color picker components --- */} 
                            {
                              colors.map( (color,index) =>
                                <Col key={index} sm="1">
                                  <ColorPicker 
                                    bgColor={this.props.bgColor}
                                    handleBgClick={this.props.handleBgClick} 
                                    color={color} 
                                  />    
                                </Col>
                              )
                            }
                          
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
        </div>
    );
  }
}

export default SettingsMenu;
