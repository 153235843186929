import React, { Component } from "react";
import { 
    CardBody,
    Row,
    Col,
} from "reactstrap";
import UsersInStoreTable from "./UsersInStoreTable";


class StoreDetails extends Component {
    
    render(){
        //console.log("StoreDetails props",this.props);
        let ngsn;
        try {
            ngsn = this.props.store.u_ngsn;
        }
        catch {
            console.log("Unable to get u_ngsn value")
        }

        return(
            <React.Fragment>    
                <CardBody>
                    <Row>
                        <Col>
                            <p>
                                <b>Department: </b> 
                                {
                                    this.props.data.length > 0 
                                    ? this.props.data[0].location.display_value
                                    : "Unknown"
                                }
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <b>Phone number: </b> 
                                {
                                    this.props.data.length > 0 
                                    ? this.props.data[0].phone.display_value
                                    : this.props.data.phoneNumber
                                }   
                            </p>
                        </Col>

                        <Col>
                            <p>
                                <b>NGSN: &nbsp;</b>
                                {
                                    this.props.fetchingLocation
                                    ? <i class="fas fa-spinner fa-pulse text-info"></i>
                                    :
                                        ngsn
                                        ? <i class="far fa-check-square text-info"></i>
                                        : <i class="fas fa-times text-danger"></i>
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <p>
                                <b>Manager: </b>
                                {
                                    this.props.data.length > 0
                                    ? this.props.data[0].manager.display_value
                                    : ""
                                }
                            </p>     
                        </Col>
                        <Col>
                            <p>
                                <b>Second Level Manager: </b>
                                {
                                    this.props.data.length > 0 
                                    ? this.props.data[0].u_second_level_manager.display_value
                                    : ""
                                }
                            </p>    
                        </Col>
                    </Row>
                    <p>
                        <b>Users in Store: </b>
                    </p>
                    {
                        this.props.data.length > 0
                        ? <UsersInStoreTable users={this.props.data}/>
                        : <p>No users registered for this phone number.</p>
                    }
                </CardBody>
            </React.Fragment>
        );
    }
}

export default StoreDetails;