import React, {Component} from "react";
import {
  Card,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
  Badge,
  CardHeader,
  CardBody,
  Nav,
} from "reactstrap";

import TicketSearch from "components/TicketSearch";
import UserTicketsv2 from "components/UserSumTable/UserTickets/UserTicketsv2";
import NoTicketsToShow from "components/NoTicketsToShow";
/* import CreateTicketForm from "components/TicketForm/CreateTicketForm/CreateTicketForm";
import CallRecordForm from "components/CallRecordForm"; */
import KbSearch from "components/KnowledgeSearch/KbSearch";


class TicketsTabMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      openedCollapses: ["collapseOne"],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  collapsesToggle = (collapse) => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter((item) => item !== collapse),
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses,
      });
    }
  };

  countActive(){
    //Returns a count of active tickets from callInfo.userTickets prop.
    let tickets;
    let active = 0;
    try {
      tickets = this.props.callInfo.userTickets;
    }
    catch {
      return "zero"
    }

    tickets.forEach(ticket =>{
      if (ticket.state.value !== "7"  && ticket.state.value !=="8"){
        active++;
      }
    });
    return active;
  }


  render() {
    //console.log("TicketsTabMenu props:",this.props);
    return (
      <Card>
        <CardHeader>
          <h5>
            Incident history &nbsp;
            <i className="fas fa-history text-primary"></i>
          </h5>
        </CardHeader>

        <CardBody>
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav id="tabs" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "1" })}
                  >
                    Store Incidents
                    {this.props.callInfo.userTickets.length > 0 ? (
                      <Badge style={{marginLeft:"10px"}} color="info">
                        {this.countActive()} active
                      </Badge>
                    ) : null}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "2" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "2" })}
                  >
                    Incident Search
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tab"
                    href="#"
                    className={this.state.activeTab === "3" ? "active" : ""}
                    onClick={() => this.setState({ activeTab: "3" })}
                  >
                    Knowledge Base Search
                  </NavLink>
                </NavItem>
              </Nav>
              </div>
              </div>

          <TabContent
            className="text-left"
            id="tickets-tab-content"
            activeTab={this.state.activeTab}
          >
          
            <TabPane tabId="1" role="tabpanel">
              {this.props.callInfo.userTickets &&
                Object.keys(this.props.callInfo.userTickets).length > 0 ? 
                    <UserTicketsv2
                    data={this.props.callInfo.userTickets}
                    handleUpdateTicket={this.props.handleUpdateTicket}
                    />
                 : <NoTicketsToShow />}
            </TabPane>

            <TabPane tabId="2" role="tabpanel">
                <TicketSearch 
                  handleUpdateTicket={this.props.handleUpdateTicket}
                  />
            </TabPane>

            <TabPane tabId="3" role="tabpanel">
                <KbSearch />
            </TabPane>

          </TabContent>
        </CardBody>  
      </Card>
    );
  }
}

export default TicketsTabMenu;
