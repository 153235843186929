import React from "react";
import {
  Spinner,
  Label,
  Form,
} from "reactstrap";
import Select, { components } from "react-select";
//Redux
import {connect} from "react-redux";
// Api data
import { API_QueryData } from "variables/apis";
import qs from "querystring";
//Amplify
import { Auth } from "aws-amplify";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className="nc-icon nc-zoom-split" />
      </components.DropdownIndicator>
    )
  );
};

const Option = (props) => {
  return <components.Option {...props}>{props.label}</components.Option>;
};

const LoadingIndicator = (props) => {
  return (
    <components.LoadingIndicator {...props}>
      <Spinner color="info" />
    </components.LoadingIndicator>
  );
};

const LoadingMessage = (props) => {
  return (
    <components.LoadingMessage {...props}>
      Loading... <Spinner size="sm" color="info" />
    </components.LoadingMessage>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Please perform a new search... <i className="fas fa-search text-info" /> 
    </components.NoOptionsMessage>
  );
};



class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_fetching : false,
      searchKey: "",
    };
    this.handleGetTicketSearch = this.handleGetTicketSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  init;

  //Used on Navbars/Search/Search.js component for retrieve any ticket related.
  handleGetTicketSearch(key){
    //console.log("Search key: ", key);
    this.setState({is_fetching : true});
    API_QueryData.getQuickSearch.keys = key;
    fetch(API_QueryData.endpoint + "?" + qs.stringify(API_QueryData.getQuickSearch), this.init)
      .then(response =>  response.json())
      .then(res => {
        console.log("Ticket Search:" , res);
        this.props.onChangeState("searchTickets", res.data.result);
        this.setState({is_fetching : false});
      });
    
  };

  handleSubmit(e){
    e.preventDefault();
    this.handleGetTicketSearch(this.state.searchKey);
  }

  handleChange(e){
    this.setState({searchKey : e});
  }

  onChangeSearch(event){
    if (event) {
      this.setState({ searchKey: event }, () => {
        this.props.handleUpdateTicket(event.value);
      });
    }
  };

  async componentDidMount(){
    this.init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
  }

  render() {
    //console.log("Search props", this.props);
    return (
      <>
      <Label className="search-label" style={{minWidth:"50px",maxWidth:"105px"}}>Quick search: &nbsp;</Label>
      <Form style={{minWidth:"220px",maxWidth:"250px"}}
            onSubmit={this.handleSubmit}>
          <Select
            components={{
              DropdownIndicator,
              Option,
              LoadingIndicator,
              LoadingMessage,
              NoOptionsMessage,
            }}
            isLoading={this.state.is_fetching}
            placeholder="Incident number..."
            className=""
            classNamePrefix="react-select " 
            name="singleSelect"
            options={
              //Because the Select component NEEDS an array with label and value, we need to copy the state values to a valid one.
              this.props.searchTickets.map((opt) => ({
                label:
                  opt.number.display_value +
                  ", " +
                  opt.short_description.display_value,
                value: opt,
              }))
            }
            //onInputChange={this.handleGetTicketSearch}
            style={{minWidth:"220px",maxWidth:"250px"}}
            onInputChange={this.handleChange}
            value={this.state.searchKey}
            onChange={this.onChangeSearch}
            autoFocus={true}
            isDisabled={false}
            isClearable={true}
          />
      </Form>
      </>
    );
  }
}

// ---- REDUX STUFF ---- //
const mapStateToProps = state => {
  return {
    searchTickets : state.searchTickets,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
      type: type,
      value,
  });
  return Promise.resolve();
}

// ENDS REDUX STUFF //
export default connect(mapStateToProps,mapDispatchToProps)(Search);