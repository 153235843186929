import React from "react";
import LayoutVariables from "../../variables/layout";
import axios from "axios";
// Amplify
import { Auth } from "aws-amplify";
import { API_UploadImage } from "variables/apis";
import LiveImage from "components/LiveImage/LiveImage";

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.user_has_picture === true
        ? LayoutVariables.profile_images_bucket + this.props.agentProfile + "_profile"
        : LayoutVariables.default_user_profile,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  async handleSubmit() {
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    if (this.state.file === null) return;

    //Could contain JPG, PNG, JPEG etc...
    let format = this.state.file.type.split("/")[1];
    if (
      format === "png" ||
      format === "jpg" ||
      format === "jpeg" ||
      format === "gif"
    ) {
      API_UploadImage.data = new Blob([this.state.file]);
      axios({
        method: API_UploadImage.method,
        url:
          API_UploadImage.endpoint +"/"+ "/" + this.props.profileName + "_profile",
        headers: {
          "Content-Type": this.state.file.type,
          Authorization : Init.headers.Authorization,
        },
        data: API_UploadImage.data,
      }).then((response) => {
        //console.log("img response",response);
        if (response.status === 200){
          this.props.successAlert(
            "Success!",
            "Profile picture uploaded succesfuly 👍: "
          );
        }
        else {
          this.props.dangerAlert(
            "Error",
            "Something wrong happened, please try again."
          );
        }
      });
    } else {
      this.props.dangerAlert(
        "Oh no! 😰",
        "Image format must be png, jpg or jpeg only!"
      );
    }
  }

  render() {
    return (
      <div className="picture-container">
        <div className="picture">
          <LiveImage
            image={this.state.imagePreviewUrl}
            className="picture-src"
            interval={1000}
          ></LiveImage>
          <input type="file" onChange={(e) => this.handleImageChange(e)} />
        </div>
        <h6 className="description">Profile Picture</h6>
      </div>
    );
  }
}

export default PictureUpload;
