import React, { Component } from "react";
import axios from "axios";
import qs from "querystring";
import {
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { connect } from "react-redux";

import PictureUpload from "components/CustomUpload/PictureUpload";
//Amplify
import { Auth } from 'aws-amplify';
import { API_AgentProfilePost } from "variables/apis";


class EditProfileForm extends Component {
  constructor(props) {
    super(props);
    this.pictureUpload = React.createRef();
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }

    API_AgentProfilePost.postProfile.username = this.props.user
    API_AgentProfilePost.data.profile = {
      Email: this.emailInputValue.value ? this.emailInputValue.value : "",
      /* FirstName: this.firstNameInputValue.value
        ? this.firstNameInputValue.value
        : "",
      LastName: this.lastNameInputValue.value
        ? this.lastNameInputValue.value
        : "", */ //This has been commented in order to not saver user's personal info.
      //Address: this.addressnputValue.value ? this.addressnputValue.value : "",
      City: this.cityInputValue.value ? this.cityInputValue.value : "",
      Country: this.countryInputValue.value ? this.countryInputValue.value : "",
      PostalCode: this.postalCodeInputValue.value
        ? this.postalCodeInputValue.value
        : "",
      About: this.aboutInputValue.value ? this.aboutInputValue.value : "",
    };

    axios({
      method: API_AgentProfilePost.method,
      headers : Init.headers,
      url:
        API_AgentProfilePost.endpoint +
        "?" +
        qs.stringify(API_AgentProfilePost.postProfile),
      data: API_AgentProfilePost.data,
    }).then((response) => {
      this.props.handleGetAgentProfile(this.props.user);
      this.props.successAlert(
        "Success! 🙌",
        "Your profile has been updated but your profile picture may take a while!"
      );
      //We call our child's upload image function.
      this.pictureUpload.current.handleSubmit();
      this.props.onChangeState("user_has_picture",true);
    });
  };

  render() {
    //console.log("Edit Profile props:",this.props);
    return (
      <React.Fragment>
        <CardHeader>
          <CardTitle tag="h5">User360 Account</CardTitle>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.onSubmit}>
            <Container>
              <Row>
                <Col lg="2" md="12" sm="12">
                  <PictureUpload
                    user_has_picture={this.props.user_has_picture}
                    agentProfile={this.props.user}
                    ref={this.pictureUpload}
                    profileName={
                      this.props.user
                        ? this.props.user
                        : ""
                    }
                    successAlert={this.props.successAlert}
                    dangerAlert={this.props.dangerAlert}
                  />
                </Col>
                <Col lg="10" md="12" sm="12">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Company </label>
                        <Input
                          defaultValue="Tata Consulary Services Inc."
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Employee Id</label>
                        <Input
                          disabled
                          innerRef={(node) => (this.lastNameInputValue = node)}
                          defaultValue={
                            this.props.userdata.nickname
                              ? this.props.userdata.nickname
                              : "-"
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>


                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          innerRef={(node) => (this.usernameInputValue = node)}
                          disabled
                          defaultValue={
                            this.props.ccpAgentProfile.username
                              ? this.props.ccpAgentProfile.username
                              : this.props.user
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <Input
                          disabled
                          innerRef={(node) => (this.emailInputValue = node)}
                          type="email"
                          defaultValue={
                            this.props.userdata
                              ? this.props.userdata.email
                              : ""
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>First Name</label>
                    <Input
                      disabled
                      innerRef={(node) => (this.firstNameInputValue = node)}
                      defaultValue={
                        this.props.userdata.name
                          ? this.props.userdata.name
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="6">
                  <FormGroup>
                    <label>Last Name</label>
                    <Input
                      disabled
                      innerRef={(node) => (this.lastNameInputValue = node)}
                      defaultValue={
                        this.props.userdata.given_name
                          ? this.props.userdata.given_name
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* <Row>
                <Col md="12">
                  <FormGroup>
                    <label>Address</label>
                    <Input
                      innerRef={(node) => (this.addressnputValue = node)}
                      defaultValue={
                        this.props.agentProfile.item
                          ? this.props.agentProfile.item.Address
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row> */}

              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>City</label>
                    <Input
                      innerRef={(node) => (this.cityInputValue = node)}
                      defaultValue={
                        this.props.agentProfile.item
                          ? this.props.agentProfile.item.City
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>Country</label>
                    <Input
                      innerRef={(node) => (this.countryInputValue = node)}
                      defaultValue={
                        this.props.agentProfile.item
                          ? this.props.agentProfile.item.Country
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="4">
                  <FormGroup>
                    <label>Postal Code</label>
                    <Input
                      innerRef={(node) => (this.postalCodeInputValue = node)}
                      defaultValue={
                        this.props.agentProfile.item
                          ? this.props.agentProfile.item.PostalCode
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>About Me</label>
                    <Input
                      innerRef={(node) => (this.aboutInputValue = node)}
                      type="textarea"
                      maxLength="300"
                      defaultValue={
                        this.props.agentProfile.item
                          ? this.props.agentProfile.item.About
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="info" type="submit">
                    Update Profile
                  </Button>
                </div>
              </Row>
            </Container>
          </Form>
        </CardBody>
      </React.Fragment>
    );
  }
}


// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    user : state.user,
    userdata : state.userdata,
    user_has_picture : state.user_has_picture,
  };
}


const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);

