import React, { Component } from 'react';
// reactstrap components
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";

var date = new Date();
var month = date.getMonth();
const monthNames = [
    "January", 
    "February", 
    "March", 
    "April", 
    "May", 
    "June",
    "July", 
    "August", 
    "September", 
    "October", 
    "November", 
    "December"
];
month = monthNames[month];
const alLeft = {textAlign:"left"};

class StatsCard extends Component{
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return(
            <Card className="card-stats card-chungus stats-card">
                <CardBody>
                    <Row style={{"height" : "18%"}}>
                        <Col md="8">
                            <CardTitle className="my-card-title">{this.props.title}</CardTitle>  
                        </Col>
                        
                        <Col md="4" style={{testAlign:"right"}}>
                            <i className={this.props.iconClass + " icon-md"} 
                            style={{"color":this.props.color}}
                            />
                        </Col>
                    </Row>
                    
                    <hr/>
                    
                    <Row>
                        <Col md="5" style={{fontSize:"1rem"}}>
                            <div className="numbers">
                                <p className="card-category"
                                    style={alLeft}
                                >
                                    Today
                                </p>
                                <CardTitle style={alLeft} tag="p">{this.props.data1}</CardTitle>
                                <p />
                            </div>
                        </Col>

                        <Col md="7">
                            <Row>
                                <Col>
                                <div className="numbers">
                                    <p className="card-category right">Week</p>
                                    <CardTitle className="right" tag="p">{this.props.data2}</CardTitle>
                                    <p />
                                </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                <div className="numbers">
                                    <p className="card-category right">{month}</p>
                                        <CardTitle className="right" tag="p">{this.props.data3}</CardTitle>
                                    <p />
                                </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
        );
    }
}

export default StatsCard;