import React, { Component } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

class OverallMetrics extends Component {

  render() {
    //console.log("OverallMetrics: ", this.props);
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">
            {this.props.metrics} Metrics &nbsp;
            <i className="fas fa-history text-primary"></i>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Table hover data-color={this.props.bgColor}>
            <thead className="text-primary">
              <tr>
                <th>Metric</th>
                <th>Today</th>
                <th>This Week</th>
                <th>{this.props.month}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Calls answered</td>
                <td>
                  {this.props.dailyMetrics ? this.props.dailyMetrics.COUNT : "NULL"}
                </td>
                <td>
                  {this.props.weeklyMetrics &&
                    Object.keys(this.props.weeklyMetrics).length > 0 &&
                    this.props.weeklyMetrics != undefined &&
                    this.props.weeklyMetrics.COUNT != undefined &&
                    Object.keys(this.props.weeklyMetrics.COUNT).length > 0 &&
                    this.props.weeklyMetrics.COUNT != null ? this.props.weeklyMetrics.COUNT : "NULL"}
                </td>
                <td>
                  {this.props.monthlyMetrics ? this.props.monthlyMetrics.COUNT : "NULL"}
                </td>
              </tr>

              <tr>
                <td>Average Handle Time (AHT)</td>
                <td>
                  {this.props.dailyMetrics ? this.props.dailyMetrics.AVG_AHT : "NULL"}
                </td>
                <td>
                  {this.props.weeklyMetrics ? this.props.weeklyMetrics.AVG_AHT : "NULL"}
                </td>
                <td>
                  {this.props.monthlyMetrics ? this.props.monthlyMetrics.AVG_AHT : "NULL"}
                </td>
              </tr>

              <tr>
                <td>After Call Work Time</td>
                <td>
                  {this.props.dailyMetrics ? this.props.dailyMetrics.ACW : "NULL"}
                </td>
                <td>
                  {this.props.weeklyMetrics ? this.props.weeklyMetrics.ACW : "NULL"}
                </td>
                <td>
                  {this.props.monthlyMetrics ? this.props.monthlyMetrics.ACW : "NULL"}
                </td>
              </tr>

              <tr>
                <td>Time On Hold</td>
                <td>
                  {this.props.dailyMetrics ? this.props.dailyMetrics.HOLD : "NULL"}
                </td>
                <td>
                  {this.props.weeklyMetrics ? this.props.weeklyMetrics.HOLD : "NULL"}
                </td>
                <td>
                  {this.props.weeklyMetrics ? this.props.weeklyMetrics.HOLD : "NULL"}
                </td>
              </tr>

              {/* <tr>
                <td>Idle time</td>
                <td>
                  {this.props.dailyMetrics.IDLE  != undefined ? this.props.dailyMetrics.IDLE : "25m 35s"}
                </td>
                <td>
                  {this.props.weeklyMetrics.IDLE  != undefined ? this.props.dailyMetrics.IDLE : "1 hour 40min"}
                </td>
                <td>
                  {this.props.monthlyMetrics.IDLE != undefined ? this.props.monthlyMetrics.IDLE : "4hours 24 min"}
                </td>
              </tr> */}

            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

export default OverallMetrics;
