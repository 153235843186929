import React, { Component, useState } from "react";
// Redux 
import { connect } from "react-redux"
// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";
// Amplify
import { Auth } from "aws-amplify";
import { host } from "../variables/apis";
import convertTime from "../AuxFunctions/convertTime";
import CallBehaviourTabs from "components/CallBehaviour/CallBehaviourTabs";
import humanNumber from "AuxFunctions/humanNumber";
import DemoMap from "./components/DemoMap";

//Custom Components
import ChartCard from "../components/ChartCard";
import StatsCard from "../components/StatsCard";

import TicketsChartTabs from "components/TicketsChartTabs";


// ---- COMPONENT ---- //

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching_count: false,
      contentTopMargin: props.getContentTopMargin(),
    }
  }
  async fetch_monthly_data() {
    //Fetchs data from API for monthly stats and stores it on component state.
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    await fetch(`${host}json-stats?report=monthly`, init)
      .then(response => response.ok ? response.json() : null)
      .then(data => {
        this.props.onChangeState("monthly_data", data);
        //console.log(data);
      });
  }

  async fetch_weekly_data() {
    //Fetchs data from API for monthly stats and stores it on component state.
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    await fetch(`${host}json-stats?report=weekly`, init)
      .then(response => response.json())
      .then(data => {
        this.props.onChangeState("weekly_data", data);
        //console.log(data);
      });
  }

  async fetch_daily_data() {
    //Fetchs data from API for daily stats and stores it on component state.
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    await fetch(`${host}json-stats?report=daily`, init)
      .then(response => response.json())
      .then(data => {
        this.props.onChangeState("daily_data", data);
        //console.log(data);
      });
  }

  async fetch_all_data() {
    await this.fetch_daily_data();
    await this.fetch_weekly_data();
    await this.fetch_monthly_data();
  }


  calc_ans_percent(sla, actual) {
    /*Retuns percentage for either answered or abandoned calls based on service level
    agreement terms. */
    return ((sla / actual) * 100).toFixed(2);
  }

  calc_aban_percent(sla, actual, count) {
    /*Retuns percentage for either answered or abandoned calls based on service level
    agreement terms. */
    return (((actual - sla) / count) * 100).toFixed(2);
  }


  gen_chart_data_arr(data, type) {
    /* Returns an array with data to be used for display in single value Doughnut chart */
    if (data === undefined) {
      return ["fetching", 100]
    }

    if (type === "ans") {
      return [data, 100 - data];
    }
    else {
      return [data, 8 - data];
    }
  }

  calc_all_percents() {
    // Getting daily data for percentages 
    let day_count = this.props.daily_data.INBOUND.TODAY.COUNT
    let day_ans = this.props.daily_data.INBOUND.TODAY.ANS;
    let day_sla_ans = this.props.daily_data.INBOUND.TODAY.SLA_ANS;
    let day_aban = this.props.daily_data.INBOUND.TODAY.ABAN;
    let day_sla_aban = this.props.daily_data.INBOUND.TODAY.SLA_ABAN;

    // Getting weekly data for percentages 
    let week_count = 0;
    let week_ans = 0;
    let week_sla_ans = 0;
    let week_aban = 0;
    let week_sla_aban = 0;
    if (this.props.weekly_data && this.props.weekly_data !== undefined && this.props.weekly_data !== null && this.props.weekly_data !== {} && Object.keys(this.props.weekly_data).length > 0) {
      week_count = this.props.weekly_data.INBOUND.WEEK.TOTAL.COUNT
      week_ans = this.props.weekly_data.INBOUND.WEEK.TOTAL.ANS;
      week_sla_ans = this.props.weekly_data.INBOUND.WEEK.TOTAL.SLA_ANS;
      week_aban = this.props.weekly_data.INBOUND.WEEK.TOTAL.ABAN;
      week_sla_aban = this.props.weekly_data.INBOUND.WEEK.TOTAL.SLA_ABAN;
    }
    // Getting monthly data for percentages 
    let mon_count = this.props.monthly_data.INBOUND.MONTH.TOTAL.COUNT;
    let mon_ans = this.props.monthly_data.INBOUND.MONTH.TOTAL.ANS;
    let mon_sla_ans = this.props.monthly_data.INBOUND.MONTH.TOTAL.SLA_ANS;
    let mon_aban = this.props.monthly_data.INBOUND.MONTH.TOTAL.ABAN;
    let mon_sla_aban = this.props.monthly_data.INBOUND.MONTH.TOTAL.SLA_ABAN;
    this.props.onChangeState("percents",
      {
        //Daily %
        day_sla_ans_percent: this.calc_ans_percent(day_sla_ans, day_ans),
        day_sla_aban_percent: this.calc_aban_percent(day_sla_aban, day_aban, day_count),
        // Weekly %
        week_sla_ans_percent: this.calc_ans_percent(week_sla_ans, week_ans),
        week_sla_aban_percent: this.calc_aban_percent(week_sla_aban, week_aban, week_count),
        //Monthly %
        mon_sla_ans_percent: this.calc_ans_percent(mon_sla_ans, mon_ans),
        mon_sla_aban_percent: this.calc_aban_percent(mon_sla_aban, mon_aban, mon_count)
      }
    );
  }

  gen_all_chart_data() {
    // Generates data to be used by charts ans passes it to state.

    // Daily
    let d_sla_ans_data = this.gen_chart_data_arr(this.props.percents.day_sla_ans_percent, "ans");
    let d_sla_aban_data = this.gen_chart_data_arr(this.props.percents.day_sla_aban_percent);
    // Weekly
    let w_sla_ans_data = this.gen_chart_data_arr(this.props.percents.week_sla_ans_percent, "ans");
    let w_sla_aban_data = this.gen_chart_data_arr(this.props.percents.week_sla_aban_percent);
    // Monthly
    let m_sla_ans_data = this.gen_chart_data_arr(this.props.percents.mon_sla_ans_percent, "ans");
    let m_sla_aban_data = this.gen_chart_data_arr(this.props.percents.mon_sla_aban_percent);

    // Map to props
    this.props.onChangeState('chart_data', {
      d_sla_ans_data: d_sla_ans_data,
      d_sla_aban_data: d_sla_aban_data,
      w_sla_ans_data: w_sla_ans_data,
      w_sla_aban_data: w_sla_aban_data,
      m_sla_ans_data: m_sla_ans_data,
      m_sla_aban_data: m_sla_aban_data
    }
    );
  }

  getAHT = (metric) => {
    // Returns Average Handle Time depending on desired metric: day / week / month //
    switch (metric) {
      case "day":
        return convertTime(this.props.daily_data.INBOUND.TODAY.AHT / this.props.daily_data.INBOUND.TODAY.COUNT);

      case "week":
        if (this.props.weekly_data && this.props.weekly_data !== undefined && this.props.weekly_data !== null && this.props.weekly_data !== {} && Object.keys(this.props.weekly_data).length > 0) {
          return convertTime(this.props.weekly_data.INBOUND.WEEK.TOTAL.AHT / this.props.weekly_data.INBOUND.WEEK.TOTAL.COUNT);
        } else {
          return "0:00"
        }

      case "month":
        return convertTime(this.props.monthly_data.INBOUND.MONTH.TOTAL.AHT / this.props.monthly_data.INBOUND.MONTH.TOTAL.COUNT);

      default:
        return "wrong input"
    }
  };


  getASA = (metric) => {
    // Returns Average Speed of Answer depending on desired metric: day / week / month //
    switch (metric) {
      case "day":
        return convertTime(this.props.daily_data.INBOUND.TODAY.SLA_ANS / this.props.daily_data.INBOUND.TODAY.ANS);

      case "week":
        if (this.props.weekly_data && this.props.weekly_data !== undefined && this.props.weekly_data !== null && this.props.weekly_data !== {} && Object.keys(this.props.weekly_data).length > 0) {
          return convertTime(this.props.weekly_data.INBOUND.WEEK.TOTAL.SLA_ANS / this.props.weekly_data.INBOUND.WEEK.TOTAL.ANS);
        } else {
          return "0:00"
        }

      case "month":
        return convertTime(this.props.monthly_data.INBOUND.MONTH.TOTAL.SLA_ANS / this.props.monthly_data.INBOUND.MONTH.TOTAL.ANS);

      default:
        return "wrong input"
    }
  }

  getTrueABAN = (aban, sla_aban) => {
    // Returns Average Speed of Answer depending on desired metric: day / week / month //
    if (isNaN(aban) || isNaN(sla_aban)) {
      return 0
    }
    return aban - sla_aban;
  }


  DataRefresh() {
    // Refreshes all data in order to be constantly fetch with an interval
    this.fetch_all_data().then(() => {
      this.calc_all_percents();
      this.gen_all_chart_data();
    });
  }

  componentDidMount() {
    this.DataRefresh();
    this.interval = setInterval(() => this.DataRefresh(), 30000);
    //window.addEventListener('resize', this._handleWindowResize)=>{console.log("TEST Resize")};
    window.addEventListener("resize", () => {
      this.render();
    }, true);
    window.addEventListener("focus", () => {
      this.render();
    }, true);

    // Set scroll to top
    let main = document.querySelector(".main-panel");
    main.scrollTo(0, 0);
  }

  componentWillUnmount() {
    try {
      clearInterval(this.interval);
    }
    catch { }
  }
  render() {
    //console.log("Dashboard props:",this.props);
    //console.log("Dashboard this:",this);
    /*
    const navBars = document.getElementsByClassName("navbar");
    const navBar = (navBars != undefined && navBars != null)? navBars[0]:null;
    const contentMarginTop = (navBars != undefined && navBar != null && "offsetHeight" in navBar)?(navBar.offsetHeight).toString():"20px";
    */
    return (
      <>
        <div className="content" style={{ marginTop: (this.props.getContentTopMargin()) }}>
          <h3 className="text-default">
            Overall Stats &nbsp;
            <i className="fas fa-chart-bar text-primary"></i>
          </h3>
          {this.props.weekly_data !== undefined && this.props.weekly_data !== null && this.props.weekly_data !== {} && Object.keys(this.props.weekly_data).length > 0 ?
            <Row>
              <Col lg="2" md="4" sm="6" className="d-flex align-items-stretch">
                <StatsCard
                  title="Offered calls"
                  iconClass="fa fa-bell text-warning"
                  data1={humanNumber(this.props.daily_data.INBOUND.TODAY.COUNT)}
                  data2={humanNumber(this.props.weekly_data.INBOUND.WEEK.TOTAL.COUNT)}
                  data3={humanNumber(this.props.monthly_data.INBOUND.MONTH.TOTAL.COUNT)}
                />
              </Col>


              <Col lg="2" md="4" sm="6" className="d-flex align-items-stretch">
                <StatsCard
                  title="Answered calls"
                  iconClass="fa fa-phone text-success"
                  data1={humanNumber(this.props.daily_data.INBOUND.TODAY.ANS)}
                  data2={humanNumber(this.props.weekly_data.INBOUND.WEEK.TOTAL.ANS)}
                  data3={humanNumber(this.props.monthly_data.INBOUND.MONTH.TOTAL.ANS)}
                />
              </Col>


              <Col lg="2" md="4" sm="6" className="d-flex align-items-stretch">
                <StatsCard
                  title="SLA Answered"
                  iconClass="fa fa-clipboard-check text-info"
                  data1={humanNumber(this.props.daily_data.INBOUND.TODAY.SLA_ANS)}
                  data2={humanNumber(this.props.weekly_data.INBOUND.WEEK.TOTAL.SLA_ANS)}
                  data3={humanNumber(this.props.monthly_data.INBOUND.MONTH.TOTAL.SLA_ANS)}
                />
              </Col>

              <Col lg="2" md="4" sm="6" className="d-flex align-items-stretch">
                <StatsCard
                  title="ASA"
                  iconClass="fas fa-stopwatch text-primary"
                  data1={this.getASA("day")}
                  data2={this.getASA("week")}
                  data3={this.getASA("month")}
                />
              </Col>


              <Col lg="2" md="4" sm="6" className="d-flex align-items-stretch">
                <StatsCard
                  title="AHT"
                  iconClass="far fa-clock text-info"
                  data1={this.getAHT("day")}
                  data2={this.getAHT("week")}
                  data3={this.getAHT("month")}
                />
              </Col>


              <Col lg="2" md="4" sm="6" className="d-flex align-items-stretch">
                <StatsCard
                  title="True Abandoned"
                  iconClass="fa fa-phone-slash text-danger"
                  data1={this.getTrueABAN(this.props.daily_data.INBOUND.TODAY.ABAN, this.props.daily_data.INBOUND.TODAY.SLA_ABAN)}
                  data2={this.getTrueABAN(this.props.weekly_data.INBOUND.WEEK.TOTAL.ABAN, this.props.weekly_data.INBOUND.WEEK.TOTAL.SLA_ABAN)}
                  data3={this.getTrueABAN(this.props.monthly_data.INBOUND.MONTH.TOTAL.ABAN, this.props.monthly_data.INBOUND.MONTH.TOTAL.SLA_ABAN)}
                />
              </Col>

            </Row>
            :
            null
          }

          {/* ---------------------CHARTS------------------ */}
          <h3 className="text-default">
            Service Level Agreement &nbsp;
            <i className="fas fa-file-signature text-primary"></i>
          </h3>
          <Row>
            <Col lg="2" md="4" sm="6">
              <ChartCard
                title="Today SLA Answered"
                ans={true}
                data={this.props.chart_data.d_sla_ans_data} />
            </Col>

            <Col lg="2" md="4" sm="6">
              <ChartCard
                title="Today SLA Abandoned"
                ans={false}
                data={this.props.chart_data.d_sla_aban_data} />
            </Col>

            <Col lg="2" md="4" sm="6">
              <ChartCard
                title="Weekly SLA Answered"
                ans={true}
                data={this.props.chart_data.w_sla_ans_data} />
            </Col>

            <Col lg="2" md="4" sm="6">
              <ChartCard
                title="Weekly SLA Abandoned"
                ans={false}
                data={this.props.chart_data.w_sla_aban_data} />
            </Col>

            <Col lg="2" md="4" sm="6">
              <ChartCard
                title="Monthly SLA Answered"
                ans={true}
                data={this.props.chart_data.m_sla_ans_data} />
            </Col>

            <Col lg="2" md="4" sm="6">
              <ChartCard
                title="Monthly SLA Abandoned"
                ans={false}
                data={this.props.chart_data.m_sla_aban_data} />
            </Col>
          </Row>
          <br />


          {/* ----------------- Incident Statistics -------------- */}
          <Row>
            <Col md="6">
              <TicketsChartTabs />
            </Col>

            {/* -------------- Call behaviour charts -------------------- */}
            <Col md="6">
              <CallBehaviourTabs />
            </Col>
          </Row>

          {/* ----------------------- Map ------------------------------- */}
          {/* <Row>
            <Col md="12">
              <DemoMap />
            </Col>
          </Row> */}
        </div>
      </>
    );
  }
}


// --- REDUX STUFF --- //
const mapStateToProps = state => {
  return {
    backgroundColor: state.backgroundColor,
    month: state.month,
    monthly_data: state.monthly_data,
    weekly_data: state.weekly_data,
    daily_data: state.daily_data,
    percents: state.percents,
    chart_data: state.chart_data,
    all_tickets: state.all_tickets,
  };
}


const mapDispatchToProps = dispatch => {
  return {
    onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
    type: type,
    value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
