import React, {Component} from "react";
// Boostrap
import {Table } from "reactstrap";
import convertTime from "../AuxFunctions/convertTime";
// Custom Components
import FetchingData from "./FetchingData";
// Auxiliary functions 
import capitalize from "AuxFunctions/capitalize";

class AgentStatusTable extends Component{
    
    gen_agent_status(status){
        switch (status) {
            case "available":
                return <th className="text-success">Available</th>

            case "CONNECTING":
                return <th className="text-warning">Incoming Call</th> 

            case "CONNECTED_ONHOLD":
                return <th className="text-warning">Call on hold</th> 

            case "MISSED":
                return <th className="text-danger">Missed call</th>    

            case "CONNECTED":
                return <th className="text-info">On call</th>

            case "technical difficulties":
                return <th className="text-danger">Technical difficulties</th>
            
            case "ACW":
            case "backlog":
            case "sme":
                return <th className="text-primary">{capitalize(status)}</th>

            case "break":
            case "lunch":
                return <th className="text-salmon">{capitalize(status)}</th>

            default:
                return <th className="text-primary">{capitalize(status)}</th>
        }
    }

    render(){
        if (this.props.agents_status.length === 0){
            return(
                <FetchingData></FetchingData>
            );
        }

        return(
            <Table data-color={this.props.bgColor} hover striped responsive >
                <thead className="text-primary">
                    <tr>
                        <th>Agent</th>
                        <th>Status</th>
                        <th>Duration</th>
                        <th>Queue</th>
                    </tr>
                </thead>
                <tbody>
                    {   
                        this.props.agents_status.map( (agent,id) =>
                            agent.agentstatus !== "offline" //Agents must be online
                            && (Date.now() - agent.RequestTimestamp)/1000 < 28800 // This will discard bugged agents with status longer than 8 hours 
                            ?
                                <tr key={id}>
                                    <th>{agent.agentname}</th>
                                    {this.gen_agent_status(agent.agentstatus)}
                                    <td>{convertTime((Date.now() - agent.RequestTimestamp)/1000)}</td>
                                    <td>{agent.queuename !== undefined ? agent.queuename : "-"}</td>
                                </tr>
                            :
                                null
                        )  
                    }
                </tbody>
            </Table>
        );
    }
}

  
  export default AgentStatusTable;
  