import React, {Component} from "react";
import {Table, Button} from "reactstrap";

import {SNOW_TICKET_URL} from "../variables/snow_urls";
import formatDateTime from "AuxFunctions/formatDateTime";

class TicketsTable extends Component{
    constructor(props){
        super(props);
        this.handleTicketUrl = this.handleTicketUrl.bind(this);
    }

    handleTicketUrl(sys_id){
        // Opens a popup window to edit tickets on servicenow portal
        let popup = window.open(
            SNOW_TICKET_URL +  sys_id,
            "Ticket details", 
            "left=50,top=50,width=1000,height=700"
        );
    }


    render(){
        //console.log("Table Props", this.props);
        return(
            <Table 
                className="tickets-table" 
                striped 
                responsive 
                hover 
                data-color={this.props.bgColor}
            >
                <thead className="text-primary">
                    <tr>
                        <th>Number</th>
                        <th>User</th>
                        <th>Description</th>
                        <th>Priority</th>
                        <th>State</th>
                        <th>Assign Group</th>
                        <th>Location</th>
                        <th>Category</th>
                        <th>Subcategory</th>
                        <th>Updated</th>
                        {/* <th>Impact</th>
                        <th>Urgency</th> */}
                        {/* <th>Assigned</th> */}
                    </tr>
                </thead>
                <tbody>
                    {   
                        this.props.data.map( (ticket, id) =>
                            <tr key={id}>
                                <td>
                                    <Button key={ticket.number} size="sm" className="btn-purple"
                                        onClick={() => this.props.handleUpdateTicket(ticket)}
                                        /* onClick={()=>{this.handleTicketUrl(ticket.sys_id.value)}} */
                                        >{ticket.number.display_value}
                                    </Button>
                                </td>
                                <td>{ticket.caller_id.display_value}</td>
                                <td>{ticket.short_description.display_value}</td>
                                <td>{ticket.priority.display_value}</td>
                                <td>{ticket.state.display_value}</td>
                                <td>{ticket.assignment_group.display_value}</td>
                                <td>{ticket.location.display_value}</td>
                                <td>{ticket.category.display_value}</td>
                                <td>{ticket.subcategory.display_value}</td>
                                <td>{formatDateTime(ticket.sys_updated_on.display_value)}</td>
                                {/* <td>{ticket.impact.display_value}</td>
                                <td>{ticket.urgency.display_value}</td> */}
                                {/* <td>{ticket.assigned_to.display_value}</td> */}
                            </tr>
                        )  
                    }
                </tbody>
            </Table>
        );
    }
}

export default TicketsTable;
  