import React, {Component} from "react";
// reactstrap components
import { 
  Card, 
  CardBody, 
  Row, 
  Col, 
  Container } from "reactstrap";
import NothingHere from "components/NothingHere/NothingHere";
import EditProfileForm from "components/EditProfile/EditProfileForm/EditProfileForm";

class EditProfile extends Component {
  componentDidMount(){
    // Set scroll to top
    let main = document.querySelector(".main-panel");
    main.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="content" style={{marginTop: (this.props.getContentTopMargin()) }}>
        <Container fluid>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card>
                <CardBody>
                  {this.props.callInfo.agentProfile &&
                  Object.keys(this.props.callInfo.agentProfile).length > 0 ? (
                    <>
                      <EditProfileForm
                        agentProfile={this.props.callInfo.agentProfile}
                        ccpAgentProfile={this.props.callInfo.ccpAgentProfile}
                        handleGetAgentProfile={this.props.handleGetAgentProfile}
                        successAlert={this.props.successAlert}
                        dangerAlert={this.props.dangerAlert}
                      ></EditProfileForm>
                    </>
                  ) : (
                    <NothingHere message="You must login to your CCP to edit your profile!"></NothingHere>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditProfile;
